App.HelpFaq = DS.Model.extend({
	title: DS.attr("string"),
	body: DS.attr("string"),
	category: DS.attr("string")
});

App.HelpSetupStep = DS.Model.extend({
	title: DS.attr("string"),
	body: DS.attr("string")
});

App.HelpSection = DS.Model.extend({
	product: DS.attr("string"),
	productTitle: DS.attr("string"),
	//NOTE: Use string for sub-object id's until we can query by id list
	setupStepIds: DS.attr("string"),
	faqIds: DS.attr("string"),
	categoryIds: DS.attr("string")
});

App.HelpFaqCategory = DS.Model.extend({
	name: DS.attr("string")
});

App.HelpCenterAdapter = DS.RESTAdapter.extend({
	buildURL: function(typeName) { //Override default behavior
		return "/static/js/data/help_center/" + typeName + ".json";
	}
});

//App.HelpCenterAdapter.configure({
//	"help_faq_category": "help_faq_categories"
//}, "plurals");
// Switch to this line below once we upgrade Ember Data.
// Ember.Inflector.inflector.pluralize("help_faq_category", "help_faq_categories");

//App.Store.registerAdapter('App.HelpSection', App.HelpCenterAdapter);
//App.Store.registerAdapter('App.HelpSetupStep', App.HelpCenterAdapter);
//App.Store.registerAdapter('App.HelpFaq', App.HelpCenterAdapter);
//App.Store.registerAdapter('App.HelpFaqCategory', App.HelpCenterAdapter);

App.HelpSetupStep.reopen({
	htmlId: function() {
	  return 'helpcenter-step-' + this.get('id');
	}.property('id')
});

App.HelpFaq.reopen({
	htmlId: function() {
	  return 'helpcenter-faq-' + this.get('id');
	}.property('id')
});
