(function () {

var toDecimal = function(key, places) {
	return Ember.computed(function() {
		return parseFloat(this.get(key)).toFixed(places);
	}).property(key);
};

var EPlanRiderMixin = Ember.Mixin.create({
	riderTypeRiders: function() {
		var riders = this.get('riders');
		var riderTypeRidersMap = {};
		riders.forEach(function(rider){
			if(!(rider.riderTypeDisplayName in riderTypeRidersMap)){
				riderTypeRidersMap[rider.riderTypeDisplayName] = [];
			}
			riderTypeRidersMap[rider.riderTypeDisplayName].push(rider);
		});
		var riderTypeRiders = [];
		for (var riderTypeDisplayName in riderTypeRidersMap) {
			if (riderTypeRidersMap.hasOwnProperty(riderTypeDisplayName)) {
				riderTypeRiders.push({
					riderTypeDisplayName: riderTypeDisplayName,
					riders: riderTypeRidersMap[riderTypeDisplayName]
				});
			}
		}
		return riderTypeRiders;
	}.property('riders'),
	riderTypes: function() {
		var riderTypeRiders = this.get('riderTypeRiders');
		return (riderTypeRiders || []).mapBy('riderTypeDisplayName');
	}.property('riderTypeRiders'),
});

var _EPlanMixin = Ember.Mixin.create({
	cobraTotalYouPremium: function() {
		return( parseFloat(this.get('employee.employeeCobra.computedAdminFee')) *  parseFloat(this.get('totalYouPremium'))).toFixed(2);
	}.property('totalYouPremium', 'employee.employeeCobra.computedAdminFee'),
	cobraTotalYouAndSpousePremium: function() {
		return( parseFloat(this.get('employee.employeeCobra.computedAdminFee')) *  parseFloat(this.get('totalYouAndSpousePremium'))).toFixed(2);
	}.property('totalYouAndSpousePremium', 'employee.employeeCobra.computedAdminFee'),
	cobraTotalYouAndChildPremium: function() {
		return( parseFloat(this.get('employee.employeeCobra.computedAdminFee')) *  parseFloat(this.get('totalYouAndChildPremium'))).toFixed(2);
	}.property('totalYouAndChildPremium', 'employee.employeeCobra.computedAdminFee'),
	cobraTotalFamilyPremium: function() {
		return( parseFloat(this.get('employee.employeeCobra.computedAdminFee')) *  parseFloat(this.get('totalFamilyPremium'))).toFixed(2);
	}.property('totalFamilyPremium', 'employee.employeeCobra.computedAdminFee'),
	youPremiumCommas: toDecimal('youPremium', 2),
	youContributionCommas: toDecimal('youContribution', 2),
	totalYouPremium: function() {
		return parseFloat(this.get('youPremium') + this.get('youContribution')).toFixed(2);
	}.property('youPremium', 'youContribution'),
	youAndSpousePremiumCommas: toDecimal('youAndSpousePremium', 2),
	youAndSpouseContributionCommas: toDecimal('youAndSpouseContribution', 2),
	totalYouAndSpousePremium: function() {
		return parseFloat(this.get('youAndSpousePremium') + this.get('youAndSpouseContribution')).toFixed(2);
	}.property('youAndSpousePremium', 'youAndSpouseContribution'),
	youAndChildPremiumCommas: toDecimal('youAndChildPremium', 2),
	youAndChildContributionCommas: toDecimal('youAndChildContribution', 2),
	totalYouAndChildPremium: function() {
		return parseFloat(this.get('youAndChildPremium') + this.get('youAndChildContribution')).toFixed(2);
	}.property('youAndChildPremium', 'youAndChildContribution'),
	familyPremiumCommas: toDecimal('familyPremium', 2),
	familyContributionCommas: toDecimal('familyContribution', 2),
	totalFamilyPremium: function() {
		return parseFloat(this.get('familyPremium') + this.get('familyContribution')).toFixed(2);
	}.property('familyPremium', 'familyContribution')
});

var attr = DS.attr;
App.EPlan = DS.Model.extend(_EPlanMixin, EPlanRiderMixin, {
	company: DS.belongsTo('App.Company'),
	employee: DS.belongsTo('App.AllEmployee'),
	plan: DS.belongsTo('App.Plan'),
	network: DS.belongsTo('App.Network', {inverse: null}),
	companyHealthPlan: DS.belongsTo('App.CompanyHealthPlan'),
	rxCoPayGenericRetail: attr('string'),
	HSA: attr('boolean'),
	HRA: attr('boolean'),
	postACA: attr('boolean'),
	state: attr('string'),
	group: attr('string'),
	name: attr('string'),
	networkSize: attr('number'),
	planUrl: attr('string'),
	rxSupplyDaysRetail: attr('number'),
	pharmacyCoverage: attr('boolean'),
	rxCoPayBrandRetail: attr('string'),
	isShortCircuitPlan: attr('boolean', { defaultValue: false }),
	shortCircuitPlanType: attr('string', { defaultValue: 'A' }),
	planOrder: attr('number'),
	rxCoPayNonFormularyRetail: attr('string'),
	deductibleIndividual: attr('number'),
	deductibleFamily: attr('number'),
	carrier: attr('string'),
	stateCarrier: DS.belongsTo('App.Carrier'),
	isSelected: attr('boolean'),
	specialistCoPay: attr('number'),
	coPay: attr('number'),
	pharmacyDeductible: attr('number'),
	HMOPPO: attr('string'),
	isOldPlan: attr('boolean', { defaultValue: false }),
	coInsurance: attr('number'),
	outOfNetworkCoInsurance: attr('number'),
	oopMaxIndividual: attr('number'),
	oopMaxFamily: attr('number'),
	oopMaxIncludeDeductible: attr('boolean'),
	youPremium: attr('number'),
	youAndSpousePremium: attr('number'),
	youAndChildPremium: attr('number'),
	familyPremium: attr('number'),
	youContribution: attr('number'),
	youAndSpouseContribution: attr('number'),
	youAndChildContribution: attr('number'),
	familyContribution: attr('number'),
	estimatedNormalOOP: attr('string'),
	estimatedSevereOOP: attr('string'),
	estimatedNormalOOPIndex: attr('number'),
	estimatedSevereOOPIndex: attr('number'),
	reasons: attr('string'),
	spanningContribution: attr('boolean'),
	needsPCP: attr('boolean'),
	availableOOS: attr('boolean'),
	isDentalBundled: attr('boolean'),
	isVisionBundled: attr('boolean'),
	isHraEnabled: attr('boolean'),
	isAvailable: attr('boolean'),
	unavailableReason: attr('string'),
	riders: attr('raw'),
	rateStyle: attr('string'),
	emergencyService: attr('string'),
	contributionType: attr('string'),
	hasDentalCoverage: attr('boolean'),
	hasVisionCoverage: attr('boolean'),
	oneFixedCostTotalAmount: attr('number'),
	benefitsData: attr('raw'),
	lineOfCoverage: 'medical',

	nameLimited: function() {
		var name = this.get('name');
		if (name && name.length > 25) {
			return this.get('name').substring(0, 22) + '...';
		}
		return name;
	}.property('name'),
	reasonList: Ember.computed.split('reasons', ':'),
	genRxPlan: function() {
		var name = this.get('name');
		if (name.indexOf('GenRx') != -1) {
			return true;
		}
		return false;
	}.property('name'),
	isHMO: Ember.computed.equal('HMOPPO', 'HMO'),
	isSeeChange: Ember.computed.equal('carrier', 'SeeChange'),
	isSeeChangeEnhanced: function() {
		return this.get('carrier') == 'SeeChange' && /Enhanced/.test(this.get('name'));
	}.property('carrier', 'group'),
	isSeeChangeClassic: function() {
		return this.get('carrier') == 'SeeChange' && /Classic/.test(this.get('name'));
	}.property('carrier', 'group'),
	isSeeChangeHSA: function() {
		return this.get('carrier') == 'SeeChange' && /HSA/.test(this.get('name'));
	}.property('carrier', 'group'),
	isSeeChangeDeluxe: function() {
		return this.get('carrier') == 'SeeChange' && /Deluxe/.test(this.get('name'));
	}.property('carrier', 'group'),
	isSeeChangeHRA: function() {
		return this.get('carrier') == 'SeeChange' && this.get('group') == "HRA";
	}.property('carrier', 'group'),
	isHraPlanAndEnabled: Ember.computed.and('HRA', 'isHraEnabled'),
	validCoPay: function() {
		return !(this.get('HSA') && !this.get('coPay'));
	}.property('HSA', 'coPay'),
	validSpecialistCoPay: function() {
		return !(this.get('HSA') && !this.get('specialistCoPay'));
	}.property('HSA', 'specialistCoPay'),

	deductibleIndividualCommas: Ember.computed.alias('deductibleIndividual'),
	deductibleFamilyCommas: Ember.computed.alias('deductibleFamily'),
	coPayCommas: Ember.computed.alias('coPay'),
	rxCoPayGenericRetailCommas: Ember.computed.alias('rxCoPayGenericRetail'),
	oopMaxIndividualCommas: Ember.computed.alias('oopMaxIndividual'),
	oopMaxFamilyCommas: Ember.computed.alias('oopMaxFamily'),
	isVanilaShortCircuitPlan: Ember.computed.equal('shortCircuitPlanType', 'N'),
	isTypeAShortCircuitPlan: Ember.computed.equal('shortCircuitPlanType', 'A'),
	shortCircuitPlanTypeWithRates: Ember.computed.or('shortCircuitPlanTypeWithRatesNotBenefits', 'isTypeAShortCircuitPlan'),
	shortCircuitPlanTypeWithRatesNotBenefits: Ember.computed.equal('shortCircuitPlanType', 'R'),
	shortCircuitPlanTypeWithBenefitsNotRates: Ember.computed.equal('shortCircuitPlanType', 'B'),
	shortCircuitPlanWithoutBenefitsOrRates: Ember.computed.equal('shortCircuitPlanType', 'N'),
	shortCircuitPlanWithoutRates: Ember.computed.or('shortCircuitPlanTypeWithBenefitsNotRates', 'shortCircuitPlanWithoutBenefitsOrRates'),
	shortCircuitPlanWithoutBenefits: Ember.computed.or('shortCircuitPlanTypeWithRatesNotBenefits', 'shortCircuitPlanWithoutBenefitsOrRates'),
});

App.EDentalPlan = DS.Model.extend(_EPlanMixin, {
	company: DS.belongsTo('App.Company'),
	employee: DS.belongsTo('App.AllEmployee'),
	plan: DS.belongsTo('App.DentalPlan'),
	network: DS.belongsTo('App.Network', {inverse: null}),
	companyHealthPlan: DS.belongsTo('App.CompanyHealthPlan'),
	HMOPPO: attr('string'),
	isShortCircuitPlan: attr('boolean', { defaultValue: false }),
	shortCircuitPlanType: attr('string', { defaultValue: 'A' }),
	isOldPlan: attr('boolean', { defaultValue: false }),
	useNewStyleDentalRates: attr('boolean'),
	carrier: attr('string'),
	stateCarrier: DS.belongsTo('App.Carrier'),
	isSelected: attr('boolean'),
	postACA: attr('boolean'),
	name: attr('string'),
	coInsuranceBasic: attr('string'),
	coInsuranceEndo: attr('string'),
	coInsuranceMajor: attr('string'),
	coInsuranceOrtho: attr('string'),
	coInsurancePerio: attr('string'),
	deductibleIndividual: attr('number'),
	deductibleFamily: attr('number'),
	freePreventCare: attr('boolean'),
	maxBenefits: attr('number'),
	orthoCoverage: attr('boolean'),
	orthoMaxAge: attr('string'),
	planUrl: attr('string'),
	youPremium: attr('number'),
	youAndSpousePremium: attr('number'),
	youAndChildPremium: attr('number'),
	familyPremium: attr('number'),
	youContribution: attr('number'),
	youAndSpouseContribution: attr('number'),
	youAndChildContribution: attr('number'),
	familyContribution: attr('number'),
	spanningContribution: attr('boolean'),
	isVisionBundled: attr('boolean'),
	isAvailable: attr('boolean'),
	unavailableReason: attr('string'),
	rateStyle: attr('string'),
	contributionType: attr('string'),
	hasDentalCoverage: attr('boolean'),
	hasVisionCoverage: attr('boolean'),
	oneFixedCostTotalAmount: attr('number'),
	benefitsData: attr('raw'),
	lineOfCoverage: 'dental',

	nameLimited: function() {
		var name = this.get('name');
		if (name && name.length > 25) {
			return this.get('name').substring(0, 22) + '...';
		}
		return name;
	}.property('name'),
	isHMO: Ember.computed.equal('HMOPPO', 'HMO'),
	deductibleIndividualCommas: Ember.computed.alias('deductibleIndividual'),
	deductibleFamilyCommas: Ember.computed.alias('deductibleFamily'),
	isVanilaShortCircuitPlan: Ember.computed.equal('shortCircuitPlanType', 'N'),
	isTypeAShortCircuitPlan: Ember.computed.equal('shortCircuitPlanType', 'A'),
	shortCircuitPlanTypeWithRates: Ember.computed.or('shortCircuitPlanTypeWithRatesNotBenefits', 'isTypeAShortCircuitPlan'),
	shortCircuitPlanTypeWithRatesNotBenefits: Ember.computed.equal('shortCircuitPlanType', 'R'),
	shortCircuitPlanTypeWithBenefitsNotRates: Ember.computed.equal('shortCircuitPlanType', 'B'),
	shortCircuitPlanWithoutBenefitsOrRates: Ember.computed.equal('shortCircuitPlanType', 'N'),
	shortCircuitPlanWithoutRates: Ember.computed.or('shortCircuitPlanTypeWithBenefitsNotRates', 'shortCircuitPlanWithoutBenefitsOrRates'),
	shortCircuitPlanWithoutBenefits: Ember.computed.or('shortCircuitPlanTypeWithRatesNotBenefits', 'shortCircuitPlanWithoutBenefitsOrRates'),
});

App.EVisionPlan = DS.Model.extend(_EPlanMixin, {
	company: DS.belongsTo('App.Company'),
	employee: DS.belongsTo('App.AllEmployee'),
	plan: DS.belongsTo('App.VisionPlan'),
	network: DS.belongsTo('App.Network', {inverse: null}),
	companyHealthPlan: DS.belongsTo('App.CompanyHealthPlan'),
	carrier: attr('string'),
	stateCarrier: DS.belongsTo('App.Carrier'),
	isSelected: attr('boolean'),
	postACA: attr('boolean'),
	name: attr('string'),
	coPay: attr('number'),
	isShortCircuitPlan: attr('boolean', { defaultValue: false }),
	shortCircuitPlanType: attr('string', { defaultValue: 'A' }),
	isOldPlan: attr('boolean', { defaultValue: false }),
	frames: attr('string'),
	frameAllowable: attr('number'),
	frameFrequency: attr('number'),
	lenses: attr('string'),
	lensAllowable: attr('number'),
	lensFrequency: attr('number'),
	contacts: attr('string'),
	contactsAllowable: attr('number'),
	contactsFrequency: attr('number'),
	exams: attr('string'),
	lasik: attr('boolean'),
	minContribution: attr('number'),
	planUrl: attr('string'),
	youPremium: attr('number'),
	youAndSpousePremium: attr('number'),
	youAndChildPremium: attr('number'),
	familyPremium: attr('number'),
	youContribution: attr('number'),
	youAndSpouseContribution: attr('number'),
	youAndChildContribution: attr('number'),
	familyContribution: attr('number'),
	spanningContribution: attr('boolean'),
	isAvailable: attr('boolean'),
	unavailableReason: attr('string'),
	rateStyle: attr('string'),
	contributionType: attr('string'),
	hasDentalCoverage: attr('boolean'),
	hasVisionCoverage: attr('boolean'),
	oneFixedCostTotalAmount: attr('number'),
	benefitsData: attr('raw'),
	lineOfCoverage: 'vision',

	nameLimited: function() {
		var name = this.get('name');
		if (name && name.length > 25) {
			return this.get('name').substring(0, 22) + '...';
		}
		return name;
	}.property('name'),
	isVanilaShortCircuitPlan: Ember.computed.equal('shortCircuitPlanType', 'N'),
	isTypeAShortCircuitPlan: Ember.computed.equal('shortCircuitPlanType', 'A'),
	shortCircuitPlanTypeWithRates: Ember.computed.or('shortCircuitPlanTypeWithRatesNotBenefits', 'isTypeAShortCircuitPlan'),
	shortCircuitPlanTypeWithRatesNotBenefits: Ember.computed.equal('shortCircuitPlanType', 'R'),
	shortCircuitPlanTypeWithBenefitsNotRates: Ember.computed.equal('shortCircuitPlanType', 'B'),
	shortCircuitPlanWithoutBenefitsOrRates: Ember.computed.equal('shortCircuitPlanType', 'N'),
	shortCircuitPlanWithoutRates: Ember.computed.or('shortCircuitPlanTypeWithBenefitsNotRates', 'shortCircuitPlanWithoutBenefitsOrRates'),
	shortCircuitPlanWithoutBenefits: Ember.computed.or('shortCircuitPlanTypeWithRatesNotBenefits', 'shortCircuitPlanWithoutBenefitsOrRates'),
});

App.ESection = DS.Model.extend({
	name: attr('string'),
	path: attr('string'),
	isCompleted: attr('boolean'),
	subsections: DS.hasMany("App.ESubsection")
});

App.ESubsection = DS.Model.extend({
	name: attr('string'),
	path: attr('string'),
	isCompleted: attr('boolean'),
	attributes: attr('string'),
	section: DS.belongsTo('App.ESection'),
	attrList: Ember.computed.split('attributes', ', '),
});

App.PersonalInfo = DS.Model.extend({
	objId: attr('number'),
	socialSecurity: attr('string'),
	socialSecurityEnc: attr('string'),
	firstName: attr('string'),
	lastName: attr('string'),
	hireDate: attr('string'),
	maritalStatus: attr('string'),
	haveChildren: attr('string'),
	numberChildren: attr('string'),
	marriageDate: attr('string'),
	marriageState: attr('string'),
	marriageCountry: attr('string'),
	spouseDPEmployed: attr('string'),
	spouseDPEmployer: attr('string'),
	spouseDPEmployerAddress: attr('string'),
	employmentStatus: attr('string'),
	jobTitle: attr('string'),
	homeAddress: attr('string'),
	homeAddress2: attr('string'),
	homeCity: attr('string'),
	homeState: attr('string'),
	homeZip: attr('string'),
	homePhone: attr('string'),
	email: attr('string'),
	contactPreference: attr('string'),
	dob: attr('string'),
	gender: attr('string'),
	salary: attr('string'),
	hoursPerWeek: attr('number'),
	numberOfDependents: attr('number'),
	isSpouseDPEmployed: Ember.computed.equal('spouseDPEmployed', 'yes'),
	isMarried: function() {
		return (this.get('maritalStatus') == 'Married' || this.get('maritalStatus') == 'Domestic Partner');
	}.property('maritalStatus'),
	marriageText: function() {
		if (this.get('maritalStatus') == 'Married') {
			return "marriage";
		}

		if (this.get('maritalStatus') == 'Domestic Partner') {
			return "domestic partnership";
		}
	}.property('maritalStatus'),
	doesHaveChildren: Ember.computed.equal('haveChildren', 'yes'),
});

App.Dependent = DS.Model.extend({
	version_id: attr('number'),
	objId: attr('number'),
	employee: DS.belongsTo('App.AllEmployee'),
	type: attr('string'),
	status: attr('string'),
	effectiveDate: attr('string'),
	dentalEffectiveDate: attr('string'),
	visionEffectiveDate: attr('string'),
	cancelledDate: attr('string'),
	dentalCancelledDate: attr('string'),
	visionCancelledDate: attr('string'),
	marriageDate: attr('string'),
	dateOfBirth: attr('string'),
	age: attr('string'),
	firstName: attr('string'),
	lastName: attr('string'),
	socialSecurity: attr('string'),
	socialSecurityEnc: attr('string'),
	gender: attr('string'),
	sameAddress: attr('boolean'),
	sameEmail: attr('boolean'),
	samePCP: attr('boolean'),
	sameDentalPCP: attr('boolean'),
	address: attr('string'),
	address2: attr('string'),
	city: attr('string'),
	state: attr('string'),
	zip: attr('string'),
	email: attr('string'),
	reason: attr('string'),
	proofUrl: attr('string'),
	proofDate: attr('string'),
	proofType: attr('string'),
	isCourtOrderedDependent: attr('boolean'),
	courtOrder: DS.belongsTo('App.CourtOrder'),
	courtOrderEndDate: attr('string'),
	isCourtOrderActive: attr('boolean'),
	isMarried: attr('boolean'),
	isFullTimeStudent: attr('boolean'),
	hasStateException: attr('boolean'),
	height: attr('string'),
	weight: attr('string'),
	enrollInMedical: attr('boolean'),
	enrolledInMedical: attr('boolean'),
	medicalApprovalStatus: attr('string'),
	medicalEnrollment: DS.belongsTo('App.EmployeeHealthEnrollment'),
	enrollInDental: attr('boolean'),
	enrolledInDental: attr('boolean'),
	dentalApprovalStatus: attr('string'),
	dentalEnrollment: DS.belongsTo('App.EmployeeHealthEnrollment'),
	enrollInVision: attr('boolean'),
	enrolledInVision: attr('boolean'),
	visionApprovalStatus: attr('string'),
	visionEnrollment: DS.belongsTo('App.EmployeeHealthEnrollment'),
	isSmoker: attr('boolean'),
	isDependentDisabled: attr('boolean'),
	autoSelectPCP: attr('boolean'),
	autoSelectDentalPCP: attr('boolean'),
	hmoPhysicianName: attr('string'),
	hmoPhysicianProviderNumber: attr('string'),
	hmoPhysicianProviderPAOrMGNumber: attr('string'),
	hmoPhysicianProviderPPGNumber: attr('string'),
	hmoPhysicianExistingPatient: attr('boolean'),
	hmoDentalName: attr('string'),
	hmoDentalProviderNumber: attr('string'),
	hmoDentalExistingPatient: attr('boolean'),
	havePersonalInfo: attr('boolean'),
	previousCoverage: DS.belongsTo('App.DependentPreviousCoverage'),
	dependentHealthEnrollments: DS.hasMany('App.DependentHealthEnrollment'),
	activeDependentHealthEnrollments: Ember.computed.filterByProperty('dependentHealthEnrollments', 'isActive'),
	dependentMedicalEnrollments: Ember.computed.filterByProperty('activeDependentHealthEnrollments', 'lineOfCoverage', 'medical'),
	dependentDentalEnrollments: Ember.computed.filterByProperty('activeDependentHealthEnrollments', 'lineOfCoverage', 'dental'),
	dependentVisionEnrollments: Ember.computed.filterByProperty('activeDependentHealthEnrollments', 'lineOfCoverage', 'vision'),
	courtOrderLinesOfCoverage: attr('raw'),

	lifeDisabilityEnrollments: DS.hasMany('App.DependentLifeDisabilityEnrollment'),
	sameContactInformation: Ember.computed.and('sameEmail', 'sameAddress'),
	typeInLowerCase: function() {
		return this.get('type').toLowerCase();
	}.property('type'),
	getLifeDisabilityEnrollment: function(lineOfCoverage) {
		return this.get('lifeDisabilityEnrollments').findProperty('lineOfCoverage', lineOfCoverage);
	},
	lifeEnrollment: function() {
		return this.getLifeDisabilityEnrollment(App._LDEAbstractModel.prototype.LIFE);
	}.property('lifeDisabilityEnrollments.@each'),
	addEnrollment: function() {
		return this.getLifeDisabilityEnrollment(App._LDEAbstractModel.prototype.ADD);
	}.property('lifeDisabilityEnrollments.@each'),
	isNotEnrolled: function() {
		if (!(this.get('medicalCoverageStartDateText') || this.get('dentalCoverageStartDateText') || this.get('visionCoverageStartDateText'))) {
			return true;
		}
		return false;
	}.property('medicalCoverageStartDateText', 'dentalCoverageStartDateText', 'visionCoverageStartDateText'),
	qualifyingEvent: DS.belongsTo('App.QualifyingEvent'),
	hasMedicalCoverage: attr('boolean'),
	hasDentalCoverage: attr('boolean'),
	hasVisionCoverage: attr('boolean'),
	isDependentEnrolledInAllLines: Ember.computed.and('hasMedicalCoverage', 'hasDentalCoverage', 'hasVisionCoverage'),
	hasCoverage: Ember.computed.or('hasMedicalCoverage', 'hasDentalCoverage', 'hasVisionCoverage'),
	cannotRemove: Ember.computed.or('hasCoverage', 'isCourtOrderActive'),
	cannotEdit: Ember.computed.or('isCourtOrderActive'),

	checkBoxStatus : function(dhes, enrollInFlag) {
		if (dhes.get('length')) {
			var dhe = null;
			dhes.forEach(function(eachDhe) {
				if (!dhe || dhe.get('id') < eachDhe.get('id')) {
						dhe = eachDhe;
				}
			});

			return dhe.get('type') == '+';
		}

		return enrollInFlag;
	},
	medicalCheckBoxStatus: function() {
		return this.checkBoxStatus(this.get('dependentMedicalEnrollments'), this.get('enrollInMedical'));
	}.property('dependentMedicalEnrollments', 'enrollInMedical'),
	dentalCheckBoxStatus: function() {
		return this.checkBoxStatus(this.get('dependentDentalEnrollments'), this.get('enrollInDental'));
	}.property('dependentDentalEnrollments', 'enrollInDental'),
	visionCheckBoxStatus: function() {
		return this.checkBoxStatus(this.get('dependentVisionEnrollments'), this.get('enrollInVision'));
	}.property('dependentVisionEnrollments', 'enrollInVision'),
	isCobraMedicalReadOnly: function() {
		return this.get('employee.employeeCobra.isBorEmployeeAlreadyCovered') || !this.get('enrolledInMedical');
	}.property('employee.employeeCobra.isBorEmployeeAlreadyCovered', 'enrolledInMedical'),
	isCobraDentalReadOnly: function() {
		return this.get('employee.employeeCobra.isBorEmployeeAlreadyCovered') || !this.get('enrolledInDental');
	}.property('employee.employeeCobra.isBorEmployeeAlreadyCovered', 'enrolledInDental'),
	isCobraVisionReadOnly: function() {
		return this.get('employee.employeeCobra.isBorEmployeeAlreadyCovered') || !this.get('enrolledInVision');
	}.property('employee.employeeCobra.isBorEmployeeAlreadyCovered', 'enrolledInVision'),

	isSelf: Ember.computed.equal('type', 'self'),

	isSpouse: function() {
		var type = this.get('type');

		return type && (type == 'Spouse' || type == 'Domestic Partner' || type == 'spouse');
	}.property('type'),

	isChild: function() {
		var type = this.get('type');

		return type && (type == 'Child' || type == 'child');
	}.property('type'),

	isHMO: Ember.computed.or('hmoPhysicianName', 'hmoPhysicianProviderNumber'),
	isDHMO: Ember.computed.or('hmoDentalName', 'hmoDentalProviderNumber'),
	fullName: function() {
		return (this.get('firstName') || "") + " " + (this.get('lastName') || "");
	}.property('firstName', 'lastName'),
	height_ft: function() {
		var height = this.get('height');
		if (height) {
			var ht = height.split(" ")[0];
			return ht.replace("'", "");
		}

		return "";
	}.property('height'),
	height_in: function() {
		var height = this.get('height');
		if (height) {
			var ht = height.split(" ")[1];
			if (ht) {
				return ht.replace("\"", "");
			}
		}

		return "";
	}.property('height'),
	nameAndType: function() {
		return this.get('fullName') + ' (' + this.get('type').capitalize() + ')';
	}.property('fullName', 'type'),
	hasDependentEnrollmentBegun: Ember.computed.or('medicalCheckBoxStatus', 'dentalCheckBoxStatus', 'visionCheckBoxStatus', 'isSelf'),

	proofDesc: function() {
		var proofMap = {
			'marriage': "your marriage license",
			'birth': "your child's birth certificate, or a letter from the hospital indicating the child's birth",
			'adoption': "your adoption papers",
			'coveragelost': "your dependent's termination papers",
			'guardian': "your guardianship papers",
		};

		return proofMap[this.get('reason')];
	}.property('reason'),
	proofDateCopy: function() {
		var proofMap = {
			'marriage': "When were you married?",
			'birth': "Birth date?",
			'adoption': "Date of adoption?",
			'coveragelost': "When was the coverage lost?",
			'guardian': "When did you become the legal guardian?",
		};

		return proofMap[this.get('reason')];
	}.property('reason'),
	isBirthReason: Ember.computed.equal('reason', 'birth'),
	medicalCoverageStartDate: attr('string'),
	dentalCoverageStartDate: attr('string'),
	visionCoverageStartDate: attr('string'),
	medicalCoverageEndDate: attr('string'),
	dentalCoverageEndDate: attr('string'),
	visionCoverageEndDate: attr('string'),
	stateExceptionAgeLimit: function () {
		var DEPENDENT_AGE_OUT_STATE_EXCEPTIONS = {
			FL: 30, NE: 30, NJ: 31, NY: 30, OH: 28, PA: 30, WI: 27,
		};
		if (this.get('state') == this.get('employee.state')) {
			return DEPENDENT_AGE_OUT_STATE_EXCEPTIONS[this.get('state')];
		}
		return null;
	}.property('state', 'employee.state'),


	// [MDV-162] belu: differentiate the display based on approval
	// status : starts 		-> approved and covered in the future
	//          since 		-> approved and covered from the past
	//          scheduled 	-> unapproved and scheduled to start in the future
	//          retroactive -> unapproved and scheduled to start from the past
	coverageStatus: function(lineOfCoverage){
		var coverageStartDate = this.get(lineOfCoverage.toLowerCase() + 'CoverageStartDate');

		if (coverageStartDate) {
			var isApproved = this.get('enrollIn' + lineOfCoverage.capitalize()) &&
				this.get('employee.is' + lineOfCoverage.capitalize() + 'Approved');
			var isAfter = moment(coverageStartDate).isAfter(moment(), 'day');

			if (isApproved && isAfter) {
				return 'starts';
			} else if (isApproved && !isAfter) {
				return 'since';
			} else if (!isApproved && isAfter) {
				return 'scheduled';
			} else if (!isApproved && !isAfter) {
				return 'retroactive';
			}
		}

		return null;
	},
	coverageStartDateText: function(lineOfCoverage, isDescriptive){
		var status = this.coverageStatus(lineOfCoverage);

		if (status) {
			var startDateText = {
				'starts' : 'Starts ',
				'since' : 'Since ',
				'scheduled' : 'Scheduled to begin on ',
				'retroactive' : 'Retroactive start on ',
			};
			var startDateHoverText = {
				'starts' : 'Coverage will start on ',
				'since' : 'Covered since ',
				'scheduled' : 'Coverage scheduled to start on ',
				'retroactive' : (this.get('employee.' + lineOfCoverage.toLowerCase() + 'Plan.stateCarrier.displayName') || 'The carrier') +
					' hasn’t approved coverage yet. If approved, coverage will have a retroactive start date of ',
			};
			var coverageStartDate = this.get(lineOfCoverage.toLowerCase() + 'CoverageStartDate');

			if (isDescriptive) {
				return  startDateHoverText[status] + zen.tableDate(coverageStartDate);
			}
			return  startDateText[status] + zen.tableDate(coverageStartDate);
		}

		return null;
	},

	medicalCoverageStatusText: function() {
		return this.coverageStartDateText('medical', true);
	}.property('medicalCoverageStartDate', 'enrollInMedical', 'employee.isMedicalApproved', 'employee.medicalPlan.stateCarrier.displayName'),
	dentalCoverageStatusText: function() {
		return this.coverageStartDateText('dental', true);
	}.property('dentalCoverageStartDate', 'enrollInDental', 'employee.isDentalApproved', 'employee.dentalPlan.stateCarrier.displayName'),
	visionCoverageStatusText: function() {
		return this.coverageStartDateText('vision', true);
	}.property('visionCoverageStartDate', 'enrollInVision', 'employee.isVisionApproved', 'employee.visionPlan.stateCarrier.displayName'),

	medicalCoverageStartDateText: function() {
		return this.coverageStartDateText('medical', false);
	}.property('medicalCoverageStartDate', 'enrollInMedical', 'employee.isMedicalApproved'),
	dentalCoverageStartDateText: function() {
		return this.coverageStartDateText('dental', false);
	}.property('dentalCoverageStartDate', 'enrollInDental', 'employee.isDentalApproved'),
	visionCoverageStartDateText: function() {
		return this.coverageStartDateText('vision', false);
	}.property('visionCoverageStartDate', 'enrollInVision', 'employee.isVisionApproved'),


	// end date texts
	coverageEndDateText: function(coverageEndDate) {
		if (coverageEndDate) {
			return "Ends " + zen.tableDate(coverageEndDate);
		}

		return null;
	},
	medicalCoverageEndDateText: function() {
		return this.coverageEndDateText(this.get('medicalCoverageEndDate'));
	}.property('medicalCoverageEndDate'),
	dentalCoverageEndDateText: function() {
		return this.coverageEndDateText(this.get('dentalCoverageEndDate'));
	}.property('dentalCoverageEndDate'),
	visionCoverageEndDateText: function() {
		return this.coverageEndDateText(this.get('visionCoverageEndDate'));
	}.property('visionCoverageEndDate'),
	genderText: function() {
		if (this.get('gender') == 'M') {
			return 'Male';
		}

		return 'Female';
	}.property('gender'),
	coverageText: function() {
		var plans = '';
		if (this.get('enrollInMedical')) {
			plans += 'Medical';
			if (this.get('enrollInDental')) {
				plans += (this.get('enrollInVision')) ? ', Dental & Vision' : ' & Dental';
			}
			else if (this.get('enrollInVision')) {
				plans += ' & Vision';
			}
		}
		else if (this.get('enrollInDental')) {
			plans += 'Dental' + ((this.get('enrollInVision')) ? ' & Vision' : '');
		}
		else if (this.get('enrollInVision')) {
			plans += 'Vision';
		}

		return plans;
	}.property('enrollInMedical', 'enrollInDental', 'enrollInVision'),
	hasCourtOrderLineOfCoverage: function(lineOfCoverage) {
		return this.get('courtOrderLinesOfCoverage').contains(lineOfCoverage);
	},
});

App.CourtOrder = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	proofUrl: attr('string'),
	orderDate: attr('string'),
	noticeDate: attr('string'),
	processingDate: attr('string'),
	employeeMaxWithholdPercentage: attr('number'),
	medicalPlan: DS.belongsTo('App.Plan'),
	dentalPlan: DS.belongsTo('App.DentalPlan'),
	visionPlan: DS.belongsTo('App.VisionPlan'),
	medicalEffectiveDate: attr('string'),
	dentalEffectiveDate: attr('string'),
	visionEffectiveDate: attr('string'),
	signature: DS.belongsTo('App.Signature'),
	dependents: DS.hasMany("App.Dependent"),
	isProcessed: attr('boolean'),
});

var inclusionHash = function(properties) {
	var ret = {};

	properties.forEach(function(prop) {
		ret[prop] = true;
	});

	return ret;
};

App.WaiverLogicMixin = Ember.Mixin.create({
	company: null,
	dependent: null,
	lineOfCoverage: null,

	requiresCarrierInfo: Ember.computed(function() {
		return this.get('waiveReason.requiresCarrierInfo') && this.get('dependent.type') == 'self';
	}).property('waiveReason.requiresCarrierInfo', 'dependent.type'),

	isOtherReason: Ember.computed(function() {
		return this.get('waiveReason.name') == 'My reason is not listed';
	}).property('waiveReason'),

	validate: function() {
		var errors = [];

		// make sure reason is valid
		if (this.get('isOtherReason') && Ember.isEmpty(this.get('otherReason'))) {
			errors.push("You must specify your 'other' reason for declining");
		}

		if (this.get('requiresCarrier')) {
			if (Ember.isEmpty(this.get('otherCarrier'))) {
				errors.push("You must specify the other carrier you are covered by");
			}
		}

		if (this.get('requiresId')) {
			if (Ember.isEmpty(this.get('otherIDNumber'))) {
				errors.push("You must specify the ID of your other insurance coverage");
			}
		}

		if (this.get('requiresIdImage') && Ember.isEmpty(this.get('idCardUrl'))) {
			errors.push("You must upload photo proof of your other insurance ID card");
		}

		return errors;
	},

	requiresIdImage: Ember.computed(function() {
		if (this.get('lineOfCoverage') != 'medical') {
			return false;
		}

		if (!this.get('requiresCarrierAndId')) {
			return false;
		}

		var carriersRequiring = [
			'isAetnaNYSelected',
			'isSeeChangeSelected',
			'isHarvardPilgrimSelected',
			'isBlueCrossSelected',
			'isUnitedMDSelected',
		];

		var company = this.get('company');
		return !!carriersRequiring.find(function(key) {
			return company && company.get(key);
		});
	}).property('requiresCarrierAndId', 'company'),

	requiresOnlyCarrier: Ember.computed(function() {
		var company = this.get('company');

		if (!this.get('requiresCarrierInfo')) {
			return false;
		}

		var carriersRequiring = [
			'isKaiserSelected',
			'isEmpireSelected',
		];

		var company = this.get('company');
		return !!carriersRequiring.find(function(key) {
			return company && company.get(key);
		});
	}).property('requiresCarrierInfo'),

	requiresCarrier: Ember.computed.or('requiresOnlyCarrier', 'requiresCarrierAndId'),
	requiresId: Ember.computed.and('requiresCarrierAndId', 'isMedicalCoverage'),

	isMedicalCoverage: Ember.computed.equal('lineOfCoverage', 'medical'),

	requiresCarrierAndId: Ember.computed(function() {
		var company = this.get('company');

		if (!this.get('requiresCarrierInfo')) {
			return false;
		}

		var carriersRequiring = [
			'isEmblemSelected',
			'isCalChoiceSelected',
			'isBlueCrossRISelected',
			'isSeeChangeSelected',
			'isBlueShieldSelected',
			'isAetnaNYSelected',
			'isBlueCrossSelected',
			'isHarvardPilgrimSelected',
			'isOxfordSelected',
			'isUnitedMDSelected',
		];

		var company = this.get('company');
		return !!carriersRequiring.find(function(key) {
			return company && company.get(key);
		});
	}).property('requiresCarrierInfo', 'company'),

	waivers: Ember.computed(function() {
		return App.WaiverMetadata.find();
	}).property(),

	declineReasons: Ember.computed(function() {
		var waivers = this.get('waivers').filter(function(waiver) {
			return !waiver.get('softDeleted');
		});

		if (!waivers.everyProperty('isLoaded')) {
			return [];
		}

		var dependentType = this.get('dependent.type');

		var applicableWaivers = waivers.filter(function(waiver) {
			var types = waiver.get('applicableDependentTypes').split(',');

			if (types.indexOf('all') > -1) {
				return true;
			}

			return types.indexOf(dependentType) > -1;
		}).sort(function(a, b) {
			return a.get('name').localeCompare(b.get('name'));
		});

		/* Reorder reasons into categories to make interpreting
		 * choices easier for user.
		 */
		var familySponsored = [];
		var govSponsored = [];
		var individualSponsored = [];
		var otherOrgSponsored = [];
		var otherReasons = [];

		// Categorize decline reasons so that they can be
		// grouped in dropdown list of options for easier viewing
		applicableWaivers.forEach(function(waiver, i) {
			var waiverCategory = waiver.get('category');
			switch (waiverCategory) {
				case 'Other Organization-Sponsored':
					otherOrgSponsored.push(waiver);
					break;
				case 'Family-Sponsored':
					familySponsored.push(waiver);
					break;
				case 'Government-Sponsored':
					govSponsored.push(waiver);
					break;
				case 'Individual-Sponsored':
					individualSponsored.push(waiver);
					break;
				default:
					otherReasons.push(waiver);
			}
		});

		var groupedWaivers = familySponsored.concat(govSponsored).concat(individualSponsored).concat(otherOrgSponsored).concat(otherReasons);

		return groupedWaivers;
	}).property('company.isLoaded', 'dependent.type', 'company.displayName', 'waivers.@each.isLoaded'),

	capitalizedLineOfCoverage: Ember.computed(function() {
		return Ember.String.capitalize(this.get('lineOfCoverage'));
	}).property('lineOfCoverage'),

	noOtherCoverage: Ember.computed.equal('reason', 'No other coverage at this time'),
});

App.EnrollmentWaiver = DS.Model.extend(App.WaiverLogicMixin, {
	enrollment: DS.belongsTo('App.EmployeeHealthEnrollment'),
	dependent: DS.belongsTo('App.Dependent'),

	// the following signature related field are depreated
	signature: attr('string'),
	// use the signature object instead
	authSignature: DS.belongsTo('App.Signature'),
	reason: attr('string'),
	waiveReason: DS.belongsTo('App.WaiverMetadata'),
	otherReason: attr('string'),
	otherCarrier: attr('string'),
	otherIDNumber: attr('string'),
	idCardUrl: attr('string'),
	haveDependents: attr('boolean'),
	datetime: attr('string'),

	// For the App.WaiverLogicMixin
	company: Ember.computed.alias('dependent.employee.company'),
	lineOfCoverage: Ember.computed.alias('enrollment.coverage_type'),

	datetimePretty: Ember.computed(function() {
		var datetime = this.get('datetime');

		return moment(datetime).format('MM/DD/YY @ h:mm A');
	}).property('datetime'),

	hasOverride: Ember.computed.bool('override'),

	override: Ember.computed.alias('overrides.firstObject'),
	overrides: Ember.computed(function() {
		return DS.PromiseObject.create({
			promise: DS.appStore.find('enrollmentWaiverOverride', {
				baseWaiver_id: this.get('id'),
			}),
		});
	}).property(),
});

App.QualifyingEventWaiver = DS.Model.extend(App.WaiverLogicMixin, {
	qualifyingEvent: DS.belongsTo('App.QualifyingEvent'),
	lineOfCoverage: attr('string'),

	// the following signature related field are depreated
	signature: attr('string'),
	// use the signature object instead
	authSignature: DS.belongsTo('App.Signature'),
	reason: attr('string'),
	waiveReason: DS.belongsTo('App.WaiverMetadata'),
	otherReason: attr('string'),
	otherCarrier: attr('string'),
	otherIDNumber: attr('string'),
	idCardUrl: attr('string'),
	haveDependents: attr('boolean'),
	datetime: attr('string'),

	// For the App.WaiverLogicMixin
	dependent: Ember.computed.alias('qualifyingEvent.dependent'),
	company: Ember.computed.alias('dependent.employee.company'),
});

App.PreviousCoverage = DS.Model.extend({
	havePreviousCoverageAtCarrier: attr('string'),
	medicalRecordNumber: attr('string'),
	continuePreviousCoverage: attr('string'),
	havePriorCoverage: attr('string'),
	havePriorDentalCoverage: attr('string'),
	priorCoverageCarrier: attr('string'),
	priorCoveragePolicyID: attr('string'),
	priorCoverageBeginDate: attr('string'),
	priorCoverageEndDate: attr('string'),
	priorCoverageType: attr('string'),
	doesHaveCoverageAtCarrier: Ember.computed.equal('havePreviousCoverageAtCarrier', 'yes'),
	doesHavePriorCoverage: Ember.computed.equal('havePriorCoverage', 'yes'),
	doesHavePriorDentalCoverage: Ember.computed.equal('havePriorDentalCoverage', 'yes'),
});

App.DependentPreviousCoverage = DS.Model.extend({
	employee: attr('number'),
	dependent: attr('number'),
	firstName: attr('string'),
	lastName: attr('string'),
	type: attr('string'),
	sameAsEmployee: attr('boolean'),
	havePreviousCoverage: attr('string'),
	carrier: attr('string'),
	isInsuranceActive: attr('boolean'),
	startDate: attr('string'),
	endDate: attr('string'),
	reasonForEnding: attr('string'),
	insuranceType: attr('string'),
	otherType: attr('string'),
	policyID: attr('string'),
	providedByEmployer: attr('string'),
	employementStatus: attr('string'),
	contractType: attr('string'),
	isPrimaryCoverage: attr('boolean'),
	isMedicalCovered: attr('boolean'),
	isVisionCovered: attr('boolean'),
	isDentalCovered: attr('boolean'),
	isMedicare: attr('boolean'),
	isPartA: attr('boolean'),
	isPartB: attr('boolean'),
	isPartD: attr('boolean'),
	medicareClaimNumber: attr('string'),
	isSixtyFive: attr('string'),
	isDependentDisabled: attr('boolean'),
	isReceivingSSDiablilityInsurance : attr('string'),
	hasESRD: attr('boolean'),
	esrdEffectiveDate: attr('string'),
	continueOtherCoverage: attr('string'),
	continueCarrier: attr('string'),
	continueEffectiveDate: attr('string'),
	continueEndDate: attr('string'),
	continueOtherDentalCoverage: attr('string'),
	continueDentalCarrier: attr('string'),
	isSelf: Ember.computed.equal('type', 'self'),
	isInsuranceTypeOther: Ember.computed.equal('insuranceType', 'Other'),
	doesHavePreviousCoverage: Ember.computed.equal('havePreviousCoverage', 'yes'),
	doesContinueOtherCoverage: Ember.computed.equal('continueOtherCoverage', 'yes'),
	doesContinueOtherDentalCoverage: Ember.computed.equal('continueOtherDentalCoverage', 'yes'),
	isTypeOther: Ember.computed.equal('insuranceType', 'Other'),
	previousCoverageName: Ember.computed.prefix('havePreviousCoverage', 'id'),
	continueCoverageName: Ember.computed.prefix('continueCoverageName', 'id'),
});

App.OfficerStatement = DS.Model.extend({
	percentOwnership: attr('string'),
	entityType: attr('string'),
	docProofUrl: attr('string'),
	docProofName: attr('string'),
	// the following signature related field are depreated
	signature: attr('string'),
	name: attr('string'),
	title: attr('string'),
	date: attr('string'),
	// use the signature object instead
	authSignature: DS.belongsTo('App.Signature'),
	isSection1: function() {
		var entityType = this.get('entityType');
		if (entityType &&
			(entityType == 'CO' || entityType == 'LLC' || entityType == 'LC' || entityType == 'S-Corporation'))
		{
			return true;
		}
		return false;
	}.property('entityType'),
	isSection2: function() {
		var entityType = this.get('entityType');
		if (entityType && (entityType == 'PA' || entityType == 'LLP')) {
			return true;
		}
		return false;
	}.property('entityType'),
	isSection3: Ember.computed.equal('entityType', 'Sole Proprietorship'),
});

App.DeclineInfo = DS.Model.extend({
	employee: attr('number'),
	socialSecurity: attr('string'),
	socialSecurityEnc: attr('string'),
	firstName: attr('string'),
	lastName: attr('string'),
	hireDate: attr('string'),
	maritalStatus: attr('string'),
	jobTitle: attr('string'),
	homeAddress: attr('string'),
	homeCity: attr('string'),
	homeState: attr('string'),
	homeZip: attr('string'),
	numberOfDependents: attr('string'),
	isMarried: function() {
		return this.get('maritalStatus') == "Married" || this.get('maritalStatus') == "Domestic Partner";
	}.property('maritalStatus'),
	hasChildren: Ember.computed(function() {
		var numPartners = this.get('isMarried') ? 1 : 0;

		return this.get('numberOfDependents') > numPartners;
	}).property('numberOfDependents', 'isMarried'),
});

App.CoverageCancellation = DS.Model.extend({
	authDate: attr('string'),
	authName: attr('string'),
	authSignature: attr('string'),
	effectiveEndDate: attr('string'),
	employee: DS.belongsTo('App.AllEmployee'),
	planID: attr('number'),
	previousMemberId: attr('string'),
	reason: attr('string'),
	status: attr('string'),
	type: attr('string'),
});

App.InsuranceCardRequest = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	firstName: attr('string'),
	lastName: attr('string'),
	fullName: function() {
		return (this.get('firstName')||"") + " " + (this.get('lastName')||"").trim();
	}.property('firstName', 'lastName'),
	address: attr('string'),
	city: attr('string'),
	state: attr('string'),
	zip: attr('string'),
	status: attr('string'),
	isAddressUpdated: attr('boolean'),
	isNameUpdated: attr('boolean'),
	createdDate: attr('string'),
	submittedDate: attr('string'),
	needCopyForDependents: attr('boolean'),

	isPastCarrierProcessingTime: function(){
		var submittedDate = this.get('submittedDate');
		if (Ember.isEmpty(submittedDate)) {
			return false;
		} else {
			submittedDate = moment(submittedDate);
			return moment().isAfter(submittedDate.add(14, 'days'), 'day');
		}
	}.property('submittedDate'),
	isClosed: Ember.computed.equal('status', 'Closed'),
	isComplete: Ember.computed.or('isClosed', 'isPastCarrierProcessingTime'),
});

})();
