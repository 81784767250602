(function () {

window.routingFn = function() {
	// This is to avoid loading unnecessary routes for the Microsoft Teams and oauth2 embedded view.
	// TODO: should just abstract out common components from client-app for oauth2 and Microsoft Teams
	// rather than trying to use client-app and then hiding or unloading random stuff
	if(window.location && window.location.pathname && window.location.pathname.indexOf('microsoft_teams') >= 0){
		this.route('employee-directory-for-teams', {path: '/employee-directory-for-teams'});
		return;
	}
	if(window.location && window.location.pathname && window.location.pathname.indexOf('oauth2/platform-authorize') >= 0){
		this.route("employeedirectoryoauth2", { path : "/" }); // for oauth2 AuthorizationView
		return;
	}

	// TODO: lazy-loading: move to boot/router.js when enabling lazy loading
	this.route("index", { path: '/' });
	this.route("baWelcome", { path: '/ba-welcome' });
	this.route("rebrandingMessage", { path: '/rebranding-message' });
	this.route("dashboardRedirectStub", { path: "/z2" });
	this.route("dashboardRedirectStub", { path: "/dashboard" });

	// Lazy-loaded routes. Place any eager routes above (and add update client-app/config/bundles.js)
	this.route("missing", { path : "/missing" });
	this.route("first", { path : "/first" });
	this.route("empty", { path : "/empty" });
	this.route("search", { path : "/search" });
	this.route("oldemployeedirectory", { path : "/employeedirectory/v1" });
	this.route("employeedirectory", { path : "/employeedirectory" });
	this.route("employeeorgchart", { path : "/employeeorgchart" });
	this.route("teamdirectory", { path : "/teamdirectory" });
	this.route("teamorgchart", { path : "/teamorgchart" });
	this.resource("employeebulk", function() {
		this.route("download");
		this.route("upload");
	});
	this.route("employeehistory", { path : "/employeehistory" });
	this.route("employeetearsheet", { path : "/employeetearsheet/:all_employee_id" });
	this.route("employeeeeo", { path : "/employeeeeo/:all_employee_id" });
	this.route("contractortearsheet", { path: "/contractortearsheet/:contractor_id" });
	this.route("backgroundcheck", { path : "/backgroundcheck/:new_hire_id" });
	this.route("employeei9", { path : "/employeei9/:employee_eligibility_id" }, function() {
		this.route('review', {path: "/review"});
		this.route('document-review', {path: "/document-review"});
		this.route('employer-certification', {path: "/employer-certification"});
		this.route('preview', {path: "/preview"});
	});
	this.route("reverifyi9", { path : "/reverifyi9/:employee_eligibility_id" }, function() {
		this.route('section3', {path: "/section3"});
		this.route('preview', {path: "/preview"});
	});
	this.route("employeedocumentupload", { path : "/employeedocumentupload/:all_employee_id" });
	this.route("offerletterrevoke", { path : "/offerletterrevoke/:new_hire_id" });
	this.route("employeerequest", { path : "/employeerequest/:all_employee_id" });
	this.route("employeeColleague", { path : "/employee-colleague/:all_employee_id" });
	this.route("employeeProfile", { path: "/employee-profile/:all_employee_id"}, function() {
		this.route("personalInfo", { path : "/personal-info"});
		this.route("employmentInfo", { path : "/employment-info"});
		this.route("groups", { path: "/groups"});
		this.route("projects", { path: "/projects"});
		this.route("workGroups", { path: "/work-groups"});
		this.route("integrations", { path : "/integrations/:change_request_group_id"});
		this.route("taxInfo", { path : "/tax-info"});
		this.route("bankInfo", { path : "/bank-info"});
		this.route("documents");
		this.route("eligibility");
		this.route("customFields", { path : '/custom-fields'});
		this.route("vaccination", { path : '/vaccination'});
		this.route("termination");
		this.route("securitySettings", { path : '/security-settings'});
		this.route("payrollSettings", { path : '/payroll-settings'});
		this.route("integrations");
		this.route("timeOff", { path : '/time-off'});
		this.route("accountInfo", { path : '/account-info'});
		this.route("blank", { path : '/blank'});
		this.route("time-attendance", { path : '/ta'});
	});
	this.route("changeRequest", { path: '/change-request/:change_request_id'}, function() {
		this.route("effectiveDate", { path: '/effective-date'});
		this.route("edit", { path: '/edit'});
		this.route('edit-groups', {path: '/edit-groups'});
		this.route('groups', {path: '/groups'});
		this.route("review", { path: '/review' });
		this.route("payroll", { path: '/payroll' });
		this.route("employeeClass", { path: '/employee-class' });
		this.route("benefits", { path: '/benefits' });
		this.route("benefitsImpact", { path: '/benefits-impact' });
		this.route("team", { path: '/team' });
		this.route("taxes", { path: '/taxes' });
		this.route("timeOff", { path: '/time-off' });
		this.route("additionalInfo", { path: '/additional-info' });
		this.route("integrations", { path: '/integrations' });
	});


	this.route("guide",  {path: '/guide'}, function () {
		this.route("hiring");
		this.route("onboarding");
		this.route("switching");
		this.route("pto-approve");
		this.route("pto-request");
		this.route("administrators");
	});

	this.route("guide",  {path: '/guide'}, function () {
		this.route("hiring");
		this.route("onboarding");
		this.route("switching");
		this.route("pto-approve");
		this.route("pto-request");
		this.route("administrators");
	});

	this.route("companyOnboarding",  {path: '/company-onboarding'}, function () {
		this.route("start");
		this.route("setup", {path: '/setup'}, function () {
			this.route("details");
			this.route("locations");
			this.route("departments");
			this.route("employees");
			this.route("payroll-provider");
		});
		this.route("complete");
	});
	this.route("companyProfile",  {path: '/company-profile'}, function () {
		this.route("details");
		this.route("custom-integrations");
		this.route("export");
		this.route("taxInfo", {path: '/tax-info'});
		this.route("billingInfo", {path: '/billing-info'});
		this.route("billingInformation", {path: '/billing-information'});
		this.route("planInfo", {path: '/plan-info'});
		this.route("planInformation", {path: '/plan-information'});
		this.route("locations", { path : "/locations" }, function() {
			this.route("companyLocations", {path:'/company-locations'});
			this.route("remoteLocations", {path:'/remote-locations'})
		});
		this.route("projects");
		this.route("admins");
		this.route("managers", {path: '/employee-permissions'});
		this.route("departments");
		this.route("security-settings");
		this.route("renewal");
	});
	this.route('rolesPermissions', {path : '/roles-permissions'}, function() {
		this.route('addAdmin', {path: '/add-admin' }, function() {
			this.route('basicInfo', {path: '/basic-info'});
			this.route('roles', {path: '/roles'});
			this.route('permissions', {path: '/permissions'});
		});
	});
	this.route("primaryAdmin", {path: '/primary-admin'});
	this.route("benAdminAgreement", {path: '/ben-admin-agreement'});
	this.route('plaidVerification', {path: '/plaid-verification' }, function() {
		this.route('bank', {path: '/bank' }, function() {
			this.route('selectBank', {path: '/select-bank'});
			this.route('manualEntry', {path: '/manual-entry'});
			this.route('uploadCheck', {path: '/upload-check'});
			this.route('complete', {path: '/complete'});
		});
	});
	this.route('paymentMethod', { path : '/payment-method' });
	this.resource("approvers", { path : "/approvers" }, function () {
		this.route("overview");
		this.route("editpermissions");
		this.route("addapprover", { "path" : "/:approver_detail_id/addapprover" });
		this.route("showconfirm");
	});
	this.route("attributehistory");
	this.route("teamtearsheet", { path : "/teamtearsheet/:all_employee_id" });
	this.route("requestchanges", { path : "/requestchanges/:change_request_id" });
	this.route("transferemployee", { path :"/transferemployee/:change_request_id" });
	this.route("confirmrequest", { path : "/confirmrequest/:change_request_id" });
	this.route("confirmtransfer", { path : "/confirmtransfer/:change_request_id" });

	this.resource("locations", { path : "/locations" }, function() {
		this.route("overview");
		this.route("addlocation");
		this.route("addcompanytaxinfo", { path : "/addcompanytaxinfo/:company_federal_tax_id" });
		this.route("editlocation", { path : "/editlocation/:company_location_id" });
	});
	// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename or remove, please change in zapp.py as well
	this.route("connectforquotes", { path : "/connectforquotes" });
	this.route("onboardingtobenefits", { path : "/onboardingtobenefits" });
	this.route("syncingquotes", { path : "/syncingquotes" });
	this.route("syncingonboarding", { path : "/syncingonboarding" });

	this.route("syncingptoupload", { path : "/syncingptoupload" });
	this.route("manual", { path : "/manual" });
	this.route("quotedependents", { path : "/quotedependents" });
	this.route("newOrExisting", { path : "/new-or-existing" });

	this.resource("medicalsettings", { path : "/medicalsettings" }, function() {
		this.route("/");
		this.route("overview", { path : "/overview" });
		this.route("openenrollment", { path : "/open-enrollment" });
		this.route("employees", { path : "/employees" }, function() {
			this.route("summary", { path : "/summary" }, function() {
				this.route("details", { path : "/details/:employee_id" });
			});
			this.route("medical", { path : "/medical" }, function() {
				this.route("details", { path : "/details/:employee_id" });
			});
			this.route("dental", { path : "/dental" }, function() {
				this.route("details", { path : "/details/:employee_id" });
			});
			this.route("vision", { path : "/vision" }, function() {
				this.route("details", { path : "/details/:employee_id" });
			});
		});
		this.route("estimate", { path : "/estimate" });
		this.route("switchcarrier", { path : "/switchcarrier" });
		this.route("cobra", { path : "/cobra" });
		this.route("cancel", { path : "/cancel" });
		this.route("documents",{ path: "/documents" });
		// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename, please change in zapp.py as well
		this.route("section",{ path: "/section" });
		this.route("spds",{ path: "/spds" }); // will be removed once /spd is at 100%
		this.route('spd', function() {
			this.route("/");
			this.route('company');
			this.route('info');
			this.route('review');
			this.route('certificates');
			// this.route('notify'); TBD, is this "Notify Employee" or "Retrieve Document"
		});
		this.route('settings', { path: '/settings'}, function() {
			this.route('documentReview', { path: '/document-review' });
		});
	});

	// Employee facing paystubs
	this.route("paystubs", { path: "/paystubs"}, function() {
		this.route("paystub", { path: "/:paystub_id" });
	});

	// Two Factor Setup Wizard
	this.route('2fa', { "path": "/2fa" }, function() {
		this.route("setup", { "path": "/setup" }, function(){
			this.route("selectMethod", { "path": "/select-method" });
			this.route("phone");
			this.route("app");
		});
	});

	// Payroll Start =============================================================
	// third party payroll start
	this.route('payrollIntegrations', { path: '/payroll-integrations' }, function() {
		this.route('syncPayroll', { 'path': '/sync-payroll' }, function() {
			this.route('overview');
			this.route('fixRecords', { 'path': '/fix-records' });
			this.route('download');
			this.route('upload');
			this.route('update');
			this.route('newHire', { 'path': '/new-hire' });
			this.route('termination');
			this.route('bank');
			this.route('complete');
		});
		this.route('syncBack', { 'path': '/sync-back' }, function() {
			this.route('overview', {'path': '/'});
			this.route('upload');
			this.route('results');
			this.route('complete');
		});
		this.route('settings', { path: '/settings' }, function() {
			this.route('deductionsMapping', { path: '/deductions-mapping'});
			this.route('addDeductions', { path: '/add-deductions'});
			this.route('payrollId', { path: '/payroll-id'});
			this.route('workforce', { path: '/workforce' }, function() {
				this.route('coCode', { path: '/co-code' });
				this.route('compensationClass', { path: '/compensation-class'});
				this.route('payClass', { path: '/pay-class' });
				this.route('payrollDepartment', { path: '/payroll-department'});
				this.route('location', { path: '/location' });
				this.route('businessUnit', { path: '/business-unit' });
				this.route('jobTitle', { path: '/job-title' });
				this.route('directDepositDeduction', { path: '/direct-deposit-deduction'}, function() {
					this.route('new', { path: '/new'});
					this.route('edit', { path: '/edit'});
				});
			});
			this.route('paylocity', { path: '/paylocity' });
		});
		this.route('sync', { path: '/sync' }, function() {
			this.route('reports', { path: '/reports' });
			this.route('connect', { path: '/connect' });
		});
		this.route('dashboard'); //SMP Dashboard
		this.route('dashboardV2', { 'path': '/dashboard/overview' });
		this.route('help');
		this.route('pastReports', { 'path': '/past-reports' });
		this.route('manage');
	});

    this.route('payrollIntegrations.sync.selectProvider', {path: '/payroll-integrations/sync/select-provider' }, function() {
        this.route('intro');
        this.route('provider');
        this.route('credentials');
        this.route('fileSyncIntro', {path: '/file-sync-intro'});
        this.route('payrollReportsIntro', {path: '/payroll-reports-intro'});
        this.route('questionnaire');
        this.route('complete');
        this.route('instructions');
        this.route('syncType', {path: '/sync-type'});
    });
    this.route('payrollIntegrations.sync.setup', { path: '/payroll-integrations/sync/setup' }, function() {
        this.route('intro', {path: '/intro'});
        this.route('index', {path: '/index'});
        this.route('companyInfo', {path: '/company-info' }, function() {
            this.route('intro', {path: '/intro'});
            this.route('details', {path: '/details'});
            this.route('locations', {path: '/locations'});
        });
        this.route('employeeInfo', {path: '/employee-info' }, function() {
            this.route('intro', {path: '/intro'});
            this.route('downloadSpreadsheet', {path: '/download-spreadsheet'});
            this.route('uploadFile', {path: '/upload-file'});
            this.route('invite', {path: '/invite'});
        });
        this.route('deductionInfo', {path: '/deduction-info' }, function() {
            this.route('mappings', {path: '/mappings'});
        });
        this.route('paySchedules', {path: '/pay-schedules'}, function() {
            this.route('create');
            this.route('complete');
        });
    });

	this.route('payroll.intro', { path: '/payroll/intro' });
	this.route('payroll', { path: '/payroll' }, function() {
		this.route('pendingTasks', { path: '/pending-tasks' }, function() {
			this.route('overview', { path: '/overview' });
			this.route('reviewTaxLiabilities', { path: '/review-tax-liabilities' });
			this.route('employeeOnboarding', { path: '/employee-onboarding' }, function() {
				this.route('current', function() {
					this.route('details', { path: '/:employee_id'}, function() {
						this.route('payTemplate', { path: '/pay-template' });
					});
				});
				this.route('past', function() {
					this.route('details', { path: '/:employee_id'}, function(){
						this.route('index');
					});
				});
				this.route('inactive', function() {
					this.route('details', { path: '/:employee_id'}, function() {
						this.route('index');
					});
				});
			});
			this.route('priorPayroll', { path: '/prior-payroll' }, function() {
				this.route('payrollHistory', { path: '/payroll-history' });
				this.route('downloadSpreadsheet', { path: '/download-spreadsheet' });
				this.route('verifySpreadsheet', { path: '/verify-spreadsheet' });
			});
		});
		this.route('help');
		this.route('manage');
		this.route('fix-records');
		this.route('reconcile');
		this.route('timeline');
		this.route('exemptions', { 'path': '/exemptions/:employee_id' }, function() {
			this.route("section", { "path": "" }, function() {
				this.route("display");
				this.route("date");
				this.route("select");
			});
		});
		this.route('sync', { path: '/sync' }, function() {
			this.route('reports', { path: '/reports' });
			this.route('connect', { path: '/connect' });
		});
		this.route('syncPayroll', { 'path': '/sync-payroll' }, function() {
			this.route('overview');
			this.route('fixRecords', { 'path': '/fix-records' });
			this.route('download');
			this.route('upload');
			this.route('update');
			this.route('newHire', { 'path': '/new-hire' });
			this.route('termination');
			this.route('bank');
			this.route('complete');
		});
		this.route('syncBack', { 'path': '/sync-back' }, function() {
			this.route('overview', {'path': '/'});
			this.route('upload');
			this.route('results');
			this.route('complete');
		});
		this.route('dashboard'); //SMP Dashboard
		this.route('dashboardV2', { 'path': '/dashboard/overview' });
		this.route('pastReports', { 'path': '/past-reports' });

		// Admin facing paystubs
		this.route('paystubs', { 'path': '/paystubs' }, function() {
			this.route('employee', { 'path': '/employee/:employee_id' }, function() {
				this.route('paystub', { 'path': '/paystub/:paystub_id'});
			});
		});

		// Zenefits Payroll Marketing
		this.route('qualify', { path: '/qualify' });
		this.route('features', { path: '/features/:plan' });
		this.route('gettingStarted', { path: '/getting-started' }, function() {
			this.route('excessEmployeesModal', { path: '/excess-employees-modal' });
		});

		// Zenefits Payroll Setup
		this.route('setup', { path: '/setup' }, function() {
			this.route('company', { path: '/company' }, function() {
				this.route('information', { path: '/information' });
				this.route('sorry', { path: '/sorry' });
			});
			this.route('employees', { path: '/employees' }, function() {
				this.route('current', { path: '/current' });
				this.route('ownership', { path: '/ownership' });
				this.route('signatory', { path: '/signatory' });
				// Not active yet
				// this.route('syncDiffs', { path: '/sync-diffs' });
			});
			this.route('bankInformation', { path: '/bank-information' }, function() {
				this.route('verify', { path: '/verify'});
			});
			this.route('upcoming', { path: '/upcoming' }, function() {
				this.route('whatToExpect', { path: '/what-to-expect' });
			});
			this.route('introduction', { path: '/introduction' }, function() {
				this.route('welcome', { path: '/welcome' });
			});
			this.route('startDate', { path: '/start-date' }, function() {
				this.route('desired', { path: '/desired' });
			});
			// Not active yet
			// this.route('employee', { path: '/employee' }, function() {
			// 	this.route('welcome', { path: '/welcome' });
			// 	this.route('details', { path: '/details' });
			// 	this.route('payment', { path: '/payment' });
			// 	this.route('taxes', { path: '/taxes' });
			// });
			// Not active yet
			// this.route('priorPayroll', { path: '/prior-payroll' }, function() {
			// 	this.route('uploadReports', { path: '/upload-reports' });
			// 	this.route('addPayDates', { path: '/add-pay-dates' });
			// 	this.route('terminatedEmployees', { path: '/terminated-employees' });
			// 	this.route('payStubs', { path: '/pay-stubs' });
			// });
		});

		this.route('setupV2', { path: '/setup-v2'}, function() {
			this.route('startDate', { path: '/start-date'});
			this.route('paySchedule', { path: '/pay-schedule'});
			this.route('companyGeneralInfo', { path: '/company-general-info'});
			this.route('companyOwnership', { path: '/company-ownership'});
			this.route('companySignatory', { path: '/company-signatory'});
			this.route('verifyBankAccount', { path: '/verify-bank-account'});
			this.route('nextSteps', { path: '/next-steps'});
		});
		// Zenefits Payroll App
		this.route('overview', { path: '/overview' });
		this.route('calendar', { path: '/calendar/:payScheduleID/:payScheduleGroupName/:payScheduleYear' });

		this.route('priorPayroll', { path: '/prior-payroll' }, function() {
			this.route('payrollHistory', { path: '/payroll-history' });
			this.route('downloadSpreadsheet', { path: '/download-spreadsheet' });
			this.route('verifySpreadsheet', { path: '/verify-spreadsheet' });
		});

		this.route('employees', { path: '/employees' }, function() {
			this.route('current', { path: '/current' }, function() {
				this.route('details', { path: '/:employee_id' }, function() {
					this.route('index', { path: '/' });
					this.route('payTemplate', { path: '/pay-template'});
					this.route('paystubs', { path: '/paystubs'});
					this.route('priorPayroll', { path: '/prior-payroll' });
					this.route('contractorYtd', { path: '/contractor-ytd' });
					this.route('contractorPreviousYear', { path: '/contractor-previous-year' });
				});
				this.route('details.loading');
			});
			this.route('past', { path: '/past' }, function() {
				this.route('details', { path: '/:employee_id' }, function() {
					this.route('paystubs', { path: '/paystubs'});
					this.route('contractorYtd', { path: '/contractor-ytd' });
					this.route('contractorPreviousYear', { path: '/contractor-previous-year' });
				});
			});
			this.route('inactive', { path: '/inactive' }, function() {
				this.route('details', { path: '/:employee_id' }, function() {
					this.route('paystubs', { path: '/paystubs'});
					this.route('contractorYtd', { path: '/contractor-ytd' });
					this.route('contractorPreviousYear', { path: '/contractor-previous-year' });
				});
			});
		});
		this.route('payRuns', { path: '/pay-runs' }, function() {
			this.route('createPayRun', { path: '/create-pay-run' }, function() {
				this.route('type', { path: '/type' });
				this.route('details', { path: '/details' });
				this.route('edit', { path: '/edit' });
			});
			this.route('payRun', { path: '/:z_payroll_run_id' }, function() {
				this.route('edit', function() {
					this.route('simple', { path: '/simple' }, function() {
						this.route('details', { path: '/:employee_id' });
					});
				});
				this.route('runPayRun', { path: '/run-pay-run' }, function() {
					this.route('edit', { path: '/edit' });
					this.route('review', function() {
						this.route('confirm', function() {
							this.route('details', { path: 'details/:employee_id' });
						});
						this.route('submit');
						this.route('summary', function() {
							this.route('details', { path: 'details/:employee_id' });
						});
					});
				});
				this.route('review', function() {
					this.route('confirm', function() {
						this.route('details', { path: 'details/:employee_id' });
					});
					this.route('submit');
					this.route('summary', function() {
						this.route('details', { path: 'details/:employee_id' });
					});
				});
				this.route('bulkEdit', { path: '/bulk-edit'}, function() {
					this.route('chooseData', { path: '/choose-data'});
					this.route('upload', { path: '/upload'});
				});
			});
			this.route('payRunTax', { path: '/pay-run-tax/:z_payroll_run_tax_id' });
			this.route('corrections', { path: '/corrections' });
		});

		this.route('reportsAndForms', { path: '/reports-and-forms' }, function() {
			this.route('reports', { path: '/reports' });
			this.route('forms', { path: '/forms' });
		});

		this.route('reportsAndFormsBeta', { path: '/reports-and-forms-beta' }, function() {
			this.route('reports', { path: '/reports' });
		});

		this.route('reportsBeta', { path: '/reports-beta' }, function() {
			this.route("all", { path: "/*wildcard" }, function () {
				this.route("all", { path: "/*wildcard" }, function () {
					this.route("all", { path: "/*wildcard" });
				});
			});
		});

		this.route('taxes', { path: '/taxes' }, function() {
			this.route("all", { path: "/*wildcard" });
		});

		this.route('settings', { path: '/settings' }, function() {
			this.route('bankInformation', { path: '/bank-information'});
			this.route('organization', { path: '/organization'});
			this.route('payTypes', { path: '/pay-types'});
			this.route('payStatements', { path: '/pay-statements'});
			this.route('taxes', { path: '/taxes'});
			this.route('implementation', { path: '/implementation'});
			this.route('ssn-unmask', { path: '/ssn-unmask'});
			this.route('paySchedules', { path: '/pay-schedules'});
			this.route('onboardingTaxes', { path: '/onboarding-taxes'});
			this.route('generalLedger', {path: '/general-ledger'}, function() {
				this.route('all',{path: '/*wildcard'});
			});
			this.route('401k', {path: '/401k'}, function() {
				this.route('all',{path: '/*wildcard'});
			});
		});

		this.route('contractor-1099-form-filing', { path: '/contractor-1099-form-filing/:year'});
	});

	// Payroll provider selection
	this.route('payroll.sync.selectProvider', {path: '/payroll/sync/select-provider' }, function() {
		this.route('intro');
		this.route('provider');
		this.route('credentials');
		this.route('fileSyncIntro', {path: '/file-sync-intro'});
		this.route('payrollReportsIntro', {path: '/payroll-reports-intro'});
		this.route('complete');
		this.route('instructions');
		this.route('syncType', {path: '/sync-type'});
	});

	// Payroll Sync Setup Wizard
	this.route('payroll.sync.setup', { path: '/payroll/sync/setup' }, function() {
		this.route('intro', {path: '/intro'});
		this.route('index', {path: '/index'});
		this.route('companyInfo', {path: '/company-info' }, function() {
			this.route('intro', {path: '/intro'});
			this.route('details', {path: '/details'});
			this.route('locations', {path: '/locations'});
		});
		this.route('employeeInfo', {path: '/employee-info' }, function() {
			this.route('intro', {path: '/intro'});
			this.route('downloadSpreadsheet', {path: '/download-spreadsheet'});
			this.route('uploadFile', {path: '/upload-file'});
			this.route('invite', {path: '/invite'});
		});
		this.route('deductionInfo', {path: '/deduction-info' }, function() {
			this.route('mappings', {path: '/mappings'});
		});
		this.route('paySchedules', {path: '/pay-schedules'}, function() {
			this.route('create');
			this.route('complete');
		});
	});

	this.route('catchupOnDemand', {path: '/catchup-on-demand'}, function() {
		this.route('reimbursements', {path : '/reimbursements' });
	});
	// Payroll End ===============================================================

	// Aca Compliance Start ======================================================
	// Aca Compliance Overview Dashboard
	this.resource('acacompliance', {path : '/acacompliance' }, function () {
		this.route('overview', {path : '/overview' }, function(){
			this.route('employeedetails', {path : '/employeedetails' }, function (){
				this.route('details', {path : '/:acaemployee_id' }, function(){
					this.route('employee', {path : '/employee' });
					this.route('qualification', {path : '/qualification' });
					this.route('initialMeasurementPeriod', {path : '/initial-measurement-period' });
				});
				this.route('details.loading');
			});
		});
		this.route('filing', {path: '/filing' });
		this.route('settings', {path: '/settings' }, function() {
			this.route('aleStatus', {path: '/ale-status'});
			this.route('medicalPlans', {path: '/medical-plans'});
			this.route('medicalPlanAffordability', {path: '/medical-plan-affordability'});
		});
	});
	// Aca Compliance Setup Wizard
	this.route('acacompliancesetup.intro', { path: '/acacompliancesetup/intro' });
	this.resource('acacompliancesetup', {path: '/acacompliancesetup' }, function() {
		this.route('index', {path: '/index'});
		this.route('syncPlan', {path: '/sync-plan' }, function() {
			this.route('currentEmployerStatus', {path: '/current-employer-status'});
			this.route('previousEmployerStatus', {path: '/previous-employer-status'});
			this.route('terminatedEmployees', {path: '/terminated-employees'});
			this.route('medicalPlans', {path: '/medical-plans'});
			this.route('previousMedicalPlans', {path: '/previous-medical-plans'});
			this.route('affordability', {path: '/affordability'});
		});
		this.route('periodDetails', {path: '/period-details' }, function() {
			this.route('payPeriodFrequency', {path: '/pay-period-frequency'});
			this.route('payPeriodStartDate', {path: '/pay-period-start-date'});
			this.route('secondPayPeriodFrequency', {path: '/second-pay-period-frequency'});
			this.route('secondPayPeriodStartDate', {path: '/second-pay-period-start-date'});
			this.route('affordabilityChoice', {path: '/affordability-choice'});
			this.route('measurementPeriodIntro', {path: '/measurement-period-intro'});
			this.route('standardMeasurementPeriod', {path: '/standard-measurement-period'});
			this.route('standardMeasurementPeriodReview', {path: '/standard-measurement-period-review'});
			this.route('initialMeasurementPeriod', {path: '/initial-measurement-period'});
		});
		this.route('employeeDetails', {path: '/employee-details' }, function() {
			this.route('employeeInformation', {path: '/employee-information'});
			this.route('w2', {path: '/w2'});
			this.route('shortcutMedicalPlan', {path: '/shortcut-medical-plan'});
			this.route('previousShortcutMedicalPlan', {path: '/previous-shortcut-medical-plan'});
			this.route('employeeHours', {path: '/employee-hours'});
			this.route('offer', {path: '/offer'});
		});
	});
	// Aca Compliance Forms Wizard
	this.resource('aca-compliance-forms', {path: '/aca-compliance-forms' }, function() {
		this.route('generate', {path: '/generate' }, function() {
			this.route('companyInfo', {path: '/company-info' }, function() {
				this.route('companyDetails', {path: '/company-details'});
				this.route('aggregatedStatus', {path: '/aggregated-status'});
				this.route('aggregatedGroup', {path: '/aggregated-group'});
				this.route('aggregatedTime', {path: '/aggregated-time'});
				this.route('planDetails', {path: '/plan-details'});
				this.route('complete', {path: '/complete'});
			});
		});
		this.route('notify', {path: '/notify' }, function() {
			this.route('notifyForms', {path: '/notify-forms' }, function() {
				this.route('signature', {path: '/signature'});
				this.route('email', {path: '/email'});
				this.route('terminatedDocs', {path: '/terminated-docs'});
				this.route('efile', {path: '/efile'});
				this.route('complete', {path: '/complete'});
			});
		});
	});
	// Aca Compliance End ===============================================================

	this.route("onboarding_intro", { path: "/onboarding_intro" });
	this.route("onboardingstart", { path: "/onboardingstart" });
	this.route("security-questions", { path: "/security-questions" });
	this.resource("change-letter", function (){
		this.route("create", {"path": '/create'});
		this.route("edit",  { "path" : "/edit/:document_id" });
		this.route('duplicate', { "path" : "/duplicate/:document_id" });
	});
	this.route("tos-privacy-policy", { path: "/tos-privacy-policy" });
	this.resource("provision", { path : "/provision" }, function() {
		this.route("/");
		this.resource("provision.manage", { path : "/manage" }, function() {
			this.route("/");
			this.route("groups");
			this.route("settings");
			this.route("memberships");
			this.route("all");
		});
		this.resource("provision.marketing", { path : "/marketing" }, function() {
			this.route("initial");
		});
	});
	this.resource("backgroundsetup", function() {
		this.route("about");
		this.route("companyinfo");
		this.route("pricing");
		this.route("review");
		this.route("agreement");
	});
	this.resource("onboarding", function() {
		this.route("settings", function() {
			this.route("documents", { path : "/documents"});
			this.route("nonexempt", { path : "/nonexempt"});
			this.route("backgroundCheck", { path : "/background-check"});
			this.route("backgroundCheck.signatory", { path : "/background-check/signatory"});
			this.route("optionalOnboardingQuestions", {"path": "/optional-onboarding-questions"});
			this.route("companySignature", {"path": "/company-signature"});
			this.route("benefitPreview", {"path": "/benefit-preview"});
			this.route('variableCompensation', {path: '/variable-compensation'}, function() {
				this.route('all',{path: '/*wildcard'});
			});

			this.route("customEmail", { path : '/custom-email'}, function() {
				this.route('create');
				this.route('list');
				this.route('duplicate', { "path" : "/duplicate/:id" });
				this.route('edit', { "path" : "/edit/:id" });
				this.route('preview', { "path" : "/:id" });
			});
		});
		this.route("overview", { "path" : "/overview" });
		this.route("customfield");
		this.route("bulkemployeerequest");
		this.route("employeerequest", { "path" : "/employeerequest/:all_employee_id" });
		this.route("addofferletter");
		this.route("addcustomfield", { "path" : "/:custom_field_id/addcustomfield" });
		this.route("addcustomfieldsection", { "path" : "/:custom_field_section_id/addcustomfieldsection" });
		this.route("addipassignment");
		this.route('offerLetter', { path: '/offer-letter' }, function() {
			this.route('edit', { "path" : "/edit/:offer_letter_id" });
			this.route('duplicate', { "path" : "/duplicate/:offer_letter_id" });
		});
		this.route("addemployeehandbook");
		this.route('employeeHandbook', { path: '/employee-handbook' }, function() {
			this.route('edit', { "path" : "/edit/:employee_handbook_id" });
			this.route('duplicate', { "path" : "/duplicate/:employee_handbook_id" });
		});
		this.route("companysignature");
		this.route('ipAssignment', { path: '/ip-assignment' }, function() {
			this.route('edit', {"path": '/edit/:ip_assignment_id'});
			this.route('duplicate', { "path" : "/duplicate/:ip_assignment_id" });
			this.route('create');
		});
		this.route('calendarfeedsetup');
		this.route('calendarfeedaddress', {path : "/calendarfeedaddress/:calendar_id"});
	});

	this.route("hiring.v2", { path: "/hiring/v2/:new_hire_id" }, function() {
		this.route("basicinfo", { "path" : "/basicinfo" });
		this.route("employmenttype", { "path" : "/employmenttype" });
		this.route("jobdetails", { "path" : "/jobdetails" });
		this.route("employment", { "path" : "/employment" });
		this.route("workgroups", { "path" : "/workgroups" });
		this.route("employeeclass", { "path" : "/employeeclass" });
		this.route("benefitsimpact", { "path" : "/benefitsimpact" });
		this.route("customfields", { "path" : "/customfields" });
		this.route("benefitsadministration", { "path" : "/benefitsadministration" });
		this.route("benefitsinfo", { "path" : "/benefitsinfo" });
		this.route("rehirepolicy", { "path" : "/rehirepolicy" });
		this.route("integrations", { "path" : "/integrations" });
		this.route("additionalinfo", { "path" : "/additionalinfo" });
		this.route("preview", { "path" : "/preview" });
		this.route("notification", { "path" : "/notification" });
		this.route("complete", { "path" : "/complete" });
		this.route("payrollsettings", { "path" : "/payrollsettings"});
	});

	this.route("hiring.hireoradd", { path: "/hiring/hireoradd" }, function() {
		this.route("basicinfo", { "path" : "/basicinfo" });
	});

	this.route("zflow", { "path" : "/flow/:z_flow_stage_id" }, function(){
		this.route("flowModule", {"path": "/:z_flow_module_id"});
		this.route('index', { path: '/' });
	});

	this.route("earlyEmailWizard", { "path": "get-started"}, function() {
		this.route("section", { "path": ""}, function() {
			this.route("welcome");
			this.route("name");
			this.route("photo");
			this.route("address");
			this.route("phone");
			this.route("dob");
			this.route("gender");
			this.route("ssn");
			this.route("dependents");
			this.route("details");
		});
	});
	// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename or remove, please change in zapp.py as well
	this.route("initialEnrollment", {"path": "initial-enrollment"}, function() {
		this.route("intro", {"path": "intro"}, function() {
			this.route("welcome", {"path": "welcome"});
			this.route("whatsNext", {"path": "whats-next"});
		});
		this.route("miniFlow", {"path": "mini-flow"}, function() {
			this.route("effectiveDate", {"path": "effective-date"});
			this.route("companyOwner", {"path": "company-owner"});
		});
		this.route("miniFlowReview", {"path": "mini-flow-review"});
		this.route("planSelector", {"path": "plan-selector"}, function() {
			this.route("selectPlanMedical", {"path": "select-plan-medical"});
			this.route("selectPlanDental", {"path": "select-plan-dental"});
			this.route("selectPlanVision", {"path": "select-plan-vision"});
			this.route("companyIndustry", {"path": "company-industry"});
		});
		this.route("planSelectorReview", {"path": "plan-selector-review"});
	});
	this.route("quoteDependentsV2", { path : "/quote-dependents" });
	this.route("departments");
	this.route("departments_onetimesetup");
	// only keep the reports routes in case there are old links to them that Andrew missed
	// redirect the links to bi/__ and remove these routes by the end of September, 2015
	// also remove App.ReportsRoute which automatically redirects
	this.resource("reports", function() {
		this.route("list");
		this.route("custom");
		this.route("headcount");
		this.route("compensation");
		this.route("turnover");
	});
	this.resource("bi", function() {
		// Old v1 routes
		this.route("list");
		this.route("headcount");
		this.route("diversity");
		this.route("turnover");
		this.route("compensation");
		this.route("premade");
		this.route("premadescheduler");
		this.route("custom", function() {
			this.route("create");
			this.route("edit", {path: "/edit/:custom_report_id"});
		});

		// BI v2.0 routes
		this.route("overview");
		this.route("premadeReports", { path: "premade-reports" }); // Premade reports
		this.route("customReports", { path: "custom-reports" }); //List of Created Custom Reports
		this.route("createReport", { path: "create-report/:customReport_id" }, function() { //Custom report creation flow
			this.route("general");
			this.route("fields");
			this.route("rearrangement");
			this.route("filters");
			this.route("schedule");
			this.route("review");
		});
		this.route("peopleAnalytics", { path: "people-analytics" });
	});
	this.resource("eeo", function() {
		this.route("starting");
		this.route("requirements");
		this.route("setup", {"path": "/setup/:company_location_id"});
		this.route("summary");
	});
	this.route("contractors_intro");
	this.resource("contractor", function() {
		this.route("signagreement", { path : "signagreement/:instantiated_consultant_contract_id" });
		this.route("signipagreement", { path : "signipagreement/:instantiated_ip_agreement_id" });
		this.route("contracts");
		this.route("payments");
		this.route("taxdocs");
		this.route("onboarding", function() {
			this.route("basicinfo");
			this.route("bank");
			this.route("exemptions");
			this.route("w9");
		});
	});
	this.resource("contractors", function() {
		this.route("new");
		this.route("pay");
		this.route("payments");
		this.route("list");
		this.route("contract", { "path": "contract/:contractor_id"} );
		this.route("file1099");
		this.route("agreements");
		this.route("templates");
		this.route("bankinfo", function() {
			this.route("summary");
			this.route("verify", function() {
				this.route("stepone");
				this.route("stepn");
				this.route("manual");
				this.route("test", { "path" : "/test/:account_id" });
			});
		});
		this.route("1099-form-filing", { "path": "1099-form-filing/:year" });
	});
	// HR1 change for url backward compatibility. Can clean up some point after HR1
	this.route("hrAdvisorLegacy", {"path": "/hr-advisor"}, function() {
		this.route("intro");
        this.route("block");
        this.route("intermediate");
        this.route("error");
        this.route("done");
        this.route("enroll", function() {
            this.route("planDetails", {"path": "plan-details"});
            this.route("bankDetails", {"path": "bank-details"});
            this.route("agreement");
        });
        this.route("product" , {"path": ""}, function() {
        	this.route("overview");
        });
	});
	this.route("hrAdvisor", {"path": "/hr-library"}, function() {
        this.route("intro");
        this.route("block");
        this.route("intermediate");
        this.route("error");
        this.route("done");
        this.route("enroll", function() {
            this.route("planDetails", {"path": "plan-details"});
            this.route("bankDetails", {"path": "bank-details"});
            this.route("agreement");
        });
        this.route("product" , {"path": ""}, function() {
        	this.route("overview");
        });
    });
	this.resource("verification", function() {
		this.route("verifycompany");
	});

	// Offer letter setup

	this.resource("onboardingSetup.offer", {"path" : "/offer-letter" }, function() {
		this.route("offerSetup", {"path" : "/setup" }, function() {
			this.route("intro", {"path" : "/intro"});
			this.route("template", {"path" : "/template"});
			this.route("authorize", {"path" : "/authorize"});
			this.route("complete", {"path" : "/complete"});
		});
	});

	// Ip Assignment setup

	this.resource("onboardingSetup.agreement", {"path" : "/ip-assignment" }, function() {
		this.route("agreementSetup", {"path" : "/setup" }, function() {
			this.route("intro", {"path" : "/intro"});
			this.route("template", {"path" : "/template"});
			this.route("attachments", {"path" : "/attachments"});
			this.route("authorize", {"path" : "/authorize"});
			this.route("complete", {"path" : "/complete"});
		});
	});

	// Non-exempt notice setup

	this.resource("onboardingSetup.nonexempt", {"path" : "/nonexempt-notice" }, function() {
		this.route("nonexemptSetup", {"path" : "/setup" }, function() {
			this.route("intro", {"path" : "/intro"});
			this.route("companyInfo", {"path" : "/company-info"});
			this.route("companyContactInfo", {"path" : "/company-contact-info"});
			this.route("hasWorkersCompensation", {"path" : "/has-workers-compensation"});
			this.route("workersCompensation", {"path" : "/workers-compensation"});
			this.route("staffingBusiness", {"path" : "/staffing-business"});
			this.route("offerLetterDetails", {"path" : "/offer-letter-details"});
			this.route("payrollInfo", {"path" : "/payroll-info"});
			this.route("hiringInCA", {"path" : "/hiring-in-ca"});
			this.route("sickLeavePolicy", {"path" : "/sick-leave-policy"});
			this.route("exemptionReason", {"path" : "/exemption-reason"});
			this.route("authorize", {"path" : "/authorize"});
			this.route("noInsurance", {"path": "/no-insurance"});
			this.route("confirm", {"path": "/confirm"});
		});
	});

	// Background Check Setup

	this.resource("onboardingSetup.bgc", {"path" : "/background-check" }, function() {
		this.route("bgcSetup", {"path" : "/setup" }, function() {
			this.route("intro", {"path" : "/intro"});
			//settings
			this.route("type", {"path" : "/type"});
			this.route("requireCheck", {"path" : "/require-check"});
			this.route("viewResults", {"path" : "/view-results"});
			this.route("allowMvr", {"path" : "/allow-mvr"});
			this.route("requireMvr", {"path" : "/require-mvr"});
			this.route("cannotCompleteMvr", {"path": "/cannot-complete-mvr"});
			this.route("complete", {"path" : "/complete"});
			//setup
			this.route("legalInfo", {"path" : "/legal-info"});
			this.route("billingInfo", {"path" : "/billing-info"});
			this.route("authorize", {"path" : "/authorize"});
		});
	});

	// Welcome Letter Setup

	this.resource("onboardingSetup.welcome", {"path" : "/welcome-email" }, function() {
		this.route("welcomeSetup", {"path" : "/setup" }, function() {
			this.route("intro", {"path" : "/intro"});
			this.route("template", {"path" : "/template"});
			this.route("attachments", {"path" : "/attachments"});
			this.route("complete", {"path" : "/complete"});
		});
	});

	// Employee Hanbook setup

	this.resource("onboardingSetup.handbook", {"path" : "/employee-handbook" }, function() {
		this.route("handbookSetup", {"path" : "/setup" }, function() {
			this.route("intro", {"path" : "/intro"});
			this.route("template", {"path" : "/template"});
			this.route("attachments", {"path" : "/attachments"});
			this.route("complete", {"path" : "/complete"});
		});
	});

	// Mega flow: Onboarding setup

	this.resource("onboardingSetup.megaflow", {"path" : "/onboarding-setup" }, function() {
		this.route("introSetup", {"path" : "/introduction" }, function() {
			this.route("intro", {"path" : "/intro"});
		});
		this.route("offerSetup", {"path" : "/offer-letter" }, function() {
			this.route("intro", {"path" : "/intro"});
			this.route("template", {"path" : "/template"});
			this.route("authorize", {"path" : "/authorize"});
			this.route("complete", {"path" : "/complete"});
		});
		this.route("handbookSetup", {"path" : "/employee-handbook" }, function() {
			this.route("intro", {"path" : "/intro"});
			this.route("template", {"path" : "/template"});
			this.route("attachments", {"path" : "/attachments"});
			this.route("complete", {"path" : "/complete"});
		});
		this.route("agreementSetup", {"path" : "/ip-assignment" }, function() {
			this.route("intro", {"path" : "/intro"});
			this.route("template", {"path" : "/template"});
			this.route("attachments", {"path" : "/attachments"});
			this.route("authorize", {"path" : "/authorize"});
			this.route("complete", {"path" : "/complete"});
		});
		this.route("welcomeSetup", {"path" : "/welcome-email" }, function() {
			this.route("intro", {"path" : "/intro"});
			this.route("template", {"path" : "/template"});
			this.route("attachments", {"path" : "/attachments"});
			this.route("complete", {"path" : "/complete"});
		});
		this.route("bgcSetup", {"path" : "/background-check" }, function() {
			this.route("intro", {"path" : "/intro"});
			this.route("type", {"path" : "/type"});
			this.route("requireCheck", {"path" : "/require-check"});
			this.route("viewResults", {"path" : "/view-results"});
			this.route("allowMvr", {"path" : "/allow-mvr"});
			this.route("requireMvr", {"path" : "/require-mvr"});
			this.route("cannotCompleteMvr", {"path" : "/cannot-complete-mvr"});
			this.route("complete", {"path" : "/complete"});
			this.route("legalInfo", {"path" : "/legal-info"});
			this.route("billingInfo", {"path" : "/billing-info"});
			this.route("authorize", {"path" : "/authorize"});
		});
		this.route("nonexemptSetup", {"path" : "/nonexempt-notice" }, function() {
			this.route("intro", {"path" : "/intro"});
			this.route("companyInfo", {"path" : "/company-info"});
			this.route("companyContactInfo", {"path" : "/company-contact-info"});
			this.route("hasWorkersCompensation", {"path" : "/has-workers-compensation"});
			this.route("workersCompensation", {"path" : "/workers-compensation"});
			this.route("staffingBusiness", {"path" : "/staffing-business"});
			this.route("offerLetterDetails", {"path" : "/offer-letter-details"});
			this.route("payrollInfo", {"path" : "/payroll-info"});
			this.route("hiringInCA", {"path" : "/hiring-in-ca"});
			this.route("sickLeavePolicy", {"path" : "/sick-leave-policy"});
			this.route("exemptionReason", {"path" : "/exemption-reason"});
			this.route("authorize", {"path" : "/authorize"});
			this.route("noInsurance", {"path": "/no-insurance"});
			this.route("confirm", {"path": "/confirm"});
		});
		this.route("questionsSetup", {"path" : "/optional-questions" }, function() {
			this.route("questions", {"path" : "/question"});
		});
		this.route("completeSetup", {"path" : "/complete-onboarding" }, function() {
			this.route("complete", {"path" : "/complete"});
		});
	});

	// Time & Attendance

	this.resource("timeattendancewizard", {"path" : "/time-setup" }, function() {
		this.route("payrollReview", {"path" : "/payroll" }, function() {
			this.route("intro", {"path" : "/intro"});
			this.route("action", {"path" : "/action"});
			this.route("arrears", {"path" : "/arrears"});
			this.route("payDates", {"path" : "/pay-dates"});
			this.route('activePypCompatiblePaySchedule', {path: '/active-pyp-compatible-hcps'});
			this.route('activePypNoHourlyPaySchedule', {path: '/active-pyp-no-hcps'});
			this.route('pendingPypNoHourlyPaySchedule', {path: '/pending-pyp-no-hcps'});
			this.route('pendingPypCompatiblePaySchedule', {path: '/pending-pyp-compatible-hcps'});
			this.route('incompatiblePaySchedule', {path: '/incompatible-pay-schedule'});
			this.route("payweekOffset", {"path" : "/payweek-offset"});
			this.route("decideSync", {"path" : "/sync"});
			this.route("summary", {"path" : "/summary"});
			this.route("review", {"path" : "/review"});
		});
		this.route("errorPage", {"path" : "/payroll/error"});
		this.route("timeReporting", {"path" : "/reporting" }, function() {
			this.route("intro", {"path" : "/intro" });
			this.route("chooseMethod", {"path" : "/method" });
			this.route("chooseInterface", {"path" : "/clock-in" });
			this.route("chooseReportInterface", {"path" : "/self-report" });
			this.route("review", {"path" : "/review" });
		});
		this.route("approvalTimeline", {"path" : "/approval-timeline" }, function() {
			this.route("overview", {"path" : "/overview" });
			this.route("information", {"path" : "/information" });
			this.route("chooseDeadline", {"path" : "/deadline" });
		});
		this.route("overtime", {"path" : "/overtime" }, function() {
			this.route("overview", {"path" : "/overview" });
			this.route("rules", {"path" : "/rules" });
		});
		this.route("paidHolidays", {"path" : "/paid-holidays" }, function() {
			this.route("overview", {"path" : "/overview" });
			this.route("offer", {"path" : "/offer" });
			this.route("lunch", {"path" : "/lunch" });
		});
		this.route("billing", {"path" : "/billing" }, function() {
			this.route("intro", {"path" : "/intro" });
			this.route("contractType", {"path" : "/contract-type" });
			this.route("details", {"path" : "/details" });
			this.route("signature", {"path" : "/signature" });
		});
		this.route("complete", {"path" : "/complete" }, function() {
			this.route("finish", {"path" : "/finish" });
		});
	});
	this.resource("timeattendance", {"path" : "/time"}, function() {
		this.route("approver-overview");
		this.route("approveemployee", {"path" : "/approve/:ta_pay_period_employee_id"});
		this.route("approveemployees", {"path" : "/payperiod/:ta_pay_period_id"});
		this.route("approvehours");
		this.route("changeclockinstatus", {"path" : "/modify/:ta_time_approved_day_id"});
		this.route("employeesettings");
		this.route("manage-employees", {path: "manage-employees"}, function() {
		  this.route("details", {path: "details/:ta_employee_id"});
		  this.route("multi-edit", {path: "multi-edit"});
		  this.route("settings", {path: "settings/:ta_employee_id"});
		  this.route("bulk-settings", {path: "bulk-settings"});
		});

		this.route("modifysettings", {"path" : "/modify/:employee_id"});
		this.route("modifytime", {"path" : "/modify/:ta_time_approved_day_id"});
		this.route("modifytimeday", {"path" : "/modify/:ta_time_approved_day_id"});
		this.route("modifytimeperiod", {"path" : "/modify/:ta_pay_period_employee_id"});
		this.route("overview");
		this.route("pastpayperiods");
		this.route('payperiod-current', { path: '/pay-period' });
  		this.route('payperiod', { path: '/pay-period/:pay_period_id' });
		this.route("reports");
		this.route("newReports", { path: '/new-reports'});
		this.route("settings" , {"path" : "/settings"}, function() {
			this.route("timeReporting", {"path" : "/time_reporting"})
		});
		this.route("syncstatus");
		this.route("timekeeper");
		this.route("adminsettings");
		this.route("adminsettingspc");
		this.route("helpcenter");
		this.route("timesheet", {"path" : "/timesheet/:ta_pay_period_employee_id"});
		this.route("today");
	});
	this.route("timeattendance.intro", { path: "/time/intro" });
	this.resource("timeattendanceemployee", {"path" : "/timereport"}, function() {
		this.route("employee-overview");
		this.route("now");
		this.route("pastpayperiod");
		this.route("payperiod");
		this.route("reports");
		this.route("newReports", { path: '/new-reports'});
		this.route("submittime", {"path" : "/submittime/:ta_pay_period_employee_id"});
		this.route('timesheet-current', { path: '/timesheet' });
		this.route('timesheet', { path: '/timesheet/:ta_pay_period_employee_id' });
		this.route("topnavredirect", {"path" : "/redirect"});
	});


	this.route("paystub", {path: "/paystub/:paystub_current_id"});
	this.route('apiuser');

	// PTO =========================================================

	this.route("pto.intro", { path: '/pto/intro' });
	this.route('newPto', { path: '/new-pto' });
	this.route('ptosettings');
	this.resource("ptosetup", {path: "/ptosetup/:employer_pto_id"}, function() {
		this.route('accounts');
		this.route("selectemployees");
		this.route("import");
		this.route("importexcel");
		this.route("advanced");
		this.route("vacationtypes");
		this.route("holidays");
		this.route("emails");
	});

	this.resource("ptosetupaccount", {path: "/ptosetupaccount/:employer_pto_account_id"}, function() {
		this.route("basicinfo");
		this.route("secondary");
	});


	this.resource("pto", function() {
		this.route("overview");
		this.route("landing");
		this.route("balances", function (){
			this.route("accounts", { path: "/:account_type" }, function(){
				this.route('details', { path : '/:all_employee_id' }, function() {
					this.route('index', { path: '/' });
					this.route('history', { path: '/history' });
					this.route('requests', { path: '/requests' });
				});
			});
		});
		this.route('accounts', {"path": "accounts/:account_type"});
		this.route("timeline");
		this.route("history", function() {
			this.route('details', { path: 'details/:vacation_request_id' });
		});
		this.route("cancel");
		this.route('calendar');
		this.route("import");
		this.route("reports");

		this.route('settings', function() {
			this.route('policySettings', { path: '/:id' })
			this.route('setup', { path: '/:id/setup' });
			this.route('holidaySettings', { path: '/:id/holidays' });
			this.route('workScheduleSettings', { path: '/:id/hours-and-workweek' });
			this.route('typeSettings', { path: '/:id/types-and-reasons' });
			this.route('ruleSettings', { path: '/:id/rule-settings' });
			this.route('policyOptions', { path: '/:id/policy-options' });
			this.route('loa-policyOptions', { path: '/:id/loa-policy-options' });


		});
		this.route('loaPolicy', { path: '/loa-policy/:id' }, function() {
			this.route('start');
			this.route('policy-reasons');
			this.route('pto-option');
			this.route('pay');
			this.route('benefits');
			this.route('required-documents');
			this.route('summary');
			this.route('policy-options');
			this.route('loa-setup');
			this.route('loa-policy-options');

		});

		this.route('loaRequest', { path: '/loa-request/:id' }, function() {
			this.route('leave-details-dates');
			this.route('leave-details-policy');
			this.route('documentation');
			this.route('additional-notes');
			this.route('summary');
		});

		// deprecated PTO redirects
		this.route("policies");

	});
	this.route('ptoSetup', { path: '/pto-setup/:id' }, function() {
		this.route('intro', function() {
			this.route('welcome');
		});
		this.route('holidays', function() {
			this.route('calendar');
		});
		this.route('hoursAndWorkweek', { path: 'hours-and-workweek' }, function() {
			this.route('standardHours', { path: 'standard-hours' });
			this.route('standardWorkweek', { path: 'standard-workweek' });
		});
		this.route('policyDetails', { path: 'time-off-rules'}, function() {
			this.route('intro');
			this.route('policyIntro', { path: 'introduction/:account_id' });
			this.route('selectPolicy', { path: 'select-rules/:account_id' });
			this.route('setupPolicy', { path: 'setup-rules/:account_id' });
			this.route('reviewPolicies', { path: 'review-rules' });
		});
		this.route('policiesAndReasons', { path: 'types-and-reasons' }, function() {
			this.route('selectAndAdd', { path: 'select-types' });
			this.route('selectReasons', { path: 'select-reasons' });
		});
		this.route('import', function() {
			this.route('selectEmployees', { path: 'select-employees' });
			this.route('switchEmployees', { path: 'switch-employees' });
			this.route('importBalances', { path: 'import-balances' });
			this.route('effectiveDate', { path: 'effective-date' });
		});
		this.route('notifyEmployees', { path: 'notify-employees' }, function() {
			this.route('intro', { path: 'introduction' });
			this.route('sendEmails', { path: 'send-emails' });
			this.route('confirmation', { path: 'confirmation' });
		});
	});

	// Redirect all of these back to the settings page
	this.resource("ptochange", { path: '/ptochange/:employer_pto_id' }, function() {
		this.route('index', { path: '/'});
		this.route('index', { path: '/accounts' });
		this.route('index', { path: '/advanced' });
		this.route('index', { path: '/vacationtypes' });
		this.route('index', { path: '/holidays' });
		this.route('index', { path: '/holidayyear' });
	});

	this.resource("ptochangeaccount", {path: "/ptochangeaccount/:employer_pto_account_id"}, function() {
		this.route('index', { path: '/basicinfo'});
		this.route('index', { path: 'secondary'});
	});

	this.resource("employeePto", { path : "/employee/pto" }, function() {
		this.route('overview', { path: '/overview' });
		this.route("landing");
		this.route("history", function() {
			this.route('account', { path: '/:type' }, function() {
				this.route('details', { path: 'details/:vacation_request_id' });
			});
		});
		this.route("archive");
		this.route("policy", function() {
			this.route('account', { path: '/account/:type' });
		});
		this.route("calendaraddress");
		this.route("reports");
		this.route('requests', { path: '/team-requests' }, function() {
			this.route('details', { path: '/details/:vacation_request_id' });
		});

		this.route('balances', { path: '/team/:account_type' }, function() {
			this.route('details', { path : '/:all_employee_id' }, function() {
				this.route('index', { path: '/' });
				this.route('history', { path: '/history' });
				this.route('requests', { path: '/requests' });
			});
		})
	});

	// End PTO =========================================================

	this.route("hsaIntro");
	this.resource("hsa", function() {
		this.route("company");
		this.route("bank");
		this.route("info");
		this.route("contribution");
		this.route("policy");
		this.route("billing");
		this.route("nextSteps");
	});
	this.route("hsasetupfinish");
	this.route("hsasetupemails");
	this.route("hsaemailspreview");
	this.route("hsanextsteps");

	this.route("hsa.emails");
	this.route("hsa.emailspreview");
	this.resource("hsaSettings", function() {
		this.route("eligibleemployees");
		this.route("participatingemployeesside");
		this.route("overview");
		this.route("contributionchanges");
		this.route("contributionchangesside");
		this.route("change");
		this.route("changeconfirm");
		this.route("cancel");
		this.route("documents");
		this.route("editeligibility");
	});

	this.resource("fsasettings", function() {
		this.route("overview");
		this.route("eligibleemployees");
		this.route("participatingemployeesside");
		this.route("change");
		this.route("cancel");
		this.route("documents");
		this.route("invoices");
	});

	this.route("fsaAdminSetup", { path: "/fsa-admin-setup" }, function() {
		this.route("planDetails", { path: "/plan-details" }, function() {
			this.route("planYear", { path: "/plan-year" });
			this.route("payrollInfo", { path: "/payroll-info" });
			this.route("contributions");
			this.route("funding");
			this.route("summary");
		});
		this.route("finishSetup", { path: "/finish-setup" }, function() {
			this.route("billingAndAgreement", { path: "/billing-and-agreement" });
			this.route("checkout");
		});
	});
	this.route("zfbEnrollAgreement", { path: "/zfb-enroll-agreement" });
	this.route("fsaAdminFinishSplash", { path: "/fsa-admin-finish-splash" });

	this.route("fsaSetup", { path: "/fsa-setup" }, function() {
		this.route("introduction", function() {
			this.route("about");
		});
		this.route("employeeDetails", { path: "/employee-details" }, function() {
			this.route("basicDetails", { path: "/basic-details" });
			this.route("contactDetails", { path: "/contact-details" });
			this.route("bankDetails", { path: "/bank-details" });
		});
		this.route("planDetails", { path: "/plan-details" }, function() {
			this.route("intro");
			this.route("healthContribution", { path: "/health-contribution" });
			this.route("lpfsaContribution", {path: "/lpfsa-contribution"});
			this.route("careContribution", { path: "/care-contribution" });
		});
		this.route("finish", function() {
			this.route("reviewConfirm", { path: "/review-confirm" });
		});

	});
	this.route("fsaFinishSplash", { path: "/fsa-finish-splash" });
	this.route("fsaEnrollAgreement", { path: "/fsa-enroll-agreement" });

	this.route("fsaIntro");
	this.route("fsarenewalgetstarted");

	// Start of FSA v1 legacy routes
	this.resource("fsasetup", function() {
		this.route("company");
		this.route("start");
		this.route("compliance");
		this.route("keyemployees");
		this.route("compensatedemployees");
		this.route("contribution");
		this.route("contract");
		this.route("bank");
		this.route("security");
	});

	this.resource("fsarenewal", function() {
		this.route("company");
		this.route("start");
		this.route("compliance");
		this.route("keyemployees");
		this.route("compensatedemployees");
		this.route("contribution");
		this.route("contract");
		this.route("bank");
		this.route("security");
	});
	// End of FSA v1 legacy routes

	this.route("hraAdminSetup", { path: "/hra-admin-setup" }, function() {
		this.route("planDetails", { path: "/plan-details" }, function() {
			this.route("planYear", { path: "/plan-year" });
			this.route("payrollInfo", { path: "/payroll-info" });
			this.route("compatiblePlans", { path: "/compatible-plans" });
			this.route("autoEnrollment", { path: "/auto-enrollment" });
			this.route("contributions");
			this.route("funding");
			this.route("summary");
		});
		this.route("finishSetup", { path: "/finish-setup" }, function() {
			this.route("billingAndAgreement", { path: "/billing-and-agreement" });
			this.route("checkout");
		});
	});
	this.route("hraAdminFinishSplash", { path: "/hra-admin-finish-splash" });


	this.route("hraIntro");
	this.resource("hrasetup", function() {
		this.route("company");
		this.route("start");
		this.route("medicalplans");
		this.route("contribution");
		this.route("bank");
	});
	this.route("hrasetupfinish");
	this.route("hrasetupemails");
	this.route("hraemailspreview");
	// Start of HRA renewals
	this.route("hrarenewalgetstarted");
	this.resource("hrarenewal", function() {
		this.route("company");
		this.route("info");
		this.route("start");
		this.route("medicalplans");
		this.route("contribution");
		this.route("bank");
	});
	this.route("hrarenewalfinish");
	this.route("hrarenewalemails");
	this.route("hrarenewalemailspreview");
	// End of HRA renewals
	this.resource("hrasettings", function() {
		this.route("overview");
		this.route("eligibleemployees");
		this.route("participatingemployeesside");
		this.route("change");
		this.route("cancel");
		this.route("documents");
		this.route("invoices");
		this.route("editeligibility");
	});
	this.route("hrarenewalgetstarted");
	this.resource("hrarenewal", function() {
		this.route("company");
		this.route("info");
		this.route("start");
		this.route("medicalplans");
		this.route("compliance");
		this.route("keyemployees");
		this.route("compensatedemployees");
		this.route("contribution");
		this.route("security");
		this.route("bank");
	});
	this.route("hrarenewalfinish");
	this.route("hrarenewalemails");
	this.route("hrarenewalemailspreview");
	this.route("commuter.intro", { path: "ucommuter/intro" });
	this.resource("ucommuter", function() {
		this.route("company");
		this.route("start");
		this.route("contribution");
		this.route("contract");
		this.route("bank");
	});
	this.resource("ucommuterSetup", { path: "/commuter-setup" }, function() {
			this.route("introduction", function() {
				this.route("about");
			});
			this.route("planDetails", { path: "/plan-details" }, function() {
				this.route("planYear", { path: "/plan-year" });
				this.route("contribution");
				this.route("funding");
				this.route("summary");
			});
			this.route("finish", function() {
				this.route("billing");
				this.route("zfbCheckout", { path: "/zfb-checkout" });
			});
		});

	this.route("ucommuterFinishSplash", { path: "/commuter-setup-complete" });
	this.route("ucommuterfinish");
	this.route("ucommuteremails");
	this.route("ucommuteremailspreview");
	this.resource("ucommutersettings", function() {
		this.route("overview");
		this.route("eligibleemployees");
		this.route("participatingemployeesside");
		this.route("employeeeligibility");
		this.route("contributionchanges");
		this.route("employeeeligibilityside");
		this.route("change");
		this.route("cancel");
		this.route("documents");
		this.route("invoices");
	});

	this.route("inbox", {path : "/inbox/:inbox_action_id"});
	this.route("inbox-error");
	this.route("inboxView", {path : "/inbox-view"});

    this.resource("401k", {path:"/401k"}, function() {
    	this.route("payrollnotsync");
    });
    this.resource("company401k.addExisting", {path:"/company401kAddExisting"}, function() {
	    this.route("connectionInfo");
        this.route("intro");
    });

	this.resource("company401k.newEnrollment", { path:"/company401k" }, function() {
		this.route("agreementPrep");
        this.route("agreement");
		this.route("bank");
	    this.route("company");
		this.route("complianceInfo");
		this.route("complianceTesting");
		this.route("connectPayroll");
		this.route("employeeEmails");
        this.route("employeeEmailsPreview");
		this.route("highlyCompensatedEmployees");
		this.route("intro");
        this.route("keyEmployees");
        this.route("planInfo");
        this.route("syncing401k");
        this.route("trustee");
	});
	this.resource("company401k.manage", {path:"/company401kManage"}, function() {
		this.route("overview");
		this.route("keyEmployees");
		this.route("highlyCompensatedEmployees");
		this.route("signedAgreements");
		this.route("uploadPayrollJournal");
		this.route("uploadedPayrollJournalHistory");
		this.route("participatingEmployees");
		this.route("learnMore");
	});

	this.resource("aca", function() {
		this.route("overview");
	});

	this.resource("performance", function() {
		this.route("overview");
		this.route("review");
	});

	this.resource("newbusinessinsurance", function() {
		this.route("getquotes");
	});

	this.route('bizInsurance', {path: '/business-insurance'}, function(){
		this.route("overview");
	});

	this.resource("businessinsurance", function() {
		this.route("overview");
		this.resource("businessinsurance.addexisting", { path : "/addexisting"}, function() {
			// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename or remove, please change in zapp.py as well
			this.route("questions", { path: "/questions" });
		});
		this.route("addexistingfinish");
	});

	this.resource("lifedisability", { path : "/life-disability" }, function() {
		this.resource("lifedisability.employeeOverview", { path : "/employee-overview" }, function() {
			this.route("auto", { path : "auto" });
			this.route("lifenew", { path : "life" });
			this.route("add", { path : "add" });
			this.route("std", { path : "std" });
			this.route("ltd", { path : "ltd" });
		});
		this.route("eoiconfirm", { path : "/eoi-confirm" });
		this.route('newOrExisting', { path: '/new-or-existing' });
		this.route('borBlock', { path: '/bor-block' });
		this.route('quotesBlock', { path: '/quotes-block' });
		this.route('aprBlock', { path: '/apr-block' });
		this.route("overview", { path : "/overview" });
	});
	this.route("lifedisabilityenrollmentfinish");

	this.route("lifegetquotes");
	this.resource("life", function() {
		this.route("lifedetails");
		this.route("ltddetails");
		this.route("stddetails");
		this.route("change");
		this.route("cancel");
		this.route("spds");
	});
	this.route("dentalsettings", { path : "/dentalsettings" });
	this.route("visionsettings", { path : "/visionsettings" });
	// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename or remove, please change in zapp.py as well
	this.route("plansreview", { path : "/plansreview" });
	this.route("dentalplansreview", { path : "/dentalplansreview" });
	this.route("visionplansreview", { path : "/visionplansreview" });
	// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename or remove, please change in zapp.py as well
	// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename or remove, please change in zapp.py as well
	this.resource("addexisting", function() {
		this.route("questions");
		this.route("policy");
		this.route("life");
		this.route("businessinsurance");
	});
	this.route("addexistingfinish");
	this.route("benefits", function() {
		this.route("intro");
		this.route("benefits-selection");
	});
	// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename, please change in zapp.py as well
	this.route("bordashboard");
	this.resource("setup", function() {
		this.route("shortcircuitoeintro");
		this.route("totalemployees");
		this.route("currentwaitingperiods");
		this.route("newwaitingperiods");
		this.route("benefitstermination");
		this.route("configureschbor");
		this.route("configureschoe");
		this.route("plansummary"); // BoR plans summary
		this.route("shortcircuitplansummary"); // SCOE plans summary
		this.route("leadtime");
		this.route("scoeemails");
		this.route("contribution");
		this.route("census");
		this.route("emails");
		this.route("invoices");
		this.route("carrierdata", { path : "carrierdata/:carrierid" });
		this.route("borcompletecongrats");
		this.route("companyinformation");
		this.route("cobratakeover");
		this.route("boremployeeemails");
		this.route("bormetemployees");
		this.route("employeeemails");
		this.route("sendinvites");
		this.route("invitetestdrive");
		this.route("employeeprogress");
		this.route("choosecensus");
	});

	this.resource("bor", function() {
		this.route("dashboard");
		this.route("setup", function() {
			this.route("bornewhire"); // new hire pages
			this.route("documentcollection"); // bor document collection pages
		});
		// BoR early emails
		this.route('borEarlyEmails', {path: '/setupearlyemails' }, function() {
			this.route('meetEmployees', {path: '/meetemployees'});
		});
	});

	this.route("planreview", { path : "planreview/:action_notification_id" });
	this.route("planreviewaccepted");
	this.route("planreviewrejected");

	this.route("employeeenrollmentsreview", { path : "employeeenrollmentsreview/:action_notification_id" });
	this.route("employeeenrollmentsreviewaccepted");
	this.route("employeeenrollmentsreviewrejected");

	this.route("deductionsreview", { path : "deductionsreview/:action_notification_id" });
	this.route("deductionsreviewaccepted");
	this.route("deductionsreviewrejected");

	// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename or remove, please change in zapp.py as well
	this.route("getquotes");
	// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename or remove, please change in zapp.py as well
	this.route("enrollmentsummary");
	this.resource("enrollment", {path: "/enrollment"}, function() {
		this.route("/");

		this.route('planrequirements.introduction', { path: '/planrequirements/introduction' });
		this.route('planrequirements.coveragestartdate', { path: '/planrequirements/coveragestartdate'});
		this.route('planrequirements.previouscoverage', { path: '/planrequirements/previouscoverage'});
		this.route('planrequirements.ownership', { path: '/planrequirements/ownership'});
		this.route('planrequirements.workerscomp', { path: '/planrequirements/workerscomp'});
		this.route('planrequirements.employeeinfo', { path: '/planrequirements/employeeinfo'});
		this.route('planrequirements.vspcontract', { path: '/planrequirements/vspcontract'});
		this.route('planrequirements.guardiancontract', { path: '/planrequirements/guardiancontract'});
		this.route('planrequirements.participationminimums', { path: '/planrequirements/participationminimums'});
		this.route('planrequirements.instaterules', { path: '/planrequirements/instaterules'});
		this.route('planrequirements.summary', { path: '/planrequirements/summary'});

		this.route("companypolicy.leaveofabsence", { path: "/companypolicy/leaveofabsence"});
		this.route("companypolicy.coverageofpremiums", { path: "/companypolicy/coverageofpremiums"});
		this.route("companypolicy.dentalcoverageofpremiums", { path: "/companypolicy/dentalcoverageofpremiums"});
		this.route("companypolicy.visioncoverageofpremiums", { path: "/companypolicy/visioncoverageofpremiums"});
		this.route("companypolicy.waitingperiods", { path: "/companypolicy/waitingperiods"});

		this.route("admincontact.choosecontact", { path: "/admincontact/choosecontact"});

		this.route("companyinfo.companynames", { path: "/companyinfo/companynames"});
		this.route("companyinfo.addresses", { path: "/companyinfo/addresses"});
		this.route("companyinfo.entitytypes", { path: "/companyinfo/entitytypes"});
		this.route("companyinfo.ein", { path: "/companyinfo/ein"});
		this.route("companyinfo.bankdetails", { path: "/companyinfo/bankdetails"});
		this.route("companyinfo.dateestablished", { path: "/companyinfo/dateestablished"});
		this.route("companyinfo.businessdescription", { path: "/companyinfo/businessdescription"});
		this.route("companyinfo.industrytype", { path: "/companyinfo/industrytype"});
		this.route("companyinfo.siccode", { path: "/companyinfo/siccode"});
		this.route("companyinfo.locations", { path: "/companyinfo/locations"});

		this.route("underwriting.subsidiaries", { path: "/underwriting/subsidiaries"});
		this.route("underwriting.likelytodecline", { path: "/underwriting/likelytodecline"});
		this.route("underwriting.othermedicalcoverage", { path: "/underwriting/othermedicalcoverage"});
		this.route("underwriting.cobra", { path: "/underwriting/cobra"});
		this.route("underwriting.cobraelection", { path: "/underwriting/cobraelection"});
		this.route("underwriting.stdltd", { path: "/underwriting/stdltd"});
		this.route("underwriting.medicalleave", { path: "/underwriting/medicalleave"});
		this.route('underwriting.companyhistory', { path: '/underwriting/companyhistory'});
		this.route('underwriting.brokerrecommendations', { path: '/underwriting/brokerrecommendations'});

		this.route("medicaldetails.medicalquestions", { path: "/medicaldetails/medicalquestions"});

	});
	this.route("enrollmentfinaldetails");
	this.route("enrollmentfinish");
	this.route("enrollmentsection");
	this.route("enrollmentfinishedit");
	this.route("borEnrollmentLetters", { path: '/bor-enrollment-letters'});
	this.route("employeeemails");
	// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename or remove, please change in zapp.py as well
	this.route("reviewfinish");
	this.route("reviewemployeeemails");
	// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename or remove, please change in zapp.py as well
	this.route("documents", { path: '/documents' }, function () {
		this.route("apps", { path : "/apps" }, function () {
			this.route("hiring", { path : "/hiring" }, function() {
				this.route("details", { path : "/details/:employee_id" });
			});
			this.route("agreements", { path : "/agreements" }, function() {
				this.route("details", { path : "/details/:employee_id" });
			});
			this.route("benefits", { path : "/benefits" }, function() {
				this.route("details", { path : "/details/:employee_id" });
			});
			this.route("payroll", { path : "/payroll" }, function() {
				this.route("details", { path : "/details/:employee_id" });
			});
			this.route("other", { path : "/other" }, function() {
				this.route("details", { path : "/details/:employee_id" });
			});
			this.route("talent", { path : "/talent" }, function() {
				this.route("details", { path : "/details/:employee_id" });
			});
		});
		this.route("company", { path: '/company' });
	});
	// XXX: This route is referenced in backend - company_enrollment/services/zapp.py. If you plan to rename, please change in zapp.py as well
	this.route("document-collection", {path: "/documentcollection"});
	this.route("document-collection.collection-wizard", {path: "/documentcollection/upload/:documentId"}, function() {
		this.route("intro", {path: "/intro"});
		this.route("upload", {path: "/upload-documents"});
		this.route("next-steps", {path: "/next-steps"});
	});


	this.route('benefitspreview');

	this.resource("adminopenenrollment", {"path" : "/adminopenenrollment"}, function() {
		this.route("reviewmedicalplans");
		this.route("previewmedicalplans");
		this.route("reviewdentalplans");
		this.route("previewdentalplans");
		this.route("reviewvisionplans");
		this.route("previewvisionplans");
		this.route("reviewlifeplans");
		this.route("previewlifeplans");
		this.route("reviewaddplans");
		this.route("previewaddplans");
		this.route("reviewstdplans");
		this.route("previewstdplans");
		this.route("reviewltdplans");
		this.route("previewltdplans");
		this.route("emailemployees");
	});

	this.route("cobraIntro");

	this.resource("addcobra", {"path": "/addcobra"}, function() {
		this.route("questionnaire");
		this.route("tpa");
	});
	this.route("addcobrahandover");

	this.resource("addcobra2", {"path": "/addcobra2"}, function() {
		this.route("redirect");
		this.route("classification");
		this.route("confirmclassification");
		this.route("census");
		this.route("entry", { "path" : "/census/:employee_id" });
		this.route("summary");
	});

	this.resource("cobra", {"path": "/cobra"}, function() {
		this.route("participants");
		this.route("participantsold");
		this.route("bank");
		this.route("payments");
		this.route("paymentsold");
		this.route("manage");
		this.route("editcensus");
		this.route("help");
	});

	// Cobra reclassification
	this.route("cobraReclassification",  {path: '/cobra-reclassification'}, function () {
		this.route("flow", {"path" : "/flow" }, function() {
			this.route("setup", {"path" : "/setup" }, function() {
				this.route("intro", {"path" : "/intro"});
				this.route("customForm", {"path" : "/custom-form/:change_request_id"});
				this.route("supplementalForm", {"path" : "/supplemental-form/:change_request_id"});
				this.route("taxForm", {"path" : "/tax-form"});
				this.route("companyLetter", {"path" : "/company-letter/:change_request_id"});
				this.route("confirm", {"path" : "/confirm"});
			});
		});
		this.route("success", {"path" : "/success"});
	});

	this.resource("offboarding", {"path": "/offboarding"}, function() {
		this.route("changecobra");
		this.route("termpolicy");
 		this.route("terminate", { path: '/terminate/:all_employee_id'}, function() {
 			this.route("intro", { path: '/intro'});
 			this.route("hr", { path: '/hr'});
		    this.route("benefits", { path: '/benefits' });
		    this.route("short-circuit-benefits", { path: '/benefits-details' });
 			this.route("cobra", { path: '/cobra' });
 			this.route("payroll", { path: '/payroll' });
 			this.route("email", { path: '/email' });
 			this.route("integrations", { path: '/integrations' });
 			this.route("review", { path: '/review' });
 			this.route("nextsteps", { path: '/next-steps' });
 		});
	});

	// EMPLOYEE APP
	this.resource("employee", { path : "/employee" }, function() {
		this.route("employeeinfo", { path : "/employeeinfo" });
		this.route("orgchart", { path : "/orgchart" });
		this.route("qle", function() {
			this.route("/");
			this.route("sign", { path: '/sign/:qualifying_event_id'});
			this.route("signCancelCoverage", { path: '/sign-cancel-coverage'});
		});
		this.route("tearsheet", { path : "/tearsheet/:all_employee_id" });
		this.route("confirmbasicinfo",	{ path : "confirmbasicinfo" });
		this.route("openenrollment.status", { path: "/openenrollment/status"});
		this.route("newhire.status", { path: "/newhire/status" });

		this.resource("employee.personalinfo", { path : "/personalinfo" }, function() {
			this.route("/");
			this.route("basicinfo", { path: "/basicinfo" });
			this.route("customfields", { path: "/customfields" });
			this.route("contactinfo", { path : "/contactinfo" });
			this.route("emergency", { path : "/emergency" });
			this.route("vaccination", { path : '/vaccination'});
			this.route("contractorbankinfo", { path : "/contractorbankinfo" });
			this.route("contractorexemptions", { path : "/contractorexemptions" });
			this.route("employmentinfo", { path: "/employmentinfo" });
			this.route("documents", { path: "/documents" });
			this.route("contractorw9", { path: "/contractorw9" });
			this.route("paystubs", {path: "paystubs"});
		});
		this.resource("employee.payrollinfo", { path : "/payrollinfo" }, function() {
			this.route("/");
			this.route("bankinformation", { path: "/bankinformation" });
			this.route("bankinfo", { path: "/bankinfo" });
			this.route("federaltaxinfo", { path : "/taxinfo" });
			this.route("federaltaxinfo", { path : "/federaltaxinfo" });
			this.route("statetaxinfo", { path : "/statetaxinfo" });
		});
		this.route("cobraIntro");
		this.resource("employee.cobra", {path: "/cobra"}, function() {
			this.route("contact");
			this.route("dependents");
			this.route("enrollment");
			this.route("bank");
		});
		this.route("confirmCobraCoverage");
		this.route('declineCobraCoverage');
		this.resource("employee.cobraOverview", {path: "/cobraOverview"}, function() {
			this.route("overview");
			this.route("bank");
			this.route("payments");
			this.route("cancel");
			this.route("manage");
		});
		this.route("confirmCobraCancelation");

		this.route("hsaIntro");
		this.route("hrablockedhsa");
		this.route("fsablockedhsa");
		this.resource("employee.hsa", { path : "/hsa" }, function() {
			this.route("basicinfo");
			this.route("contact");
			this.route("setup");
			this.route("nextSteps");
		});
		this.route("hsafinish");
		this.route("401k.intro", {path:"/employee401kIntro"});
		this.route("401k.ssnrequired", {path:"/employee401kssnrequired"});
		this.resource("employee.401k.setup", {path: "/employee401k"}, function() {
			this.route("basicinfo");
			this.route("contact");
			this.route("contribution");
			this.route("beneficiary");
		});
		this.resource("employee.401k.manage", {path: "/employee401kManage"}, function() {
			this.route("overview");
			this.route("contribution", {path:"/changeContribution"});
			this.route("beneficiary", {path:"/changeBeneficiary"});
		});
		this.resource("employee.hsaSettings", { path : "/hsaSettings" }, function() {
			this.route("overview");
			this.route("eligibleexpenses");
			this.route("accountactivity");
			this.route("transactionhistory");
			this.route("change");
			this.route("changeconfirm");
			this.route("cancel");
			this.route("cards");
		});
		this.route("fsaIntro");
		this.resource("employee.fsa", { path : "/fsa" }, function() {
			this.route("basicinfo");
			this.route("contact");
			this.route("bank");
			this.route("contribution");
		});
		this.route("fsafinish");
		this.route("fsanextsteps");
		this.resource("employee.fsasettings", { path : "/fsasettings" }, function() {
			this.route("overview");
			this.route("eligibleexpenses");
			this.route("accountactivity");
			this.route("transactionhistory");
			this.route("updatebankinfo");
			this.route("change");
			this.route("claims");
			this.route("claimsz2");
			this.route("submitclaims");
			this.route("showclaim", { path: "/showclaim/:fsa_claim_id"});
			this.route("pendingclaims");
			this.route("cards");
			this.route("cancel");
		});
		// Start of FSA renewals
		this.route("fsarenewalgetstarted");
		this.resource("employee.fsarenewal", { path : "/fsarenewal" }, function() {
			this.route("basicinfo");
			this.route("contact");
			this.route("bank");
			this.route("contribution");
		});
		this.route("fsarenewalfinish");
		// End of FSA renewals

		this.route("hraIntro");
		this.route("hrablocked");
		this.resource("employee.hra", { path : "/hra" }, function() {
			this.route("basicinfo");
			this.route("contact");
			this.route("bank");
			this.route("medicare");
		});
		this.route("hrafinish");

		// Start of HRA renewals
		this.route("hrarenewalgetstarted");
		this.resource("employee.hrarenewal", { path : "/hrarenewal" }, function() {
			this.route("basicinfo");
			this.route("contact");
			this.route("bank");
			this.route("medicare");
		});
		this.route("hrarenewalfinish");
		// End of HRA renewals

		this.route("hranextsteps");
		this.route("hradecline");
		this.resource("employee.hrasettings", { path : "/hrasettings" }, function() {
			this.route("overview");
			this.route("eligibleexpenses");
			this.route("accountactivity");
			this.route("transactionhistory");
			this.route("updatebankinfo");
			this.route("updatebankinfov2");
			this.route("claims");
			this.route("claimsz2");
			this.route("submitclaims");
			this.route("showclaim", { path: "/showclaim/:hra_claim_id"});
			this.route("cancel");
			this.route("pendingclaims");
			this.route("cards");
		});
		this.route("hrarenewalgetstarted");

		this.resource("employee.hsaSetup", { path: "/hsa-setup" }, function() {
			this.route("introduction", function() {
				this.route("about");
			});
			this.route("employeeDetails", { path: "/employee-details" }, function() {
				this.route("basicDetails", { path: "/basic-details" });
				this.route("contactDetails", { path: "/contact-details" });
			});
			this.route("planDetails", { path: "/plan-details" }, function() {
				this.route("contribution", { path: "/contribution" });
			});
			this.route("finish", function() {
				this.route("reviewConfirm", { path: "/review-confirm" });
			});
		});
		this.route("hsaFinishSplash", { path: "/hsa-finish-splash"});
		this.route("hsaEnrollAgreement", { path: "/hsa-enroll-agreement"});

		this.route("ucommuterIntro");
		this.resource("employee.ucommuter", {path: "/ucommuter"}, function() {
			this.route("basicinfo");
			this.route("contact");
			this.route("bank");
			this.route("contribution");
		});
		this.resource("employee.ucommuterSetup", { path: "/ucommuter-setup" }, function() {
			this.route("introduction", function() {
				this.route("about");
			});
			this.route("employeeDetails", { path: "/employee-details" }, function() {
				this.route("basicDetails", { path: "/basic-details" });
				this.route("contactDetails", { path: "/contact-details" });
				this.route("bankDetails", { path: "/bank-details" });
			});
			this.route("planDetails", { path: "/plan-details" }, function() {
				this.route("intro");
				this.route("contribution", { path: "/contribution" });
			});
			this.route("finish", function() {
				this.route("reviewConfirm", { path: "/review-confrim" });
			});
		});
		this.route("ucommuterFinishSplash", { path: "/ucommuter-finish-splash" });
		this.route("ucommuternextsteps");
		this.resource("employee.ucommutersettings", {path: "/ucommutersettings"}, function() {
			this.route("overview");
			this.route("eligibleexpenses");
			this.route("accountactivity");
			this.route("transactionhistory");
			this.route("updatebankinfo");
			this.route("claims");
			this.route("claimsz2");
			this.route("submitclaims");
			this.route("showclaim", { path: "/showclaim/:commuter_claim_id"});
			this.route("change");
			this.route("ordernewcard");
			this.route("cancel");
			this.route("pendingclaims");
			this.route("cards");
		});
		this.resource("employee.life", { path : "/life" }, function() {
			this.route("basicplan");
			this.route("voluntary");
			this.route("voluntaryselfenroll");
			this.route("dependents");
			this.route("dependentenroll");
			this.route("eoi");
			this.route("eoinew");
			this.route("beneficiaries");
			this.route("enrollmentfinish");
		});
		this.resource("employee.adnd", { path : "/adnd" }, function() {
			this.route("basicplan");
			this.route("voluntary");
			this.route("voluntaryselfenroll");
			this.route("dependents");
			this.route("dependentenroll");
			this.route("eoi");
			this.route("eoinew");
			this.route("beneficiaries");
			this.route("enrollmentfinish");
		});
		this.resource("employee.std", { path : "/std" }, function() {
			this.route("basicplan");
			this.route("voluntary");
			this.route("voluntaryselfenroll");
			this.route("dependents");
			this.route("dependentenroll");
			this.route("eoi");
			this.route("eoinew");
			this.route("enrollmentfinish");
		});
		this.resource("employee.ltd", { path : "/ltd" }, function() {
			this.route("basicplan");
			this.route("voluntary");
			this.route("voluntaryselfenroll");
			this.route("dependents");
			this.route("dependentenroll");
			this.route("eoi");
			this.route("eoinew");
			this.route("enrollmentfinish");
		});
		this.resource("employee.plansettings", { path : "/plansettings" }, function() {
			this.route("/");
			this.route("overview", { path: "/overview" });
			this.resource("employee.plansettings.insurancecards", { path : "/insurancecards" }, function() {
				this.route("/");
				this.route("requestnewcard", { path : "/requestnewcard" });
			});
			this.route("spds", { path: "/spds" });
			this.route("dependents", { path: "/dependents" });
		});
		// Change Insurance Landing Page
		this.route("overview", { path: "/overview" }, function() {
			this.route("mdv", { path: "/mdv"}, function() {
				this.route("medical", { path: "/medical" });
				this.route("dental", { path: "/dental" });
				this.route("vision", { path: "/vision" });
				this.route("dependents", { path: "/dependents" });
			});
			this.route("medical", { path: "/medical" });
			this.route("dental", { path: "/dental" });
			this.route("vision", { path: "/vision" });
			this.route("lnd", { path: "/lnd" }, function() {
				this.route("newlife", { path: "/newlife" });
				this.route("newdisability", { path: "/newdisability" });
				this.route("beneficiary", { path: "/beneficiary" });
				this.route("cancel", { path: "/cancel" });
			});
			this.route("cobra", { path: "/cobra"}, function() {
				this.route("medical", { path: "/medical" });
				this.route("dental", { path: "/dental" });
				this.route("vision", { path: "/vision" });
				this.route("bank", { path: "/bank" });
				this.route("payments", { path: "/payments" });
				this.route("cancel", { path: "/cancel" });
			});
		});
		this.route("editdependent", { path: "/editdependent/:dependent_id" });
		this.resource("employee.decline", { path : "/decline" }, function() {
			this.route("/");
			this.route("info", { path: "/info" });
			this.route("dentalinfo", { path: "/dentalinfo" });
			this.route("visioninfo", { path: "/visioninfo" });
			this.route("attest", { path: "/attest" });
			this.route("dentalattest", { path: "/dentalattest" });
			this.route("visionattest", { path: "/visionattest" });
		});
		this.resource("employee.onboarding", { path: "/onboarding/:new_hire_id" }, function() {
			this.route("start", { "path" : "/start" });
			this.route("offer", { "path" : "/offer" });
			this.route("ipagreement", { "path" : "/ipagreement/:agreement_id" });
			this.route("employeehandbook", { "path" : "/employeehandbook/:agreement_id" });
			this.route("nonexemptnotice", { "path" : "/nonexemptnotice" });
			this.route("summary", { "path" : "/summary" });
			this.route("personal", { "path" : "/personal" });
			this.route("checkdisclosure", { "path" : "/checkdisclosure" });
			this.route("background", { "path" : "/background" });
			this.route("emergency", { "path" : "/emergency" });
			this.route("vaccination", { path : '/vaccination'});
			this.route("eeo", { "path" : "/eeo" });
			this.route("federaltax", { "path" : "/tax" });
			this.route("federaltax", { "path" : "/federaltax" });
			this.route("statetax", { "path" : "/statetax" });
			this.route("customfields", { "path" : "/customfields" });
			this.route("bank", { "path" : "/bank" });
			this.route("paycheckdistribution", { "path" : "/paycheckdistribution" });
			this.route("eligibility", { "path" : "/eligibility" });
			this.route("upload", { "path" : "/upload" });
			this.route("complete", { "path" : "/complete" });
		});
		// obfuscating so people don't find by accident
		this.resource("employee.onboarding-v2", { path: "/onboarding/v2/:new_hire_id" }, function() {
			this.route("start", { "path" : "/start" });
			this.route("offer", { "path" : "/offer" });
			this.route("ipagreement", { "path" : "/ipagreement/:agreement_id" });
			this.route("employeehandbook", { "path" : "/employeehandbook/:agreement_id" });
			this.route("contract", { "path" : "/contract/:agreement_id" });
			this.route("nonexemptnotice", { "path" : "/nonexemptnotice" });
			this.route("summary", { "path" : "/summary" });
			this.route("personal", { "path" : "/personal" });
			this.route("contactinfo", { "path" : "/contactinfo" });
			this.route("exemptions", { "path" : "/exemptions" });
			this.route("checkdisclosure", { "path" : "/checkdisclosure" });
			this.route("background", { "path" : "/background" });
			this.route("previewcontractor", { "path" : "/previewcontractor" });
			this.route("emergency", { "path" : "/emergency" });
			this.route("vaccination", { path : '/vaccination'});
			this.route("eeo", { "path" : "/eeo" });
			this.route("federaltax", { "path" : "/tax" });
			this.route("federaltax", { "path" : "/federaltax" });
			this.route("statetax", { "path" : "/statetax" });
			this.route("residencestatetax", { "path" : "/residencestatetax" });
			this.route("customfields", { "path" : "/customfields" });
			this.route("bank", { "path" : "/bank" });
			this.route("paycheckdistribution", { "path" : "/paycheckdistribution" });
			this.route("eligibility", { "path" : "/eligibility" });
			this.route("eligibility-part-1", { "path" : "/eligibility-part-1" });
			this.route("eligibility-part-2", { "path" : "/eligibility-part-2" });
			this.route("review-eligibility", { "path" : "/review-eligibility" });
			this.route("upload", { "path" : "/upload" });
			this.route("benefitsinfo", { "path" : "/benefitsinfo" });
			this.route("integrations", { "path" : "/integrations" });
			this.route("complete", { "path" : "/complete" });
		});
		// HR1 change for url backward compatibility. Can clean up some point after HR1
		this.route("hrAdvisorLegacy", {"path": "/hr-advisor"}, function() {
			this.route("block");
		});
		this.route("hrAdvisor", {"path": "/hr-library"}, function() {
			this.route("block");
		});

		/* Employee Enrollment */
		this.route("basicinfo", { path : "/basicinfo" });

		this.route("dependents");
		this.route("quotedependents");
		this.route("dentalquotedependents");
		this.route("visionquotedependents");

		this.route("planselector", { path : "/planselector" });
		this.route("dentalplanselector", { path : "/dentalplanselector" });
		this.route("visionplanselector", { path : "/visionplanselector" });

		this.route("scoeoldplanselector", { path : "/scoeoldplanselector"});
		this.route("scoeolddentalplanselector", { path : "/scoeolddentalplanselector" });
		this.route("scoeoldvisionplanselector", { path : "/scoeoldvisionplanselector" });

		this.route("scoenewplanselector", { path : "/scoenewplanselector"});
		this.route("scoenewdentalplanselector", { path : "/scoenewdentalplanselector" });
		this.route("scoenewvisionplanselector", { path : "/scoenewvisionplanselector" });

		this.resource("employee.enrollmentoverview", {path: "/enrollmentoverview"}, function() {
			this.route("/");

			this.route("initialenrollment", { path: "/initialenrollment"});
			this.route("openenrollment", { path: "/openenrollment"});
		});
		this.route("openenrollment", { path : "/openenrollment" });
		this.route("dentalopenenrollment", { path : "/dentalopenenrollment" });
		this.route("visionopenenrollment", { path : "/visionopenenrollment" });

		this.route("enrollmentsummary", { path : "/enrollmentsummary" });
		this.resource("employee.enrollmentv1", {path: "/enrollment"}, function() {
			this.route("/");

			this.route("personalinfo.identifyinginfo", { path: "/personalinfo/identifyinginfo"});
			this.route("personalinfo.maritalstatus", { path: "/personalinfo/maritalstatus"});
			this.route("personalinfo.employmentinfo", { path: "/personalinfo/employmentinfo"});
			this.route("personalinfo.paystubs", { path: "/personalinfo/paystubs"});
			this.route("personalinfo.contactinfo", { path: "/personalinfo/contactinfo"});

			this.route("dependents.listdependents", { path: "/dependents/listdependents"});
			this.route("dependents.enrolldependents", { path: "/dependents/enrolldependents"});
			this.route("dependents.dependentsinfo", { path: "/dependents/dependentsinfo"});
			this.route("dependents.dependentsphysician", { path: "/dependents/dependentsphysician"});
			this.route("dependents.dependentsdentist", { path: "/dependents/dependentsdentist"});
			this.route("dependents.waiver", { path: "/dependents/waiver"});

			this.route("previouscoverage.recentcoverage", { path: "/previouscoverage/recentcoverage"});
			this.route("previouscoverage.othercoverage", { path: "/previouscoverage/othercoverage"});
			this.route("previouscoverage.otherdentalcoverage", { path: "/previouscoverage/otherdentalcoverage"});

			this.route("officerstatement.officerstatement", { path: "/officerstatement/officerstatement"});
		});

		this.route('enroll', function() {
			this.route('intro');
			this.route('steps', function() {
				this.route('verification', function() {
					this.route('employee');
					this.route('family');
					this.route('currentCoverage', { path: '/current-coverage' });
				});
				this.route('plans', function() {
					this.route('medical');
					this.route('hsa');
					this.route('hsaSetup', { path: '/hsa-setup' });
					this.route('dental');
					this.route('vision');
					this.route('lifeBasic', { path: '/life-basic/:planId' });
					this.route('lifeBasicDependents', { path: '/life-basic-dependents/:planId' });
					this.route('lifeVoluntary', { path: '/life-voluntary/:planId' });
					this.route('lifeVoluntaryDependents', { path: '/life-voluntary-dependents/:planId' });
					this.route('adndBasic', { path: '/adnd-basic/:planId' });
					this.route('adndBasicDependents', { path: '/adnd-basic-dependents/:planId' });
					this.route('adndVoluntary', { path: '/adnd-voluntary/:planId' });
					this.route('adndVoluntaryDependents', { path: '/adnd-voluntary-dependents/:planId' });
					this.route('stdBasic', { path: '/std-basic/:planId' });
					this.route('stdBasicDependents', { path: '/std-basic-dependents/:planId' });
					this.route('stdVoluntary', { path: '/std-voluntary/:planId' });
					this.route('stdVoluntaryDependents', { path: '/std-voluntary-dependents/:planId' });
					this.route('ltdBasic', { path: '/ltd-basic/:planId' });
					this.route('ltdBasicDependents', { path: '/ltd-basic-dependents/:planId' });
					this.route('ltdVoluntary', { path: '/ltd-voluntary/:planId' });
					this.route('ltdVoluntaryDependents', { path: '/ltd-voluntary-dependents/:planId' });
				});
				this.route('info', function() {
					this.route('qle', { path: '/qle/:qualifyingEventId'});
					this.route('contact');
					this.route('dependentContact', { path: '/dependent-contact/:dependentId' });
					this.route('ssn');
					this.route('marriage');
					this.route('spouseEmployment', { path: '/spouse-employment' });
					this.route('other');
					this.route('employment');
					this.route('beneficiarySummary', { path: '/beneficiary-summary' });
					this.route('beneficiary', { path: '/beneficiary/:beneficiaryId' });
					this.route('beneficiaryAllocation', { path: '/beneficiary-allocation' });
					this.route('employeePhysician', { path: '/employee-physician' });
					this.route('dependentPhysician', { path: '/dependent-physician/:dependentId' });
					this.route('employeeDentist', { path: '/employee-dentist' });
					this.route('dependentDentist', { path: '/dependent-dentist/:dependentId' });
				});
				this.route('review', function() {
					this.route('enrollments');
					this.route('lndEnrollments', { path: '/lnd-enrollments' });
					this.route('carrierCoverage', { path: '/carrier-coverage' });
					this.route('dentalCoverage', { path: '/dental-coverage' });
					this.route('haveCoverage', { path: '/have-coverage' });
					this.route('dependentRecentCoverage', { path: '/dependent-recent-coverage/:dependentId' });
					this.route('dependentCoverageInfo', { path: '/dependent-coverage-info/:dependentId' });
					this.route('dependentMedicare', { path: '/dependent-medicare/:dependentId' });
					this.route('continueMedical', { path: '/continue-medical' });
					this.route('dependentContinueMedical', { path: '/dependent-continue-medical/:dependentId' });
					this.route('continueDental', { path: '/continue-dental' });
					this.route('dependentContinueDental', { path: '/dependent-continue-dental/:dependentId' });
					this.route('waivers');
					this.route('statement');
				});
			});
			this.route('sign');
			this.route('complete');
		});


		this.route("enrollmentfinish");
		this.route("successfinish");
		this.route("openenrollmentfinish");
		this.route("openenrollmentfinishsuccess");
		this.route("cobraBorEnrollmentCompleteDialog");

		// deprecated PTO redirects
		this.resource("employee.pto", function() {
			this.route('index')
		});
	});

	// ATS
	this.resource("ats", { path: "/ats" }, function() {
		this.route("provider", { path: "/:ats_provider_id" }, function() {
			this.route("addNew", { path: "/add-new" });
			this.route("connect", { path: "/connect" });
		});
		this.route("current", { path: "/current" });
		this.route("getQuotes", { path: "/get-quotes" });
		this.route("connectAts", { path: "/connect-ats" });
	});

	this.resource("redirect", {path: "/redirect"}, function() {
		this.route("tearsheet", { path: "/tearsheet/:all_employee_id"});
		this.route("directory");
	});

	this.resource("helpcenter");

	this.route('benefitsOverview', { path: '/benefits-overview'}, function() {
		this.route('enrollment', { path: '/enrollment' });
		this.route('home', { path: '/home'}); // Steady-state
		this.route('bor', { path: '/bor'});
	});

	this.route("newGroupEnrollment", { path: "/new-group-enrollment" }, function() {
		this.route('setup', { path: '/setup' }, function() {
			this.route('contributionSchemeMedical', { path: '/contribution-scheme-medical/:enrollmentWrapper_id' });
			this.route('contributionSchemeDental', { path: '/contribution-scheme-dental/:enrollmentWrapper_id' });
			this.route('contributionSchemeVision', { path: '/contribution-scheme-vision/:enrollmentWrapper_id' });
			this.route('waitingPeriod', { path: '/waiting-period' });
			this.route('leaveOfAbsence', { path: '/leave-of-absence' });
		});
		this.route('enrollment', { path: '/enrollment' }, function() {
			this.route('companyInfo', { path: '/company-info' });
			this.route('likelyToDecline', { path: '/likely-to-decline' });
			this.route('companyLocations', { path: '/company-locations' });
			this.route('companySic', { path: '/company-sic' });
			this.route('companyAddress', { path: '/company-address' });
			this.route('companyOwnership', { path: '/company-ownership' });
			this.route('subsidiaries', { path: '/subsidiaries' });
			this.route('adminContact', { path: '/admin-contact' });
			this.route('principalExecutiveOffice', { path: '/principal-executive-office' });
			this.route('companyHistory', { path: '/company-history' });
			this.route('companyPeo', { path: '/company-peo' });
			this.route('previousCoverageMedical', { path: '/previous-coverage-medical/:carrier_id' });
			this.route('previousCoverageDental', { path: '/previous-coverage-dental/:carrier_id' });
			this.route('previousCoverageVision', { path: '/previous-coverage-vision/:carrier_id' });
			this.route('workersCompensation', { path: '/workers-compensation' });
			this.route('payrollProof', { path: '/payroll-proof' });
			this.route('cobra', { path: '/cobra' });
		});
		this.route('acknowledge', { path: '/acknowledge' }, function() {
			this.route('guardianContract', { path: '/guardian-contract' });
			this.route('vspContract', { path: '/vsp-contract' });
			this.route('brokerRecommendations', { path: '/broker-recommendations' });
			this.route('acknowledgeParticipationRules', { path: '/acknowledge-participation-rules' });
			this.route('acknowledgeOutOfStateRules', { path: '/acknowledge-out-of-state-rules' });
			this.route('subsidiarySetup', { path: '/subsidiary-setup'});
			this.route('bankDetails', { path: '/bank-details' });
		});
	});

	this.route("selfServiceRenewals", { path: "/plan-shopping" }, function() {
		this.route('intro', { path: "/introduction" }, function() {
			this.route('welcome');
			this.route('whatsNext', { path: '/whats-next' });
			this.route('reviewPlan', { path: '/review-plan' });
			this.route('reviewPackage', { path: '/review-package' });
		});
		this.route('selectCoverage', { path: '/selectCoverage/:line_of_coverage' }, function() {
			this.route('renewalOffer', { path: '/renewal-offer' });
			this.route('alternativePackages', { path: '/alternative-packages' });
			this.route('packageEditor', { path: '/package-editor' });
			this.route('contributionScheme', { path: '/contribution-scheme' });
			this.route('upsellPackages', { path: '/upsell-packages' });

		});
		this.route('confirmSelection', { path: '/confirm-selection' }, function() {
			this.route('confirmSelection', { path: 'confirm'});
		});
		this.route('enrollment', { path: '/enrollment' }, function() {
			this.route('leaveOfAbsence', { path: 'leave-of-absence'});
			this.route('waitingPeriod', { path: 'waiting-period'});
			this.route('companyAddress', { path: 'company-address' });
			this.route('companyInfo', { path: 'company-info' });
			this.route('companySic', { path: 'company-sic' });
			this.route('companyHistory', { path: '/company-history' });
			this.route('companyOwnership', { path: 'company-ownership' });
			this.route('subsidiaries', { path: 'subsidiaries' });
			this.route('adminContact', { path: 'admin-contact' });
			this.route('additionalQuestions', { path: '/additional-questions' });
			this.route('companyPeo', { path: 'company-peo' });
			this.route('healthReimbursementAccount', { path: 'health-reimbursement-account' });
			this.route('workersCompensation', { path: 'workers-compensation' });
			this.route('cobra', { path: '/cobra' });
			this.route('previousCoverageMedical', { path: 'previous-coverage-medical/:carrier_id' });
			this.route('previousCoverageDental', { path: 'previous-coverage-dental/:carrier_id' });
			this.route('previousCoverageVision', { path: 'previous-coverage-vision/:carrier_id' });
			this.route('guardianSeriousInjury', { path: 'guardian-serious-injury' });
			this.route('unableToWork', { path: 'unable-to-work' });
			this.route('ownerUnderPlanEarning', { path: '/owner-under-plan-earning' });
			this.route('thirtyHoursFulltime', { path: '/thirty-hours-fulltime' });
			this.route('earningsDifferBy', { path: '/earnings-differ-by' });
			this.route('individualFicaExemption', { path: '/individual-fica-exemption' });
			this.route('significantHealthRisks', { path: '/significant-health-risks' });
			this.route('disabilityStatutoryPlans', { path: '/disability-statutory-plans' });
			this.route('employeeLeave', { path: '/employee-leave' });
		});
		this.route('acknowledge', { path: '/acknowledge' }, function() {
			this.route('subsidiarySetup', { path: 'subsidiary-setup' });
			this.route('switchCancellationOverview', { path: 'switch-cancellation-overview' });
			this.route('switchCancellationSelection', { path: 'switch-cancellation-selection' });
			this.route('switchCancellationLetter', { path: 'switch-cancellation-letter' });
			this.route('waitingPeriodLetter', { path: 'waiting-period-letter'});
			this.route('brokerRecommendations', { path: '/broker-recommendations' });
			this.route('bankDetails', { path: '/bank-details' });
			this.route('guardianContract', { path: '/guardian-contract' });
			this.route('vspContract', { path: '/vsp-contract' });
			this.route('acknowledgeParticipationRules', { path: '/acknowledge-participation-rules' });
			this.route('acknowledgeOutOfStateRules', { path: '/acknowledge-out-of-state-rules' });
			this.route('openEnrollmentPeriod', { path: 'select-open-enrollment-period' });
			this.route('agreement', { path: 'agreement' });
			this.route('invite', { path: 'invite' });
		});
	});

	this.route("preRenewalSurvey", { path: '/pre-renewal-survey'});

	this.resource("customerbilling", { path: '/customer-billing' }, function() {
		this.route('history', { path: '/history' });
	});

	// BoR Sign Up flow
	this.route("borSetupWizard", { "path": "/setupbenefits"}, function() {
		this.route("setupLine", { "path": "/setupline/:loc"}, function() {
			this.route("currentPlans", {"path":"/currentplans"});
			this.route("currentWaitingPeriods", {"path":"/currentwaitingperiods"});
			this.route("contributions", {"path":"/contributions"});
		});
		this.route("ea", { "path": "/ea"}, function() {
			this.route("carrierAccess", { "path": "/carrieraccess/:carrierid"});
		});
		this.route('sendInvites', {path: '/sendinvites' }, function() {
			this.route('introduction', {path: '/intro'});
			this.route('editEmails', {path: '/editemails'});
		});
	});

	this.resource("courtorderdependents", { path: '/court-order-dependents/:employee_id' });

	this.route('accountsettings', { path: '/account-settings' }, function() {
		this.route('settings', { path: '/settings' });
	});

	this.route('census', { path: '/census'}, function() {
		// Needs queryParams.flow to be defined (ex: "?flow=HR")
		this.route('selectMethod', { path: '/select-method' });
		this.route('import', { path: '/import' });
		this.route('sheets', { path: '/sheets' });
		this.route('reviewEmployees', { path: '/review-employees' });
		this.route('addMore', { path: '/add-more' });
	});

	// Self-Serve Signup =========================================================

	this.route('selfServeSignup', { path: '/welcome'}, function() {
		this.route('spokeSelector', { path: '/' }, function() {
			this.route('selectProduct', { path: '/select-product' });
		});
		this.route('benefits', function() {
			this.route('newOrExisting', { path: '/new-or-existing' });
			this.route('enterOrImport', { path: '/enter-or-import' });
		});
		this.route('payroll', function() {
			this.route('newOrExisting', { path: '/new-or-existing' });
			this.route('enterOrImport', { path: '/enter-or-import' });
		});
		this.route('hr', function() {
			this.route('intro');
			this.route('enterOrImport', { path: '/enter-or-import' });
		});
	});

	// Growth ======================================================================

	this.route('incrementalAddEmployees', { path: '/add-employees' });

	this.route('billing', function() {
		this.route('expiringPlan', { path: '/expiring-plan' });
		this.route('reasonsToChoosePlan', { path: '/reasons-to-choose-plan' });
		this.route('choosePlan', { path: '/choose-plan' });
		this.route('success');
		this.route('checkout');
		this.route('error');
		this.route('contactSupport', { path: '/contact-support' });
	});

	this.route("saasContract", { path: '/saas-contract' });

	// Platform ====================================================================
	this.route('integrations');
	this.route('integrations.integration', { path: '/integrations/:integration_id' }, function() {
		this.route('overview');
		this.route('nav-elements.settings', { path: '/settings' });
		this.route('nav-elements.account-mapping', {path: '/mapping'})
		this.route('uninstall');
	});
	// TODO: remove this when zywave integration is ready
	this.route('integrations.integration.zywave', { path: '/integrations/zywave'});

	// zapi/thirdpartyapp?zappId=2.com.gogole.gmail
	this.route('zapi', {path: '/zapi' }, function() {
		this.route('flow', {path: '/flow/:z_api_flow_id'});
		this.route('thirdpartyapp', {path: '/thirdpartyapp'});
		this.route('integrations', {path: '/integrations'});
	});

	// BoR Broker Services =========================================================
	this.route('brokerServices', { path: '/broker-services/:superSession_id'}, function() {
		this.route('introduction', { path: '/introduction' }, function() {
			this.route('overviewIntroduction', { path: '/overview' });
			this.route('documentsRequired', { path: '/documents-required' });
		});
		this.route('employeeDemographics', { path: '/employee-demographics' }, function() {
			this.route('confirmInformation', { path: '/confirm-information' });
			this.route('confirmEmployment', { path: '/confirm-employment' });
		});
		this.route('benefitsReview', { path: '/benefits-review/:loc' }, function() {
			this.route('confirmEnrollment', { path: '/confirm-enrollment' });
			this.route('carrierResolution', { path: '/carrier-resolution' });
		});
		this.route('finalizeChanges', { path: '/finalize-changes' }, function() {
			this.route('finalizeEmployee', { path: '/finalize-employee/:stagedEmployee_id' });
			this.route('finalizeExceptionMedical', { path: '/finalize-exception-medical/:stagedEmployee_id' });
			this.route('finalizeExceptionDental', { path: '/finalize-exception-dental/:stagedEmployee_id' });
			this.route('finalizeExceptionVision', { path: '/finalize-exception-vision/:stagedEmployee_id' });
			this.route('summary', { path: '/summary' });

		});
	});

	this.route('z2OverviewPoc', {path: '/z2-overview-poc'}); // Example Overview Page layout (Temp)
	this.route('z2OverviewPocBenefits', {path: '/z2-overview-poc-benefits'}); // Overview Page components for benefits

	this.route('cardsPoc', { path: '/cards-poc' }, function() { // Cards POC (temp)
		this.route('cardsSettings', { path: '/cards-settings' }, function() {
			this.route('basicInfo', { path: '/basic-info'});
			this.route('bankInfo', { path: '/bank-info' });
			this.route('documents', { path: '/documents' });
		});
		this.route('cardsSimpleWizard', { path: '/cards-simple-wizard' }, function() {
			this.route('basicInfo', { path: '/basic-info'});
			this.route('bankInfo', { path: '/bank-info' });
			this.route('documents', { path: '/documents' });
		});
		this.route('cardsBigWizard', { path: '/cards-big-wizard' }, function() {
			this.route('sectionOne', { path: '/section-one' }, function() {
				this.route('stepOne', { path: '/step-one'});
				this.route('stepTwo', { path: '/step-two' });
			});
			this.route('sectionTwo', { path: '/section-two' }, function() {
				this.route('stepOne', { path: '/step-one'});
				this.route('stepTwo', { path: '/step-two' });
			});
		});
	});

	this.route('compliance-companion', { path: 'compliance-assistant' }, function() {
		this.route('overview');
		this.route('upcoming-deadlines');
		this.route('additional-requirements');
		this.route('archive');
		this.route('legal-notice');
	});

	// Hub Data Wizard Service =========================================================
	this.route('hubDataWizard', {path: '/core-information/:flow_id'}, function() {
		this.route('introduction', {path: '/introduction'});
		this.route('basicInfo', {path: '/basic-info'});
		this.route('contactInfo', {path: '/contact-info'});
		this.route('emergencyContacts', {path: '/emergency-contacts'});
		this.route('bankInfo', {path: '/bank-info'});
		this.route('dependentInfo', {path: '/dependent-info'});
		this.route('employmentInfo', {path: '/employment-info'});
		this.route('enrollmentInfo', {path: '/enrollment-info'});
		this.route('federalTax', {path: '/federal-tax'});
		this.route('stateTax', {path: '/state-tax'});
		this.route('residenceStateTax', {path: '/residence-state-tax'});
	});

	// Pre-requisite redirect to lock dashboard ========================================
	this.route('registration-complete', {path: '/registration-complete'});

	// Unified Employee Enrollment ====================================================================
	this.route('employee-enrollment', function() {
		this.route('intro');
		this.route('steps', function() {
			this.route('verification', function() {
				this.route('employee');
				this.route('family');
			});
			this.route('plans', function() {
				this.route('medical', { path:'/medical/:effectiveDate'});
				this.route('hsa');
				this.route('hsaSetup', { path: '/hsa-setup' });
				this.route('dental', { path:'/dental/:effectiveDate'});
				this.route('vision', { path:'/vision/:effectiveDate'});
				this.route('lifeBasic', { path: '/life-basic/:effectiveDate' });
				this.route('lifeBasicDependents', { path: '/life-basic-dependents/:effectiveDate' });
				this.route('lifeVoluntary', { path: '/life-voluntary/:effectiveDate' });
				this.route('lifeVoluntaryDependents', { path: '/life-voluntary-dependents/:effectiveDate' });
				this.route('adndBasic', { path: '/adnd-basic/:effectiveDate' });
				this.route('adndBasicDependents', { path: '/adnd-basic-dependents/:effectiveDate' });
				this.route('adndVoluntary', { path: '/adnd-voluntary/:effectiveDate' });
				this.route('adndVoluntaryDependents', { path: '/adnd-voluntary-dependents/:effectiveDate' });
				this.route('stdBasic', { path: '/std-basic/:effectiveDate' });
				this.route('stdBasicDependents', { path: '/std-basic-dependents/:effectiveDate' });
				this.route('stdVoluntary', { path: '/std-voluntary/:effectiveDate' });
				this.route('stdVoluntaryDependents', { path: '/std-voluntary-dependents/:effectiveDate' });
				this.route('ltdBasic', { path: '/ltd-basic/:effectiveDate' });
				this.route('ltdBasicDependents', { path: '/ltd-basic-dependents/:effectiveDate' });
				this.route('ltdVoluntary', { path: '/ltd-voluntary/:effectiveDate' });
				this.route('ltdVoluntaryDependents', { path: '/ltd-voluntary-dependents/:effectiveDate' });
				this.route('suppAcc', { path:'/supp-acc/:effectiveDate'});
				this.route('suppCancer', { path:'/supp-cancer/:effectiveDate'});
				this.route('suppCi', { path:'/supp-ci/:effectiveDate'});
				this.route('suppHi', { path:'/supp-hi/:effectiveDate'});
			});
			this.route('info', function() {
				this.route('qle', { path: '/qle/:qualifyingEventId'});
				this.route('contact');
				this.route('dependentContact', { path: '/dependent-contact/:dependentId' });
				this.route('ssn');
				this.route('marriage');
				this.route('other');
				this.route('employment');
				this.route('beneficiarySummary', { path: '/beneficiary-summary' });
				this.route('beneficiary', { path: '/beneficiary/:beneficiaryId' });
				this.route('beneficiaryAllocation', { path: '/beneficiary-allocation' });
				this.route('employeePhysician', { path: '/employee-physician' });
				this.route('dependentPhysician', { path: '/dependent-physician/:dependentId' });
				this.route('employeeDentist', { path: '/employee-dentist' });
				this.route('dependentDentist', { path: '/dependent-dentist/:dependentId' });
			});
			this.route('review', function() {
				this.route('enrollments');
				this.route('lndEnrollments', { path: '/lnd-enrollments' });
				this.route('suppEnrollments', { path: '/supp-enrollments' });
				this.route('waivers');
				this.route('statement');
			});
		});
		this.route('sign');
		this.route('complete');
	});

	// Ben Admin ====================================================================
	this.route('ben-admin', function() {
		this.route('overview');
		this.route('employees');
		this.route('renewals', { path: '/renewals' }, function() {
			this.route('steps', { path: '/' }, function () {
				this.route('index', { path: '/' });
				this.route('carriers-plans');
				this.route('open-enrollment');
				this.route('plan-mapping');
				this.route('eligibility');
				this.route('contributions');
				this.route('review');
				this.route('end-date');
				this.route('invites');
			});
		});
		this.route('enrollment-verification', {path: '/enrollment-verification/:employee_id'}, function() {
			this.route('employee', function(){
				this.route('plan', function () {
					this.route('line', { path: '/:line_of_coverage' });
				});
				this.route('dependents', function () {
					this.route('all-lines');
				});
			});

			this.route('success');
		});

		this.route('manageRenewals', { path: '/manage-renewals' });

		this.route("section125",{ path: "/section125" });
		this.route('spd', function() {
			this.route("/");
			this.route('company');
			this.route('info');
			this.route('review');
			this.route('certificates');
		});

		this.route('plans');
		this.route('setup-and-settings', { path: '/:setup_or_settings' }, function() {
			this.route('intro', { path: '/introduction' }, function() {
				this.route('welcome');
			});
			this.route('lines', function() {
				this.route('select');
			});
			this.route('plans', function() {
				this.route('index', { path: '/' });
				this.route('line', { path: '/:line_of_coverage' });
			});
			this.route('contributions', function(){
				this.route('mdv');
			}),
			this.route('enrollment', function() {
				this.route('mdv');
				this.route('lnd');
				this.route('supplemental');
			});
			this.route('contacts', function() {
				this.route('choose-broker');
				this.route('add-admins');
			});
			this.route('finalize', function() {
				this.route('deductions-push-date');
				this.route('employee-review');
				this.route('agreement');
				this.route('review');
			});
		});
		this.route('timeline');
		this.route('contributions', function(){
			this.route('schemes', { path: '/' });
		}),
		this.route('contributions-review');
		this.route('pending-implementation');
		// dummy routes to mimic benconnect product.
		this.route('company');
		this.route('fulfillments');

	});

	this.route("benadmin-employee", {path: '/benadmin-employee/:employeeId/:benefitsTransactionId'});
	this.route("benadmin-bundle", {path: '/benadmin-bundle/:bundleId'});

	this.route("partner-dashboard", {path: '/partner-dashboard'});

	this.resource('quoting-tool', { path: '/quoting-tool' }, function() {
		this.route('medical', { path: '/medical' });
		this.route('dental', { path: '/dental' });
		this.route('vision', { path: '/vision' });
		this.route('life', { path: '/life' });
		this.route('accidental-death-and-dismemberment', { path: '/accidental-death-and-dismemberment' });
		this.route('long-term-disability', { path: '/long-term-disability' });
		this.route('short-term-disability', { path: '/short-term-disability' });
	});

	// Support ======================================================================
	this.route('support', function() {
		this.route('home');
		this.route('welcome');
		this.route('cat');
		this.route('issues');
		this.route('submit');
		this.route('other-issues');
		this.route('final');
		this.route('ack');
		this.route('open-cases');
	});

	// New Bulk Request ======================================================================
	this.route('bulk-request', function() {
		this.route('overview', function() {
			this.route('detail', {path: '/detail/:bulkRequest_id'});
		});
		this.route('new', function() {
			this.route('confirm');
		});
	});

	// Deductions ===================================================================
	this.route('deductions', {path: '/deductions'}, function() {
		this.route('overview', {path : '/overview' });
		this.route('review', {path: '/review' }, function() {
			this.route('current', {path: '/current' }, function() {
				this.route('details', {path: '/:employee_id' }, function() {
					this.route('single', {path: '/:deduction_type'});
				});
			});
			this.route('contribution', { 'path': '/contribution' }, function() {
				this.route('details', { path: '/:employee_id' }, function() {
					this.route('single', { path: '/:deduction_type' });
				});
			});
		});
		this.route('catchupOnDemand', {path: '/catchup-on-demand'}, function() {
			this.route('catchups', {path : '/catchups' });
		});
		this.route('settings', {path: '/settings'}, function() {
			this.route('mappings', {path : '/mappings' });
		});
		this.route('timeline', {path: '/timeline'});
	});

	// Supplemental app ===================================================================
	this.route('employee-supplemental', {path: '/employee-supplemental'}, function() {
		this.route('acc', {path : '/acc' });
		this.route('cancer', {path : '/cancer' });
		this.route('ci', {path : '/ci' });
		this.route('hi', {path : '/hi' });
	});

	// Additional Benefits app ===================================================================
	this.route('employee-additional-benefits', {path: '/employee-additional-benefits'}, function() {
		this.route('empty', {path : '/empty' });
	});

	// Payroll Connect =============================================================
	this.route('payroll-connect', {path: '/payroll-connect'}, function () {
		this.route('overview', {path: '/overview'});
		this.route('employees', {path: '/employees'}, function() {
			this.route('current', {path: '/current'});
			this.route('past', {path: '/past'});
		});
		this.route('updates', {path: '/updates'}, function () {
			this.route('updates', {path: '/updates'});
			this.route('hours', {path: '/hours'});
			this.route('mismatches', {path: '/mismatches'});
		});
		this.route('reports-and-forms', {path: '/reports-and-forms'});
		this.route('settings', {path: '/settings'}, function () {
			this.route('providerSettings', {path: '/provider-settings'});
			this.route('generalSettings', {path: '/general-settings'});
			this.route('paySchedules', { path: '/pay-schedules'});
			this.route('deductionsMapping', {path: '/deductions-mapping'});
			this.route('paylocity', {path: '/paylocity'});
			this.route('payrollId', {path:  '/payroll-id'});
			this.route('bulkAction', {path:  '/bulk-action'});
			this.route('addDeductions', {path:  '/add-deductions'});
			this.route('workforce', { path: '/workforce' }, function() {
				this.route('coCode', { path: '/co-code' });
				this.route('compensationClass', { path: '/compensation-class'});
				this.route('payClass', { path: '/pay-class' });
				this.route('payrollDepartment', { path: '/payroll-department'});
				this.route('location', { path: '/location' });
				this.route('businessUnit', { path: '/business-unit' });
				this.route('jobTitle', { path: '/job-title' });
				this.route('directDepositDeduction', { path: '/direct-deposit-deduction'}, function() {
					this.route('new', { path: '/new'});
					this.route('edit', { path: '/edit'});
				});
			});
		});
	});

	this.route("talent-intro", {path: '/talent-intro'});

	this.route("compensation-management", {path: '/compensation-management'});

	this.route("compensation-management-v3", {path: '/compensation-management-v3'});

	this.route("peoAuth", { path: '/peo-authorization' });

	// Google Sign up ===============================================================
	this.route('google-signup', { path: '/google-signup' }, function() {
		this.route('preparing-dashboard', { path: '/preparing-dashboard' });
	});

	this.route('redirectToRoute', { path: '/redirect-to-route' });

	this.route('noRouteFound', {path: '*:'});
};

})();
