(function () {

var isCompositeComputed = Ember.computed(function() {
	var compositeRules = this.get('plan.compositeRules');
	var groupSize = this.get('company.employeeCount');

	return !!compositeRules.find(function(rule) {
		var overlaps = rule.get('lowerBoundNum') <= groupSize && groupSize <= rule.get('upperBoundNum');
		var isComposite = rule.get('ratingStyle') == 'composite';

		return overlaps && isComposite;
	});

	/*
	The below property is a huge hack. In-case the rules aren't all loaded
	to begin with, we only want to recompute this when they've finished loading.
	*/
}).property('company.employeeCount', 'plan.compositeRulesAreLoaded');

var attr = DS.attr;

App.CPlan = DS.Model.extend({
	// ATTRIBUTES
	company: DS.belongsTo('App.Company'),
	plan: DS.belongsTo('App.Plan'),
	rxCoPayGenericRetail: attr('string'),
	HSA: attr('boolean'),
	postACA: attr('boolean'),
	state: attr('string'),
	group: attr('string'),
	name: attr('string'),
	availableForEveryone: attr('boolean'),
	numberUnavailable: attr('string'),
	notAvailableDetails: attr('string'),
	networkSize: attr('number'),
	planUrl: attr('string'),
	rxSupplyDaysRetail: attr('number'),
	oopMaxFamily: attr('number'),
	pharmacyCoverage: attr('boolean'),
	rxCoPayBrandRetail: attr('string'),
	planOrder: attr('number'),
	rxCoPayNonFormularyRetail: attr('string'),
	deductibleIndividual: attr('number'),
	deductibleFamily: attr('number'),
	carrier: attr('string'),
	stateCarrier: DS.belongsTo('App.Carrier'),
	specialistCoPay: attr('number'),
	coPay: attr('number'),
	pharmacyDeductible: attr('number'),
	HMOPPO: attr('string'),
	coInsurance: attr('number'),
	outOfNetworkCoInsurance: attr('number'),
	oopMaxIndividual: attr('number'),
	emergencyService: attr('string'),
	avgYouPremium: attr('number'),
	avgYouAndSpousePremium: attr('number'),
	avgYouAndChildPremium: attr('number'),
	avgFamilyPremium: attr('number'),
	totalPremium: attr('number'),
	sortScore: attr('number'),
	estimatedNormalOOP: attr('string'),
	estimatedSevereOOP: attr('string'),
	estimatedNormalOOPIndex: attr('number'),
	estimatedSevereOOPIndex: attr('number'),
	needsPCP: attr('boolean'),
	availableOOS: attr('boolean'),
	isDentalBundled: attr('boolean'),
	isVisionBundled: attr('boolean'),
	rateStyle: attr('string'),
	reasons: attr('string'),
	premiums: attr('raw'),

	// PROPERTIES
	toPadSevere: function() {
		try {
			var normal = this.get('estimatedNormalOOPIndex');
			var serious = this.get('estimatedSevereOOPIndex');
			if (serious - normal < 10) {
				return true;
			}
		}
		catch(e) {// OK to skip
		}

		return false;
	}.property('estimatedNormalOOPIndex', 'estimatedSevereOOPIndex'),
	isSeeChange: Ember.computed.equal('carrier', 'SeeChange'),
	isSeeChangeEnhanced: function() {
		return this.get('carrier') == 'SeeChange' && /Enhanced/.test(this.get('name'));
	}.property('carrier', 'group'),
	isSeeChangeClassic: function() {
		return this.get('carrier') == 'SeeChange' && /Classic/.test(this.get('name'));
	}.property('carrier', 'group'),
	isSeeChangeHSA: function() {
		return this.get('carrier') == 'SeeChange' && /HSA/.test(this.get('name'));
	}.property('carrier', 'group'),
	isSeeChangeDeluxe: function() {
		return this.get('carrier') == 'SeeChange' && /Deluxe/.test(this.get('name'));
	}.property('carrier', 'group'),
	isSeeChangeHRA: function() {
		return this.get('carrier') == 'SeeChange' && this.get('group') == "HRA";
	}.property('carrier', 'group'),
	isBigNote: Ember.computed.gt('numberUnavailable', 5),
	nameLimited: function() {
		var name = this.get('name');
		if (name && name.length > 25) {
			return this.get('name').substring(0, 22) + '...';
		}
		return name;
	}.property('name'),
	nameShort: function() {
		var name = this.get('name');
		if (name && name.length > 20) {
			return this.get('name').substring(0, 17) + '...';
		}
		return name;
	}.property('name'),
	nameSelect: function() {
		var name = this.get('name');
		if (name && name.length > 23) {
			name = this.get('name').substring(0, 22) + '..';
		}
		if (this.get('avgYouPremium')) {
			name = name + " ($" + this.get('avgYouPremium') + ")";
		}
		return name;
	}.property('name'),
	reasonList: function() {
		return this.get('reasons').split(':');
	}.property('reason'),
	genRxPlan: function() {
		var name = this.get('name');
		if (name.indexOf('GenRx') != -1) {
			return true;
		}
		return false;
	}.property('name'),
	isHMO: Ember.computed.equal('HMOPPO', 'HMO'),
	isPPO: Ember.computed.equal('HMOPPO', 'PPO'),
	validCoPay: function() {
		if (this.get('HSA') && !this.get('coPay')) {
			return false;
		}

		return true;
	}.property('HSA', 'coPay'),
	validSpecialistCoPay: function() {
		if (this.get('HSA') && !this.get('specialistCoPay')) {
			return false;
		}

		return true;
	}.property('HSA', 'specialistCoPay'),
	isBlueCrossSelected: function() {
		return (this.get('stateCarrier.displayName').indexOf('Blue Cross') > -1);
	}.property('stateCarrier'),
	isBlueCrossStatewidePlan: function() {
		return this.get('isBlueCrossSelected') && (this.get('group').toLowerCase().indexOf('statewide') > -1);
	}.property('isBlueCrossSelected', 'group'),
	isBlueCrossSelectPlan: function() {
		return this.get('isBlueCrossSelected') && (this.get('group').toLowerCase().indexOf('select') > -1);
	}.property('isBlueCrossSelected', 'group'),
	isCalChoiceSelected: function() {
		return this.get('stateCarrier.displayName') == 'CalChoice';
	}.property('stateCarrier'),
	isCalChoiceBronze: function() {
		return this.get('isCalChoiceSelected') && (this.get('name').toLowerCase().indexOf('bronze') > -1);
	}.property('isCalChoiceSelected', 'name'),
	isCalChoiceSilver: function() {
		return this.get('isCalChoiceSelected') && (this.get('name').toLowerCase().indexOf('silver') > -1);
	}.property('isCalChoiceSelected', 'name'),
	isCalChoiceGold: function() {
		return this.get('isCalChoiceSelected') && (this.get('name').toLowerCase().indexOf('gold') > -1);
	}.property('isCalChoiceSelected', 'name'),
	isCalChoicePlatinum: function() {
		return this.get('isCalChoiceSelected') && (this.get('name').toLowerCase().indexOf('platinum') > -1);
	}.property('isCalChoiceSelected', 'name'),

	isCompositeRated: isCompositeComputed,

	avgDisplayYouPremium: Ember.computed.alias('avgYouPremium'),
	avgDisplayYouAndSpousePremium: Ember.computed.alias('avgYouAndSpousePremium'),
	avgDisplayYouAndChildPremium: Ember.computed.alias('avgYouAndChildPremium'),
	avgDisplayFamilyPremium: function() {
		if (this.get('rateStyle') === 'three election') {
			return this.get('avgYouAndChildPremium');
		}
		return this.get('avgFamilyPremium');
	}.property('rateStyle', 'avgYouAndChildPremium', 'avgFamilyPremium')
});

App.CDentalPlan = DS.Model.extend({
	// ATTRIBUTES
	company: DS.belongsTo('App.Company'),
	plan: DS.belongsTo('App.DentalPlan'),
	HMOPPO: attr('string'),
	carrier: attr('string'),
	stateCarrier: DS.belongsTo('App.Carrier'),
	isSelected: attr('boolean'),
	name: attr('string'),
	coInsuranceBasic: attr('string'),
	coInsuranceMajor: attr('string'),
	deductibleIndividual: attr('number'),
	deductibleFamily: attr('number'),
	freePreventCare: attr('boolean'),
	maxBenefits: attr('number'),
	orthoCoverage: attr('boolean'),
	orthoMaxAge: attr('string'),
	recommended: attr('boolean'),
	planUrl: attr('string'),
	avgYouPremium: attr('number'),
	avgYouAndSpousePremium: attr('number'),
	avgYouAndChildPremium: attr('number'),
	avgFamilyPremium: attr('number'),
	isVisionBundled: attr('boolean'),
	rateStyle: attr('string'),
	totalPremium: attr('number'),
	premiums: attr('raw'),
	availableForEveryone: attr('boolean'),
	// TODO(edward): should be a number, in App.CPlan as well
	numberUnavailable: attr('string'),
	notAvailableDetails: attr('string'),

	// PROPERTIES
	nameLimited: function() {
		var name = this.get('name');
		if (name && name.length > 20) {
			return this.get('name').substring(0, 17) + '...';
		}
		return name;
	}.property('name'),
	isHMO: Ember.computed.equal('HMOPPO', 'HMO'),
	isBlueCrossSelected: function() {
		return (
			(/Blue Cross/.test(this.get('carrier')) && this.get('carrier') != 'Blue Cross RI') ||
			/Regence/.test(this.get('carrier')) ||
			/Lifewise/.test(this.get('carrier')) ||
			/Optima/.test(this.get('carrier')) ||
			/Providence/.test(this.get('carrier')) ||
			/Moda Health/.test(this.get('carrier')) ||
			/Excellus/.test(this.get('carrier')) ||
			/Coventry/.test(this.get('carrier')) ||
			/HealthPartners/.test(this.get('carrier')) ||
			/Highmark/.test(this.get('carrier')) ||
			/Anthem/.test(this.get('carrier')) ||
			/Cigna/.test(this.get('carrier')) ||
			/GHC/.test(this.get('carrier')) ||
			/SelectHealth/.test(this.get('carrier')) ||
			/AmeriHealth/.test(this.get('carrier')) ||
			/PreferredOne/.test(this.get('carrier')) ||
			/Assurant/.test(this.get('carrier')) ||
			/Guidestone/.test(this.get('carrier')) ||
			/Health Services Administrators/.test(this.get('carrier')) ||
			/Starmark/.test(this.get('carrier')) ||
			/Humana/.test(this.get('carrier')) ||
			/Independence/.test(this.get('carrier')) ||
			/Geoblue/.test(this.get('carrier')) ||
			/Priority Health/.test(this.get('carrier')) ||
			/ConnectiCare CT/.test(this.get('carrier')) ||
			/Presbyterian/.test(this.get('carrier')) ||
			(/HealthNet/.test(this.get('carrier')) && this.get('carrier') != 'HealthNet') ||
			/Guardian/.test(this.get('selectedCarrier')) ||
			/EMI Health/.test(this.get('selectedCarrier'))
		);
	}.property('carrier'),
	isHarvardPilgrimSelected: Ember.computed.match('carrier', /Harvard Pilgrim/),
	isUnitedNYSelected: Ember.computed.equal('carrier', 'United NY'),
	isOxfordSelected: Ember.computed.equal('carrier', 'Oxford'),
	isAetnaNYSelected: function() {
		return (
			/Aetna/.test(this.get('carrier')) ||
			/Medical Mutual/.test(this.get('carrier')) ||
			/Tufts Health/.test(this.get('carrier')) ||
			/Neighborhood Health/.test(this.get('carrier')) ||
			/Health Republic/.test(this.get('carrier')) ||
			/HealthPass/.test(this.get('carrier'))
		);
	}.property('carrier'),
	isEmblemSelected: Ember.computed.equal('carrier', 'Emblem Health'),
	isBlueShieldSelected: Ember.computed.equal('carrier', 'Blue Shield'),
	isUnitedSelected: function() {
		return (
			(/United/.test(this.get('carrier')) && this.get('carrier') != 'United NY') ||
			/UHC River Valley/.test(this.get('carrier')) ||
			/NHP/.test(this.get('carrier'))
		);
	}.property('carrier'),
	isHealthNetSelected: Ember.computed.equal('carrier', 'HealthNet'),
	isKaiserSelected: Ember.computed.match('carrier', /Kaiser Permanente/),
	isDeltaDentalSelected: Ember.computed.equal('carrier', 'Delta Dental'),
	isChoiceBuilderSelected: Ember.computed.equal('carrier', 'Choice Builder'),
	doesSupportFixed: function() {
		return this.get('isBlueCrossSelected') || /Guardian/.test(this.get('carrier'));
	}.property('carrier', 'isBlueCrossSelected'),

	isCompositeRated: isCompositeComputed,

	avgDisplayYouPremium: Ember.computed.alias('avgYouPremium'),
	avgDisplayYouAndSpousePremium: Ember.computed.alias('avgYouAndSpousePremium'),
	avgDisplayYouAndChildPremium: Ember.computed.alias('avgYouAndChildPremium'),
	avgDisplayFamilyPremium: function() {
		if (this.get('rateStyle') === 'three election') {
			return this.get('avgYouAndChildPremium');
		}
		return this.get('avgFamilyPremium');
	}.property('rateStyle', 'avgYouAndChildPremium', 'avgFamilyPremium')
});

App.CVisionPlan = DS.Model.extend({
	company: DS.belongsTo('App.Company'),
	plan: DS.belongsTo('App.VisionPlan'),
	carrier: attr('string'),
	stateCarrier: DS.belongsTo('App.Carrier'),
	isSelected: attr('boolean'),
	name: attr('string'),
	coPay: attr('number'),
	exams: attr('string'),
	contacts: attr('string'),
	frames: attr('string'),
	lasik: attr('boolean'),
	lenses: attr('string'),
	minContribution: attr('number'),
	planUrl: attr('string'),
	rateStyle: attr('string'),
	avgYouPremium: attr('number'),
	avgYouAndSpousePremium: attr('number'),
	avgYouAndChildPremium: attr('number'),
	avgFamilyPremium: attr('number'),
	recommended: attr('boolean'),
	totalPremium: attr('number'),
	premiums: attr('raw'),
	availableForEveryone: attr('boolean'),
	// TODO(edward): should be a number, in App.CPlan as well
	numberUnavailable: attr('string'),
	notAvailableDetails: attr('string'),
	nameLimited: function() {
		var name = this.get('name');
		name = name ? name.replace('Blue View', '') : name;
		if (name && name.length > 20) {
			return this.get('name').substring(0,19) + '..';
		}
		return name;
	}.property('name'),
	isBlueCrossSelected: function() {
		return (
			(/Blue Cross/.test(this.get('carrier')) && this.get('carrier') != 'Blue Cross RI') ||
			/Regence/.test(this.get('carrier')) ||
			/Lifewise/.test(this.get('carrier')) ||
			/Optima/.test(this.get('carrier')) ||
			/Providence/.test(this.get('carrier')) ||
			/Moda Health/.test(this.get('carrier')) ||
			/Excellus/.test(this.get('carrier')) ||
			/Coventry/.test(this.get('carrier')) ||
			/HealthPartners/.test(this.get('carrier')) ||
			/Highmark/.test(this.get('carrier')) ||
			/Anthem/.test(this.get('carrier')) ||
			/Cigna/.test(this.get('carrier')) ||
			/GHC/.test(this.get('carrier')) ||
			/SelectHealth/.test(this.get('carrier')) ||
			/AmeriHealth/.test(this.get('carrier')) ||
			/PreferredOne/.test(this.get('carrier')) ||
			/Assurant/.test(this.get('carrier')) ||
			/Guidestone/.test(this.get('carrier')) ||
			/Health Services Administrators/.test(this.get('carrier')) ||
			/Starmark/.test(this.get('carrier')) ||
			/Humana/.test(this.get('carrier')) ||
			/Independence/.test(this.get('carrier')) ||
			/Geoblue/.test(this.get('carrier')) ||
			/Priority Health/.test(this.get('carrier')) ||
			/ConnectiCare CT/.test(this.get('carrier')) ||
			/Presbyterian/.test(this.get('carrier')) ||
			(/HealthNet/.test(this.get('carrier')) && this.get('carrier') != 'HealthNet') ||
			/Guardian/.test(this.get('selectedCarrier')) ||
			/EMI Health/.test(this.get('selectedCarrier'))
		);
	}.property('carrier'),
	isHarvardPilgrimSelected: Ember.computed.match('carrier', /Harvard Pilgrim/),
	isUnitedNYSelected: Ember.computed.equal('carrier', 'United NY'),
	isOxfordSelected: Ember.computed.equal('carrier', 'Oxford'),
	isAetnaNYSelected: function() {
		return (
			/Aetna/.test(this.get('carrier')) ||
			/Medical Mutual/.test(this.get('carrier')) ||
			/Tufts Health/.test(this.get('carrier')) ||
			/Neighborhood Health/.test(this.get('carrier')) ||
			/Health Republic/.test(this.get('carrier')) ||
			/HealthPass/.test(this.get('carrier'))
		);
	}.property('carrier'),
	isEmblemSelected: Ember.computed.equal('carrier', 'Emblem Health'),
	isBlueShieldSelected: Ember.computed.equal('carrier', 'Blue Shield'),
	isUnitedSelected: function() {
		return (
			(/United/.test(this.get('carrier')) && this.get('carrier') != 'United NY') ||
			/UHC River Valley/.test(this.get('carrier')) ||
			/NHP/.test(this.get('carrier'))
		);
	}.property('carrier'),
	isHealthNetSelected: Ember.computed.equal('carrier', 'HealthNet'),
	isKaiserSelected: Ember.computed.match('carrier', /Kaiser Permanente/),
	isDeltaSelected: Ember.computed.equal('carrier', 'Delta Dental'),
	isVSPSelected: Ember.computed.equal('stateCarrier.displayName', 'VSP'),
	doesSupportFixed: function() {
		return this.get('isBlueCrossSelected') || /Guardian/.test(this.get('carrier'));
	}.property('carrier', 'isBlueCrossSelected'),

	isCompositeRated: isCompositeComputed,

	avgDisplayYouPremium: Ember.computed.alias('avgYouPremium'),
	avgDisplayYouAndSpousePremium: Ember.computed.alias('avgYouAndSpousePremium'),
	avgDisplayYouAndChildPremium: Ember.computed.alias('avgYouAndChildPremium'),
	avgDisplayFamilyPremium: function() {
		if (this.get('rateStyle') === 'three election') {
			return this.get('avgYouAndChildPremium');
		}
		return this.get('avgFamilyPremium');
	}.property('rateStyle', 'avgYouAndChildPremium', 'avgFamilyPremium')
});

App.CompanyRate = DS.Model.extend({
	// ATTRIBUTES
	company: DS.belongsTo('App.Company'),
	plan: DS.belongsTo('App.Plan'),
	companyHealthEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
	region: attr('string'),
	ageMin: attr('number'),
	ageMax: attr('number'),
	youPremium: attr('number'),
	youAndSpousePremium: attr('number'),
	youAndChildPremium: attr('number'),
	familyPremium: attr('number'),

	ageRange: Ember.computed(function() {
		return this.get('ageMin') + ' ' + this.get('ageMax');
	}).property('ageMin', 'ageMax'),
});

App.CompanyDentalRate = DS.Model.extend({
	// ATTRIBUTES
	company: DS.belongsTo('App.Company'),
	plan: DS.belongsTo('App.DentalPlan'),
	companyHealthEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
	region: attr('string'),
	ageMin: attr('number'),
	ageMax: attr('number'),
	youPremium: attr('number'),
	youAndSpousePremium: attr('number'),
	youAndChildPremium: attr('number'),
	familyPremium: attr('number'),

	ageRange: Ember.computed(function() {
		return this.get('ageMin') + ' ' + this.get('ageMax');
	}).property('ageMin', 'ageMax'),
});

App.CompanyVisionRate = DS.Model.extend({
	// ATTRIBUTES
	company: DS.belongsTo('App.Company'),
	plan: DS.belongsTo('App.VisionPlan'),
	companyHealthEnrollment: DS.belongsTo('App.CompanyHealthEnrollment'),
	region: attr('string'),
	ageMin: attr('number'),
	ageMax: attr('number'),
	youPremium: attr('number'),
	youAndSpousePremium: attr('number'),
	youAndChildPremium: attr('number'),
	familyPremium: attr('number'),

	ageRange: Ember.computed(function() {
		return this.get('ageMin') + ' ' + this.get('ageMax');
	}).property('ageMin', 'ageMax'),
});


App.Section = DS.Model.extend({
	name: attr('string'),
	path: attr('string'),
	isCompleted: attr('boolean'),
	subsections: DS.hasMany("App.Subsection"),
	ordering: attr('number'),

	orderedSubsections: Ember.computed(function() {
		var subsections = this.get('subsections');

		if (!subsections) {
			return [];
		}

		return subsections.toArray().sort(function(a, b) {
			return a.get('ordering') - b.get('ordering');
		});
	}).property('subsections.[]'),
});

App.Subsection = DS.Model.extend({
	name: attr('string'),
	path: attr('string'),
	isCompleted: attr('boolean'),
	attributes: attr('string'),
	section: DS.belongsTo('App.Section'),
	ordering: attr('number'),

	attrList: Ember.computed.split('attributes', ', '),
});

App.CompanyInfo = DS.Model.extend({
	objId: attr('number'),
	company: DS.belongsTo('App.Company'),
	name: attr('string'),
	dbaName: attr('string'),
	website: attr('string'),
	physicalAddressStreet1: attr('string'),
	physicalAddressStreet2: attr('string'),
	physicalAddressCity: attr('string'),
	physicalAddressState: attr('string'),
	physicalAddressCounty: attr('string'),
	physicalAddressZip: attr('string'),
	isBillingSame: attr('boolean'),
	billingAddressStreet1: attr('string'),
	billingAddressStreet2: attr('string'),
	billingAddressCity: attr('string'),
	billingAddressState: attr('string'),
	billingAddressZip: attr('string'),
	entityType: attr('string'),
	ein: attr('string'),
	sicCode: attr('string'),
	bankName: attr('string'),
	bankAccountNumber: attr('string'),
	bankAccountNumberEnc: attr('string'),
	bankRoutingNumber: attr('string'),
	bankRoutingNumberEnc: attr('string'),
	establishedMonth: attr('number'),
	establishedYear: attr('number'),
	businessDescription: attr('string'),
	industryType: attr('string'),
	fullTimeEmployeeCount: attr('number'),
	partTimeEmployeeCount: attr('number'),
	medicareEmployeeCount: attr('number'),
	outOfStateEmployeeCount: attr('number'),
	numberOfLocations: attr('number'),
	fifteenAllowed: attr('boolean'),
	numUnionMember: attr('number'),
	numPreviousAverageEmployees: attr('number'),
	entityTypeForTaxPurposes: attr('string'),
	entityChangedRecently: attr('boolean'),
	entityChangedOnPayrollQWTR: attr('boolean'),
	isQuarterlyWageTaxAvailable: attr('boolean'),
	bizDescLen: function() {
		var desc = this.get('businessDescription');
		return desc ? desc.length : 0;
	}.property('businessDescription'),
	industryTypeLen: function() {
		var desc = this.get('industryType');
		return desc ? desc.length : 0;
	}.property('industryType'),
	inStateEmployeeCount: function() {
		var total =
			this.get('fullTimeEmployeeCount') + this.get('partTimeEmployeeCount');

		var outOfState = this.get('outOfStateEmployeeCount');
		return total - outOfState;
	}.property('fullTimeEmployeeCount', 'partTimeEmployeeCount', 'outOfStateEmployeeCount'),


	anyEntityType: Ember.computed.any('company.type', 'entityType'),

	isLlc: Ember.computed.equal('anyEntityType', 'LC'),
	llcCleaner: (function() {
		if (!this.get('isLlc')) {
			this.set('entityTypeForTaxPurposes', null);
		}
	}).observes('anyEntityType'),

	isLlcCCorp: Ember.computed.equal('entityTypeForTaxPurposes', 'CC'),
	isLlcSCorp: Ember.computed.equal('entityTypeForTaxPurposes', 'SC'),
	isLlcSoleProp: Ember.computed.equal('entityTypeForTaxPurposes', 'SP'),
	isLlcPartnership: Ember.computed.equal('entityTypeForTaxPurposes', 'PA'),

	isCCorp: Ember.computed.equal('anyEntityType', 'CO'),
	isSCorp: Ember.computed.equal('anyEntityType', 'SC'),
	isSoleProp: Ember.computed.equal('anyEntityType', 'SP'),
	isPartnership: Ember.computed.equal('anyEntityType', 'PA'),

	isCCorpForTax: Ember.computed.or('isLlcCCorp', 'isCCorp'),
	isSCorpForTax: Ember.computed.or('isLlcSCorp', 'isSCorp'),
	isSolePropForTax: Ember.computed.or('isLlcSoleProp', 'isSoleProp'),
	isPartnershipForTax: Ember.computed.or('isLlcPartnership', 'isPartnership'),

	// Shared between all objects of the same type. DO NOT MUTATE.
	taxEntityTypeOptions: [
		Ember.Object.create({name: "Select", id: ""}),
		Ember.Object.create({name: "C-Corporation", id: "CC"}),
		Ember.Object.create({name: "S-Corporation", id: "SC"}),
		Ember.Object.create({name: "Partnership", id: "PA"}),
		Ember.Object.create({name: "Sole Proprietorship", id: "SP"}),
	],
	establishedMonthYear: function() {
		var month = this.get('establishedMonth');
		var year = this.get('establishedYear');
		if (month && year) {
			return moment([year, month - 1]).format('MMMM, YYYY');
		}
	}.property('establishedMonth', 'establishedYear'),
});

App.CompanyPolicy = DS.Model.extend({
	effectiveDate: attr('string'),
	leaveOfAbsenceLengthMedical: attr('string'),
	leaveOfAbsenceLengthPersonal: attr('string'),
	havePayrollHistory: attr('string'),
	startingPayrollConcurrently: attr('string'),
	allFounders: attr('string'),
	startupSignature: attr('string'),
	contributionType: attr('string'),
	basePlan: attr('string'),
	contributionEmployee: attr('string'),
	contributionDependents: attr('string'),
	dentalContributionType: attr('string'),
	dentalContributionEmployee: attr('string'),
	dentalContributionDependents: attr('string'),
	visionContributionType: attr('string'),
	visionContributionEmployee: attr('string'),
	visionContributionDependents: attr('string'),
	isFixed: Ember.computed.equal('contributionType', 'F'),
	doesHavePayrollHistory: Ember.computed.equal('havePayrollHistory', 'yes'),
	doesNotHavePayrollHistory: Ember.computed.equal('havePayrollHistory', 'no'),
	isStartingPayrollConcurrently: Ember.computed.equal('startingPayrollConcurrently', 'yes'),
	isNotStartingPayrollConcurrently: Ember.computed.equal('startingPayrollConcurrently', 'no'),
	areAllFounders: Ember.computed.equal('allFounders', 'yes'),
	areAllNotFounders: Ember.computed.equal('allFounders', 'no'),
	isDentalFixed: Ember.computed.equal('dentalContributionType', 'F'),
	isVisionFixed: Ember.computed.equal('visionContributionType', 'F'),
	isPercentOfPlan: Ember.computed.equal('contributionType', 'B'),
	spanningContribution: function() {
		return this.get('contributionType') == 'O';
	}.property('contributionType'),

});

App.AdminContact = DS.Model.extend({
	executiveName: attr('string'),
	executiveTitle: attr('string'),
	executiveEmail: attr('string'),
	executivePhone: attr('string'),
	isExecutiveSameAsBilling: attr('boolean'),
	billingName: attr('string'),
	billingTitle: attr('string'),
	billingEmail: attr('string'),
	billingPhone: attr('string'),
	status: attr('string')
});

App.UnderwritingInfo = DS.Model.extend({
	haveSubsidiaries: attr('string'),
	isSubsidiaryAffiliated: attr('string'),
	affiliationName: attr('string'),
	affiliationNumberOfEmployees: attr('string'),
	isClientPeo: attr('string'),
	priorDentalCarrier: attr('string'),
	havePriorDentalCoverage: attr('string'),
	numberOfEmployeesDecliningCoverage: attr('string'),
	allFullTimeEmployeesCovered: attr('string'),
	noExplanation: attr('string'),
	haveWorkersComp: attr('string'),
	workersCompCarrier: attr('string'),
	anyOwnerNotCovered: attr('string'),
	namesNotCovered: attr('string'),
	allOwners: attr('string'),
	workersCompEffectiveDate: attr('string'),
	workersCompRenewalDate: attr('string'),
	isSubjectToERISA: attr('string'),
	otherMedicalCoverage: attr('string'),
	anyoneStdLtd: attr('string'),
	numberOfCobraPeople: attr('string'),
	numberOfCalCobraPeople: attr('string'),
	numberOfCobraElectionPeople: attr('string'),
	numberOfEmployeesOnFamilyMedicalLeave: attr('string'),
	isAnyOwnerNotCovered: Ember.computed.equal('anyOwnerNotCovered', 'yes'),
	hasSubsidiaryAffiliated: Ember.computed.equal('isSubsidiaryAffiliated', 'yes'),
	doesHavePriorCoverage: Ember.computed.equal('havePriorCoverage', 'yes'),
	doesHavePriorDentalCoverage: Ember.computed.equal('havePriorDentalCoverage', 'yes'),
	doesHaveCurrentDentalCoverage: Ember.computed.equal('haveCurrentDentalCoverage', 'yes'),
	doesHaveCurrentVisionCoverage: Ember.computed.equal('haveCurrentVisionCoverage', 'yes'),
	doesHavePriorCoverageAtCarrier: Ember.computed.equal('previousCoverageAtCarrier', 'yes'),
	doesHaveWorkersComp: Ember.computed.equal('haveWorkersComp', 'yes'),
	doesNotHaveWorkersComp: Ember.computed.equal('haveWorkersComp', 'no'),
	notAllOwners: Ember.computed.equal('allOwners', 'no'),
	hasFiledBankruptcy: attr('boolean'),
	workersCompPolicyNumber: attr('string'),
	anyWorkersCompEmployee: attr('boolean'),
	isPrincipalExecutiveOfficeInCA: attr('boolean'),

	// Attributes for previous coverage

	previousCoverageAtCarrier: attr('string'),
	carrierName: attr('string'),
	coverageBeginDate: attr('string'),
	coverageTerminatedDate: attr('string'),
	groupID: attr('string'),
	terminatedNonPayment: attr('string'),

	medicalPreviousCoverageAtCarrier: Ember.computed.alias('previousCoverageAtCarrier'),
	medicalCarrierName: Ember.computed.alias('carrierName'),
	medicalCoverageBeginDate: Ember.computed.alias('coverageBeginDate'),
	medicalCoverageTerminatedDate: Ember.computed.alias('coverageTerminatedDate'),
	medicalGroupID: Ember.computed.alias('groupID'),
	medicalTerminatedNonPayment: Ember.computed.alias('terminatedNonPayment'),

	dentalPreviousCoverageAtCarrier: attr('string'),
	dentalCarrierName: attr('string'),
	dentalCoverageBeginDate: attr('string'),
	dentalCoverageTerminatedDate: attr('string'),
	dentalGroupID: attr('string'),
	dentalTerminatedNonPayment: attr('string'),

	visionPreviousCoverageAtCarrier: attr('string'),
	visionCarrierName: attr('string'),
	visionCoverageBeginDate: attr('string'),
	visionCoverageTerminatedDate: attr('string'),
	visionGroupID: attr('string'),
	visionTerminatedNonPayment: attr('string'),

	// Attributes for current coverage

	carrier: attr('string'),
	startDate: attr('string'),
	endDate: attr('string'),
	employeesEnrolledCount: attr('string'),
	coverageHMOPPOType: attr('string'),
	isThisTotalReplacement: attr('string'),
	havePriorCoverage: attr('string'),

	currentMedicalCarrier: Ember.computed.alias('carrier'),
	currentMedicalStartDate: Ember.computed.alias('startDate'),
	currentMedicalEndDate: Ember.computed.alias('endDate'),
	medicalEmployeesEnrolledCount: Ember.computed.alias('employeesEnrolledCount'),
	medicalCoverageHMOPPOType: Ember.computed.alias('coverageHMOPPOType'),
	haveCurrentMedicalCoverage: Ember.computed.alias('havePriorCoverage'),
	isThisTotalMedicalReplacement: Ember.computed.alias('isThisTotalReplacement'),

	currentDentalCarrier: attr('string'),
	currentDentalStartDate: attr('string'),
	currentDentalEndDate: attr('string'),
	dentalEmployeesEnrolledCount: attr('string'),
	dentalCoverageHMOPPOType: attr('string'),
	haveCurrentDentalCoverage: attr('string'),
	isThisTotalDentalReplacement: attr('string'),

	currentVisionCarrier: attr('string'),
	currentVisionStartDate: attr('string'),
	currentVisionEndDate: attr('string'),
	visionEmployeesEnrolledCount: attr('string'),
	visionCoverageHMOPPOType: attr('string'),
	haveCurrentVisionCoverage: attr('string'),
	isThisTotalVisionReplacement: attr('string'),

	employeesWorkingThirtyHoursEligibleFulltime: attr('boolean'),
	hoursToBeConsideredFulltime: attr('string'),

	doEarningsDifferByGroupsClassesDivisions: attr('boolean'),
	earningDifferenceReason: attr('string'),

	hasEmployeesInStateWithDisability: attr('boolean'),
	areEmployeesInStateWithDisabilityCoveredWithPlan : attr('boolean'),
	areEmployeesInStateWithDisabilityCoveredWithStatutory: attr('boolean'),

	hasSignificantHealthRiskEmployee: attr('boolean'),

	willOwnersBeCoveredLDPlans: attr('boolean'),
	howWillIncomeBeReportedToIRSForLDPlans: attr('string'),

	hasCoveredByFICAIndividual: attr('boolean'),
});


App.SignificantHealthRiskEmployee = DS.Model.extend({
	company: DS.belongsTo('App.Company'),
	name: attr('string'),
	healthRiskDescription: attr('string'),
});


App.CoveredByFICAIndividual = DS.Model.extend({
	company: DS.belongsTo('App.Company'),
	name: attr('string'),
	exemptionReason: attr('string'),
	optingOutOf: attr('string'),
});


App.OwnerOnLifeAndDisability = DS.Model.extend({
	company: DS.belongsTo('App.Company'),
	name: attr('string'),
	priorCalendarYearEarning: attr('string'),
});

App.Ownership = DS.Model.extend({
	company: DS.belongsTo('App.Company'),
	name: attr('string'),
	percentage: attr('string'),
	eligibility: attr('string'),
	isPrimary: attr('boolean'),
	isCoveredByWorkerComp: attr('boolean'),
	formattedPercentage: function() {
		var value = this.get('percentage');
		if (Ember.isEmpty(value)) { return "N/A"; }
		return Number(value).toFixed(2) + '%';
	}.property('percentage')
});

App.OwnershipInformation = DS.Model.extend({
	company: DS.belongsTo('App.Company'),
	areAllOwnersOnQWTR: attr('boolean'),
	anyEmployeeOtherThanOwnerCouple: attr('boolean')
});

App.AbsentEmployee = DS.Model.extend({
	company: DS.belongsTo('App.Company'),
	first_name: attr('string'),
	last_name: attr('string'),
	absenceReason: attr('string'),
	absentSinceDate: DS.attr('string'),
	expectedReturnDate: DS.attr('string'),
	// currently this is for Principal
	previousInactive: DS.attr('boolean'),
});

App.CobraPerson = DS.Model.extend({
	name: attr('string'),
	ssn: attr('string'),
	dob: attr('string'),
	cobraType: attr('string'),
	eventDescription: attr('string'),
	date: attr('string')
});

App.LeavePerson = DS.Model.extend({
	name: attr('string'),
	ssn: attr('string'),
	startDate: attr('string'),
	willReturn: attr('boolean'),
	isPresentlyDisabled: attr('boolean'),
	disablingCondition: attr('string'),
	willExerciseCobra: attr('boolean')
});

App.MedicalDetails = DS.Model.extend({
	anyEmployeesDisabledOrHospitalized: attr('string'),
	anyDependentsDisabledOrHospitalized: attr('string'),
	priorCarrierExtension: attr('string'),
	// For "unableToWorkInjuries", we consider all employees who are not actively working (even for reasons other than health)
	// The displayName copy reads "Are any employees currently not actively working?"
	unableToWorkInjuries: attr('string'),
	unableToWorkInjuriesNames: attr('string'),
	unableToWorkInjuriesDates: attr('string'),
	unableToPerformNormalDuties: attr('string'),
	dependentChildrenPhysicalMental: attr('string'),
	anyCurrentToldHospitalized: attr('string'),
	anySeriousTreatment: attr('string'),
	thirtyDaysMissedWork: attr('string'),
	expensiveExpensesLastTwoYears: attr('string'),
	anyAlcoholism: attr('string'),
	anyPregnant: attr('string'),
	howManyPregnant: attr('number'),
	dueDates: attr('string'),
	notEmployeesForWC: attr('string'),
	namesNotWC: attr('string'),
	titlesNotWC: attr('string'),
	notSubjectToW2: attr('string'),
	yesNoW2Explanation: attr('string'),
	expensiveExpensesAnyLastTwoYears: attr('string'),
	expensiveExpensesAnyLastTwoYearsNames: attr('string'),
	anyPregnanciesOrHospitalization: attr('string'),
	anyPregnanciesOrHospitalizationNames: attr('string'),
	anyEverSeriousTreatment: attr('string'),
	anyEverSeriousTreatmentNames: attr('string'),
	anyAIDs: attr('string'),
	anyAIDsNames: attr('string'),
	anyDependentDisabled: Ember.computed.equal('anyDependentsDisabledOrHospitalized', 'yes'),
});

App.ZenHistory = DS.Model.extend({
	object_id: attr('number'),
	modelName: attr('string'),
	className: attr('string'),
	unicode: attr('string'),
	changes: attr('raw'),
	lineOfCoverage: attr('string'),
	isActive: attr('raw'),
	additionalDetails: attr('raw'),
});

})();
