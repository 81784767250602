(function () {

var attr = DS.attr;

App.Address = DS.Model.extend({
	version_id: attr('number'),
	street1: attr('string'),
	street2: attr('string'),
	city: attr('string'),
	state: attr('string'),
	country: attr('string'),
	postalCode: attr('string'),
	phone: attr('string'),
	fax: attr('string'),
	location: DS.belongsTo('App.CompanyLocation'),
	employee: DS.belongsTo('App.AllEmployee'),
	associatedEmployee: DS.belongsTo('App.AllEmployee'),
});


App.CompanyFederalTax = DS.Model.extend({
	company: DS.belongsTo('App.Company'),
	country: attr('string'),
	isActive: attr('boolean'),
	businessLegalName: attr('string'),
	businessDescription: attr('string'),
	federalTaxID: attr('string'),
	tosAgreement: attr('boolean'),
});

App.CompanyBank = DS.Model.extend({
	version_id: attr('number'),
	company: DS.belongsTo('App.Company'),
	isActive: attr('boolean'),
	bankAccountNumber: attr('string'),
	bankAccountType: attr('string'),
	bankName: attr('string'),
	bankBranchName: attr('string'),
	bankRoutingNumber: attr('string'),
	bankSwiftCode: attr('string'),
	additionalRoutingNumber: attr('string'),
	country: attr('string'),
	bankAddress: DS.belongsTo('App.Address'),
	isPrimaryAccount: attr('boolean'),
	bankAccountVerification_id: DS.attr('number'),
	isVerified: attr('boolean'),
	isVerificationWaiting: attr('boolean'),
	hasVerificationFailed: attr('boolean'),
	getFullBankAccountType: function() {
		switch (this.get('bankAccountType')) {
			case 'C':
				return 'Checking';
			case 'S':
				return 'Savings';
			default:
				return null;
		}
	}.property('bankAccountType'),
	productType: attr('string'),
});

App.EmployeeBank = DS.Model.extend({
	version_id: attr('number'),
	employee: DS.belongsTo('App.AllEmployee'),
	isActive: attr('boolean'),
	isSalaryAccount: attr('boolean'),
	bankAccountName: attr('string'),
	bankAccountNumber: attr('string'),
	bankAccountType: attr('string'),
	bankName: attr('string'),
	bankBranchName: attr('string'),
	bankRoutingNumber: attr('string'),
	bankSwiftCode: attr('string'),
	additionalRoutingNumber: attr('string'),
	country: attr('string'),
	priority: DS.attr('number'),
	bankAddress: DS.belongsTo('App.Address'),
	bankAccountPhoneNumber: attr('string'),
	bankAccountTypeHuman: function() {
		var bankAccountType = this.get('bankAccountType');
		if (bankAccountType == 'C') {
			return "Checking";
		} else if (bankAccountType == 'S') {
			return "Savings";
		} else if (bankAccountType == 'B') {
			return "Business";
		}
		return bankAccountType;
	}.property('bankAccountType'),
	accountNickname: attr('string'),
	bankIndex: function() {
		var bankList = this.get('employee.unorderedBanks');
		return bankList.indexOf(this) + 1;
	}.property('employee.unorderedBanks'),
	accountHead: function() {
		return "Direct Deposit Account #" + this.get('bankIndex');
	}.property('bankIndex'),
	isPrimaryAccount: attr('boolean'),
	amountPerPaycheck: attr('number'),
	newBankAccountNumber: attr('string'),
	newBankAccountType: attr('string'),
	newBankRoutingNumber: attr('string'),
	newAccountNickname: attr('string'),
	isEditingBank: attr('boolean'),
	isRemovingBank: attr('boolean'),
	isNewAccount: attr('boolean'),
	isConfirmingBankAccountNumber: attr('boolean'),
	percentagePerPaycheck: attr('number'),
	bankAccountNumberMasked: function() {
		var result = "";
		var len = this.get('bankAccountNumber.length');
		for(var i=0; i<len-4; i++) {
			result += "*";
		}
		if(len>4) {
			result += this.get('bankAccountNumber').substring(len-4);
		}
		else {
			result = "****";
		}
		return result;
	}.property('bankAccountNumber.length', 'bankAccountNumber'),
	hasIncompleteFields: attr('boolean'),
	swiftCodeUrl: function() {
		return '/sensitive_data_image/' + this.get('employee.id') + '/run?type=bankSwiftCode&bankID=' +
		this.get('id') + '&version=v2';
	}.property('employee.id', 'id'),
	additionalRoutingNumberUrl: function() {
		return '/sensitive_data_image/' + this.get('employee.id') + '/run?type=additionalRoutingNumber&bankID=' +
		this.get('id') + '&version=v2';
	}.property('employee.id', 'id'),
	routingNumberUrl: function() {
		return '/sensitive_data_image/' + this.get('employee.id') + '/run?type=bankRoutingNumber&bankID=' +
		this.get('id') + '&version=v2';
	}.property('employee.id', 'id'),
	accountNumberUrl: function() {
		return '/sensitive_data_image/' + this.get('employee.id') + '/run?type=bankAccountNumber&bankID=' +
		this.get('id') + '&version=v2';
	}.property('employee.id', 'id'),
	isBankInfoComplete: function() {
		return this.get('bankAccountNumber') &&
			(this.get('bankRoutingNumber') || this.get('additionalRoutingNumber') || this.get('bankSwiftCode')) &&
			this.get('bankAccountType');
	}.property('bankAccountNumber',
		'bankRoutingNumber',
		'additionalRoutingNumber',
		'bankSwiftCode',
		'bankAccountType'),
	bankDetailDisplay: function() {
		return this.get('accountNickname') || this.get('bankAccountNumber');
	}.property('accountNickname', 'bankAccountNumber'),
});

App.EmployeeEmployment = DS.Model.extend({
	version_id: attr('number'),
	employee: DS.belongsTo('App.AllEmployee'),
	isActive: attr('boolean'),
	country: attr('string'),
	fixedTermEmployment: attr('boolean'),
	employmentType: attr('string'),
	employmentSubType: attr('string'),
	isFulltime: Ember.computed.equal('employmentType', 'FT'),
	isParttime: Ember.computed.equal('employmentType', 'PT'),
	isTP: Ember.computed.equal('employmentType', 'TP'),
	isIN: Ember.computed.equal('employmentType', 'IN'),
	isTemp: Ember.computed.or('isTP', 'isIN'),
	workingHoursPerWeek: attr('number'),
	workingDaysPerWeek: attr('number'),
	hireDate: attr('string'),
	hireDateFormatted: Ember.computed.prettyDate('hireDate'),
	fullTimeStartDate: attr('string'),
	fullTimeEndDate: attr('string'),
	terminationDate: attr('string'),
	terminationCategory: attr('string'),
	terminationType: attr('string'),
	title: attr('string'),
	compType: attr('string'),
	isHourly: Ember.computed.equal('compType', 'H'),
	isSalary: Ember.computed.equal('compType', 'S'),
	currency: attr('string'),
	payRate: attr('number'),
	payRateInUSD: attr('number'),
	annualSalary: attr('number'),
	annualSalaryInUSD: attr('number'),
	firstSalaryDate: attr('string'),
	payFrequency: attr('string'),
	overtimeEligibility: attr('raw'),
	annualSalaryIncludesBonus: attr('raw'),
	monthlySalary: attr('number'),
	monthlySalaryInUSD: attr('number'),
	annualSalaryCommas: Ember.computed.commas('annualSalary'),
	monthlySalaryCommas: Ember.computed.commas('monthlySalary'),
	payRateCommas: Ember.computed.commas('payRate'),
	isFlsaExempt: attr('raw'),
	isFlsaExemptSystemComputed: attr('boolean'),
	hasNonExemptJobDuties: attr('raw'),
	previousEmployment: DS.belongsTo('App.EmployeeEmployment'),
	isRehire: attr('boolean'),
	terminationActions: DS.hasMany('App.EmployeeTerminationAction'),
	endDate: attr('string'),
	hasZeroSalaryReason: Ember.computed.notEmpty('zeroSalaryReason'),
	zeroSalaryReason: attr('string'),
	zeroSalaryReasonForOtherType: attr('string'),
	employeeNumber: attr('number'),
	employmentTypeFormatted: Ember.computed('employmentType', function(){
		const formatted = {
			'FT': 'Full Time',
			'PT': 'Part Time',
			'TP': 'Temp / Intern',
		}
		return formatted[this.get('employmentType')];
	}),
	compTypeHuman: function() {
		var compType = this.get('compType');
		if (compType == 'H') {
			return 'Hourly';
		} else if (compType == 'S') {
			return 'Salaried';
		}
		return compType;
	}.property('compType'),
	zeroSalaryReasonFormatted: Ember.computed('zeroSalaryReason', 'zeroSalaryReasonForOtherType', function() {
		const formatted = {
			'owner': 'Owner',
			'partner': 'Partner',
			'officer': 'Officer',
			'other': 'Other',
		};
		const zeroSalaryReason = this.get('zeroSalaryReason');
		const reasonForOtherType = this.get('zeroSalaryReasonForOtherType');

		if (zeroSalaryReason === 'other' && reasonForOtherType){
			return Ember.String.fmt("%@ - %@", [formatted[zeroSalaryReason], reasonForOtherType]);
		}
		return zeroSalaryReason? formatted[zeroSalaryReason]: '';
	}),
	currencyHasDollarSign: function() {
		const currency = this.get('currency');
		const currencyList = ['AUD', 'BBD', 'BMD', 'BND', 'BSD', 'BZD', 'CAD', 'FJD', 'GYD', 'HKD', 'JMD', 'KYD', 'LRD', 'MXN', 'NAD', 'NZD', 'SBD', 'SGD', 'SRD', 'TTD', 'USD', 'XCD', 'ZWL'];
		return currencyList.includes(currency);
	}.property('currency'),
	qeCreated: attr('boolean'),
	isSeasonal: attr('boolean'),
});

App.EmployeeEligibility = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	newHire: DS.belongsTo('App.NewHire'),
	isActive: attr('boolean'),
	country: attr('string'),
	legalStatus: attr('string'),
	nationality: attr('string'),
	nationalID: attr('string'),
	taxID: attr('string'),
	passportNumber: attr('string'),
	workVisaType: attr('string'),
	eligibilityDocumentType: attr('string'),
	eligibilityDocument: attr('string'),
	eligibilityType: attr('string'),
	eligibilitySig: DS.belongsTo('App.Signature'),
	alienRegistrationNumber: attr('string'),
	i94AdmissionNumber: attr('string'),
	eadVisaExpiryDate: attr('string'),
	eligibilityDocuments: DS.hasMany('App.EmployeeEligibilityDocument'),
	employerEligibilityVerification: DS.belongsTo('App.EmployerEligibility'),
	eligibilityVerificationFormUrl: attr('string'),
	corrections: DS.hasMany('App.EligibilityCorrection'),
	nationalityHumanReadable: function() {
		var readable = "";
		if (this.get('nationality')) {
			readable = countryCodeToName(this.get('nationality'));
		}
		if (readable) {
			return readable;
		}
		return this.get('nationality');
	}.property('nationality'),
	legalStatusHumanReadable: function() {
		var legalStatus = this.get('legalStatus');
		if (legalStatus == 'C') {
			return 'Citizen';
		} else if (legalStatus == 'PR') {
			return 'Permanent Resident';
		} else if (legalStatus == 'O') {
			return 'Other';
		}
		return legalStatus;
	}.property('legalStatus'),
	eligibilityTypeHumanReadable: function() {
		var eligibilityType = this.get('eligibilityType');
		if (eligibilityType == 'CZ') {
			return 'a citizen';
		} else if (eligibilityType == 'PR') {
			return 'a lawful permanent resident';
		} else if (eligibilityType == 'NN') {
			return 'a noncitizen national of the United States';
		} else if (eligibilityType == 'EA') {
			return 'an alien with an Employment Authorization Document (EAD)';
		} else if (eligibilityType == 'WV') {
			return 'an alien with a work visa';
		} else if (eligibilityType == 'OT') {
			return 'Other';
		}
		return eligibilityType;
	}.property('eligibilityType'),
	currentSectionOneCorrection: function() {
		var curr = this.get('corrections').filterProperty('isSectionOneCorrection', true).get('lastObject');
		return curr;
	}.property('corrections','corrections.@each.isSectionOneCorrection'),
	sectionTwoCorrections: function() {
		return this.get('corrections').filterProperty('isSectionOneCorrection', false).filterProperty('noneCorrectionUrl', false);
	}.property('corrections', 'corrections.@each.isSectionOneCorrection'),
	currentSectionTwoCorrection: function() {
		return this.get('corrections').filterProperty('isSectionOneCorrection', false).get('lastObject');
	}.property('corrections', 'corrections.@each.isSectionOneCorrection'),
	isWorkVisaTypeF1: Ember.computed.equal('workVisaType', 'F1'),
	isInternational: attr('boolean'),
	isEligibilityVerificationComplete: attr('boolean'),
	isDocumentVerificationComplete: attr('boolean'),
	isSealed: attr('boolean'),
	status: attr('string'),
	SEVISNumber: attr('string'),
	CPTExpiryDate: attr('string'),
	reverifications: DS.hasMany('App.EligibilityReverification'),

	// Employee information for eligibility based on compliance requirements
	firstName: attr('string'),
	lastName: attr('string'),
	middleInitial: attr('string'),
	otherLastNames: attr('string'),
	address: attr('string'),
	apartmentNumber: attr('string'),
	city: attr('string'),
	state: attr('string'),
	zipCode: attr('string'),
	dateOfBirth: attr('string'),
	socialSecurityNumber: attr('string'),
	phoneNumber: attr('string'),
	email: attr('string'),
	documentType: attr('string'),
	documentNumber: attr('string'),
	documentIssuedCountry: attr('string'),
	documentExpiryDate: attr('string'),
	translatorUsed: attr('boolean'),
	isEditAllowed: attr('boolean'),
	isWaitingForSSNInfo: attr('boolean'),
});

App.EligibilityCorrection = DS.Model.extend({
	eligibility: DS.belongsTo('App.EmployeeEligibility'),
	previousEligibility: DS.belongsTo('App.EmployeeEligibility'),
	correctionMessage: attr('string'),
	isSectionOneCorrection: attr('boolean'),
	correctionFullName: attr('string'),
	correctionMemo: attr('string'),
	correctionDate: attr('string'),
	correctionSignature: attr('string'),
	correctionUrl: attr('string'),
	isEmployeeSideComplete: attr('boolean'),
	noneCorrectionUrl: Ember.computed.none('correctionUrl'),
});

var I9_LIST_B = [
	{ "id": "STATE_ID", "name": "Driver's license or State ID" },
	{ "id": "AGENCY_ID", "name": "Government Agency ID card" },
	{ "id": "SCHOOL_ID", "name": "School ID (with photograph)" },
	{ "id": "VOTER_ID", "name": "Voter's registration card" },
	{ "id": "MILITARY_ID", "name": "U.S. Military Card" },
	{ "id": "MILITARY_DEP_ID", "name": "Military dependent's ID Card" },
	{ "id": "CANADIAN_ID", "name": "Canadian Driver's license" }
];

App.EmployerEligibility = DS.Model.extend({
	eligibility: DS.belongsTo('App.EmployeeEligibility'),
	employerEligibilitySignature: attr('string'),
	employerEligibilitySignatureFirst: attr('string'),
	employerEligibilitySignatureLast: attr('string'),
	employerEligibilitySignatureTitle: attr('string'),
	employerEligibilitySignatureDate: attr('string'),
	employerEligibilitySignatureProvidedDate: attr('string'),
	firstDocumentTitle: attr('string'),
	firstDocumentIssuingAuthority: attr('string'),
	firstDocumentNumber: attr('string'),
	firstDocumentExpirationDate: attr('string'),
	secondDocumentTitle: attr('string'),
	secondDocumentIssuingAuthority: attr('string'),
	secondDocumentNumber: attr('string'),
	secondDocumentExpirationDate: attr('string'),
	SEVISNumberVerify: attr('string'),
	CPTExpiryDateVerify: attr('string'),
	additionalInfo: attr('string'),
	isComplete: attr('boolean'),
	expiryDate: function() {
		var firstDocumentExpirationDate;
		if (!this.get('firstDocumentExpirationDate') || I9_LIST_B.findProperty('id', this.get('firstDocumentTitle'))) {
			firstDocumentExpirationDate = null;
		} else {
			firstDocumentExpirationDate = moment(this.get('firstDocumentExpirationDate'), 'MM/DD/YYYY');
		}
		var secondDocumentExpirationDate;
		if (!this.get('secondDocumentExpirationDate') || this.get('secondDocumentTitle') != 'FORM_I_94') {
			secondDocumentExpirationDate = null;
		} else {
			secondDocumentExpirationDate = moment(this.get('secondDocumentExpirationDate'), 'MM/DD/YYYY');
		}
		var expiryDate;
		if (firstDocumentExpirationDate && secondDocumentExpirationDate) {
			//this will never hit, doc 2 will be i94 only when doc 1 is FOREIGN_PASSPORT while doc 1 is not in list B
			expiryDate = firstDocumentExpirationDate.isAfter(secondDocumentExpirationDate) ? secondDocumentExpirationDate : firstDocumentExpirationDate;
		} else {
			expiryDate = firstDocumentExpirationDate ? firstDocumentExpirationDate : secondDocumentExpirationDate;
		}
		return expiryDate;
	}.property('firstDocumentExpirationDate', 'secondDocumentExpirationDate', 'firstDocumentTitle', 'secondDocumentTitle'),
});

App.EmployeeEligibilityDocument = DS.Model.extend({
	eligibility: DS.belongsTo('App.EmployeeEligibility'),
	documentType: attr('string'),
	documentName: attr('string'),
	uploadUrl: attr('string'),
});

App.EligibilityReverification = DS.Model.extend({
	eligibility: DS.belongsTo('App.EmployeeEligibility'),
	newFirstName: attr('string'),
	newLastName: attr('string'),
	newMiddleInitial: attr('string'),
	reHireDate: attr('string'),
	reverifyDocTitle: attr('string'),
	reverifyDocNumber: attr('string'),
	reverifyDocExpirationDate: attr('string'),
	reverifySignature: attr('string'),
	reverifyDate: attr('string'),
	reverifyFullName: attr('string'),
	reverifyUrl: attr('string'),
	isActive: attr('boolean'),
	isReverificationComplete: attr('boolean'),
});

App.EmployeePension = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	isActive: attr('boolean'),
	country: attr('string'),
	isExempt: attr('boolean'),
	pensionID: attr('string'),
	contributionType: attr('string'),
	contributionAmount: attr('number'),
	contributionPercentage: attr('number'),
});

App.CanadaFederalTax = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	country: attr('string'),
	permanentResidence: attr('string'),
	isActive: attr('boolean'),
	isExempt: attr('boolean'),
	employeeSignature: DS.belongsTo('App.Signature'),
	basicPersonalAmount: attr('number'),
	childAmount: attr('number'),
	seniorSupplementaryAmount: attr('number'),
	ageAmount: attr('number'),
	pensionIncomeAmount: attr('number'),
	educationAmount: attr('number'),
	disabilityAmount: attr('number'),
	spouseAmount: attr('number'),
	eligibleDependentAmount: attr('number'),
	caregiverAmount: attr('number'),
	infirmDependentAmount: attr('number'),
	fromSpouseAmount: attr('number'),
	fromDependentAmount: attr('number'),
	mbFamilyTaxBenefit: attr('number'),
	alreadyClaimedCredit: attr('boolean'),
	claimAmountLowerThanIncome: attr('boolean'),
	isNonResident: attr('raw'),
	zoneBasedDeduction: attr('number'),
	additionalWithholdings: attr('number'),
	list: Ember.computed.collect('basicPersonalAmount', 'childAmount', 'seniorSupplementaryAmount', 'ageAmount', 'pensionIncomeAmount',
	 			'educationAmount', 'disabilityAmount', 'spouseAmount', 'eligibleDependentAmount', 'caregiverAmount',
	 			'infirmDependentAmount', 'fromSpouseAmount', 'fromDependentAmount'),
	totalClaimAmount: Ember.computed.sum('list'),
	permanentResidenceHumanReadable: function() {
		if (this.get('permanentResidence')) {
			return countryCodeToName(this.get('permanentResidence'));
		}
		return this.get('permanentResidence');
	}.property('permanentResidence'),
});

App.CanadaStateTax = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	country: attr('string'),
	permanentResidence: attr('string'),
	isActive: attr('boolean'),
	isExempt: attr('boolean'),
	employeeSignature: DS.belongsTo('App.Signature'),
	basicPersonalAmount: attr('number'),
	childAmount: attr('number'),
	seniorSupplementaryAmount: attr('number'),
	ageAmount: attr('number'),
	pensionIncomeAmount: attr('number'),
	educationAmount: attr('number'),
	disabilityAmount: attr('number'),
	spouseAmount: attr('number'),
	eligibleDependentAmount: attr('number'),
	caregiverAmount: attr('number'),
	infirmDependentAmount: attr('number'),
	fromSpouseAmount: attr('number'),
	fromDependentAmount: attr('number'),
	mbFamilyTaxBenefit: attr('number'),
	alreadyClaimedCredit: attr('boolean'),
	claimAmountLowerThanIncome: attr('boolean'),
	isNonResident: attr('raw'),
	zoneBasedDeduction: attr('number'),
	additionalWithholdings: attr('number'),
	state: attr('string'),
	isWorkLocationState: attr('boolean'),
	isResidenceState: attr('boolean'),
	list: Ember.computed.collect('basicPersonalAmount', 'childAmount', 'seniorSupplementaryAmount', 'ageAmount', 'pensionIncomeAmount',
	 			'educationAmount', 'disabilityAmount', 'spouseAmount', 'eligibleDependentAmount', 'caregiverAmount',
	 			'infirmDependentAmount', 'fromSpouseAmount', 'fromDependentAmount', 'mbFamilyTaxBenefit'),
	totalClaimAmount: Ember.computed.sum('list'),
});

App.AustraliaFederalTax = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	country: attr('string'),
	isActive: attr('boolean'),
	isExempt: attr('boolean'),
	employeeSignature: DS.belongsTo('App.Signature'),
	isResident: attr('raw'),
	hasTaxID: attr('boolean'),
	reasonForNoTaxID: attr('string'),
	claimTaxFreeThreshold: attr('raw'),
	claimSeniorTaxOffset: attr('raw'),
	claimTaxOffset: attr('raw'),
	hasHelpDebt: attr('raw'),
	hasFinancialSupplementDebt: attr('raw'),
	withholdingCertificate: attr('string'),
	taxOffsetAmount: attr('number'),
	seniorTaxOffsetInfo: attr('string'),
	additionalWithholding: attr('raw'),
	additionalWithholdingAmount: attr('number'),
	additionalWithholdingPercent: attr('number'),
	effectiveDate: attr('string'),
});

App.GermanyFederalTax = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	country: attr('string'),
	isActive: attr('boolean'),
	isExempt: attr('boolean'),
	employeeSignature: DS.belongsTo('App.Signature'),
	taxClass: attr('string'),
	hasNoChurchAffiliation: attr('raw'),
	exemptionsForChildren: attr('number'),
	taxOffice: attr('string'),
	communityKey: attr('string'),
});

App.SingaporeFederalTax = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	country: attr('string'),
	isActive: attr('boolean'),
	isExempt: attr('boolean'),
	employeeSignature: DS.belongsTo('App.Signature'),
	race: attr('string'),
	cdacContribution: attr('number'),
	ecfContribution: attr('number'),
	mbmfContribution: attr('number'),
	sindaContribution: attr('number'),
});

App.UnitedKingdomFederalTax = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	country: attr('string'),
	isActive: attr('boolean'),
	isExempt: attr('boolean'),
	employeeSignature: DS.belongsTo('App.Signature'),
	isDirector: attr('boolean'),
	niCategory: attr('string'),
	taxCode: attr('string'),
	unpaidStudentLoan: attr('boolean'),
	employeeStatement: attr('string'),
});

App.NetherlandsFederalTax = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	country: attr('string'),
	isActive: attr('boolean'),
	isExempt: attr('boolean'),
	employeeSignature: DS.belongsTo('App.Signature'),
});

App.HongKongFederalTax = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	country: attr('string'),
	isActive: attr('boolean'),
	isExempt: attr('boolean'),
	employeeSignature: DS.belongsTo('App.Signature'),
});

App.IndiaFederalTax = DS.Model.extend({
	employee: DS.belongsTo('allEmployee'),
	country: attr('string'),
	isActive: attr('boolean'),
	isExempt: attr('boolean'),
	employeeSignature: DS.belongsTo('App.Signature'),
});

App.FranceFederalTax = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	country: attr('string'),
	isActive: attr('boolean'),
	isExempt: attr('boolean'),
	employeeSignature: DS.belongsTo('App.Signature'),
	numberOfDependents: attr('number'),
	jobCoefficient: attr('string'),
	legalPosition: attr('string'),
	cadreStatus: attr('boolean'),
});

App.IrelandFederalTax = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	country: attr('string'),
	isActive: attr('boolean'),
	isExempt: attr('boolean'),
	employeeSignature: DS.belongsTo('App.Signature'),
});

App.BrazilFederalTax = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	country: attr('string'),
	isActive: attr('boolean'),
	isExempt: attr('boolean'),
	employeeSignature: DS.belongsTo('App.Signature'),
	hasDependents: attr('boolean'),
	workingBookletNumber: attr('number'),
	workingBookletSeries: attr('string'),
	workingBookletUF: attr('string'),
	workingBookletIssueDate: attr('string'),
	idNumber: attr('string'),
	idNumberIssuingBody: attr('string'),
	highestLevelOfEducation: attr('string'),
	isFirstJob: attr('boolean'),
	hasUnionContribution: attr('boolean'),
	previousUnionContribution: attr('number'),
});

App.EmployeeEeo = DS.Model.extend({
	employee: DS.belongsTo('App.AllEmployee'),
	eeoJobCategory: attr('string'),
	eeoRace: attr('string'),
	isFilledByAdmin: attr('boolean'),
	adminFillDate: attr('string'),
	adminFullName: attr('string'),
	eeoInfoComplete: function() {
		return this.get('eeoJobCategory') && this.get('eeoRace');
	}.property('eeoJobCategory', 'eeoRace'),
	notWishToSpecify: function() {
		return !this.get('eeoRace') || (this.get('eeoRace') && this.get('eeoRace') == 'NA');
	}.property('eeoRace'),
	eeoInfoCompleteBySelf: function() {
		return this.get('eeoRace') && !this.get('isFilledByAdmin');
	}.property('eeoRace', 'isFilledByAdmin'),
	eeoJobCategoryDisplay: function() {
		var category = this.get('eeoJobCategory');
		if(category == '1.1') {return 'Executive/Senior Level Officials and Managers';}
		if(category == '1.2') {return 'First/Mid-Level Officials and Managers';}
		if(category == '2') {return 'Professionals';}
		if(category == '3') {return 'Technicians';}
		if(category == '4') {return 'Sales Workers';}
		if(category == '5') {return 'Administrative Support Workers';}
		if(category == '6') {return 'Craft Workers';}
		if(category == '7') {return 'Operatives';}
		if(category == '8') {return 'Laborers and Helpers';}
		if(category == '9') {return 'Service Workers';}
		return "Don't wish to specify";
	}.property('eeoJobCategory'),
	eeoRaceDisplay: function() {
		var race = this.get('eeoRace');
		if(race == 'A') {return 'Hispanic or Latino';}
		if(race == 'b') {return 'Hispanic or Latino';}
		if(race == 'C') {return 'White';}
		if(race == 'D') {return 'Black or African American';}
		if(race == 'E') {return 'Native Hawaiian or Other Pacific Islander';}
		if(race == 'F') {return 'Asian';}
		if(race == 'G') {return 'American Indian or Alaska Native';}
		if(race == 'H') {return 'Two or more races';}
		if(race == 'I') {return 'White';}
		if(race == 'J') {return 'Black or African American';}
		if(race == 'K') {return 'Native Hawaiian or Other Pacific Islander';}
		if(race == 'L') {return 'Asian';}
		if(race == 'M') {return 'American Indian or Alaska Native';}
		if(race == 'N') {return 'Two or more races';}
		return "Don't wish to specify";
	}.property('eeoRace'),
	eeoGender: function() {
		var race = this.get('eeoRace');
		if(race == 'A' || (race >= 'C' && race <= 'H')){return 'Male';}
		if(race == 'b' || (race >= 'I' && race <= 'N')){return 'Female';}
		return "Don't wish to specify";
	}.property('eeoRace'),
});

})();
