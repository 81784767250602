// Temporarily add a utility debounce since version 1.0.0RC5 of ember does not include it.
// Source is modified version of _.debonce (http://underscorejs.org/docs/underscore.html)
App.Utils = App.Utils || {};

App.Utils.debounce = function(func, wait, immediate) {
	var timeout, args, context, timestamp, result;

	var later = function() {
		var last = (new Date().getTime()) - timestamp;

		if (last < wait && last > 0) {
			timeout = setTimeout(later, wait - last);
		} else {
			timeout = null;
			if (!immediate) {
				result = func.apply(context, args);
				if (!timeout) { context = args = null; }
			}
		}
	};

	return function() {
		context = this;
		args = arguments;
		timestamp = (new Date().getTime()) ;
		var callNow = immediate && !timeout;
		if (!timeout) { timeout = setTimeout(later, wait); }
		if (callNow) {
			result = func.apply(context, args);
			context = args = null;
		}

		return result;
	};
};

App.Utils.isValidWfCode = function(code) {
	if ((code === undefined) || (code.trim().length !== 3) || !code.trim().match(/^[A-Z0-9\-&]+$/)) {
		return false;
	}
	return true;
};

App.Utils.CostCenters1Metadata = {
	'company_related_name': 'costCenters1',
	'related_name': 'plCostCenter1',
	'supported_providers': ['PL','PG'],
	'human_readable_name': 'Cost Center 1',
};
App.Utils.CostCenters2Metadata = {
	'company_related_name': 'costCenters2',
	'related_name': 'plCostCenter2',
	'supported_providers': ['PL','PG'],
	'human_readable_name': 'Cost Center 2',
};
App.Utils.CostCenters3Metadata = {
	'company_related_name': 'costCenters3',
	'related_name': 'plCostCenter3',
	'supported_providers': ['PL','PG'],
	'human_readable_name': 'Cost Center 3',
};
App.Utils.PositionCodeMetadata = {
	'company_related_name': 'positionCodes',
	'related_name': 'positionCode',
	'supported_providers': ['PL','PG'],
	'human_readable_name': 'Position',
};
App.Utils.EmployeePayrollEnumFields = [App.Utils.CostCenters1Metadata, App.Utils.CostCenters2Metadata, App.Utils.CostCenters3Metadata, App.Utils.PositionCodeMetadata];

/**
 * Class that parses tastypie API validation errors and exposes convenient properties and methods.
 * When tastypie API validation error occurs, the HTTP response will have status code 400 and
 * all error messages will be in JSON format.
 *
 * @see http://django-tastypie.readthedocs.io/en/latest/validation.html#implementing-your-own-validation
 *
 * @property {boolean}          isValidationError       - True if it's an API validation error, false otherwise
 * @property {array[string]}    errorKeys               - Array of field names that has validation error
 * @property {array[string]}    errorMessages           - Flattened array of all error messages
 *
 * @example
 * 	Dependent validation failed with the following errors:
 * 	{
 * 		"data": {
 * 			"type": ["Cannot have more than one Spouse or Domestic Partner."],
 * 			"firstName": ["Duplicate dependent name."]
 * 		},
 * 		"statusCode": 400
 * 	}
 *
 * 	// Use ApiValidationErrors.errotTextHandler to set the error text field directly.
 * 	promise.catch(App.Utils.ApiValidationErrors.errotTextHandler(this, 'errorText'));
 *
 * 	// Use ApiValidationErrors.handler if you want to handle the error yourself.
 * 	dependent.save().catch(App.Utils.ApiValidationErrors.handler(function(apiValidationError) {
 * 		// Convenient properties
 * 		apiValidationError.get('validationErrors'); // => { type: ["Cannot have more than one Spouse or Domestic Partner."], firstName: ["Duplicate dependent name."] }
 * 		apiValidationError.get('isValidationError'); // => true
 * 		apiValidationError.get('errorKeys'); // => ["type", "firstName"]
 * 		apiValidationError.get('errorMessages'); // => ["Cannot have more than one Spouse or Domestic Partner.", "Duplicate dependent name."]
 * 		apiValidationError.get('errorMessages.firstObject'); // => "Cannot have more than one Spouse or Domestic Partner."
 *
 * 		// Get error fields directly
 * 		apiValidationError.get('type'); // => ["Cannot have more than one Spouse or Domestic Partner."]
 * 		apiValidationError.get('firstName'); // => ["Duplicate dependent name."]
 * 	}));
 */
App.Utils.ApiValidationErrors = Ember.ObjectProxy.extend({
	_error: null,

	content: Ember.computed.alias('validationErrors'),
	isValidationError: Ember.computed.equal('_error.status', 400),

	validationErrors: function() {
		if (!this.get('isValidationError')) {
			return {
				response: ['Unknown error occured']
			};
		}

		var response = this.get('_error.responseText');
		return JSON.parse(response);
	}.property('isValidationError', '_error', '_error.responseText'),

	errorKeys: function() {
		return Object.keys(this.get('validationErrors'));
	}.property('validationErrors'),

	errorMessages: function() {
		var validationErrors = this.get('validationErrors');
		var keys = this.get('errorKeys');

		var errorMessages = keys.reduce(function(msgs, key) {
			return msgs.concat(validationErrors[key]);
		}, []);
		return errorMessages;
	}.property('errorKeys', 'validationErrors'),
});

App.Utils.ApiValidationErrors.reopenClass({
	/**
	 * Returns an error handler that takes an error object and calls the callback with an instance of ApiValidationErrors
	 * @param {function} callback function
	 * @returns {function}
	 */
	handler: function(cb) {
		return function(error) {
			var apiValidationErrors = App.Utils.ApiValidationErrors.create({
				_error: error,
			});
			return cb(apiValidationErrors);
		};
	},

	/**
	 * Returns an error handler that sets the first error message to the `attr` on `target`.
	 * @param {Object} target object
	 * @param {string} error text attribute name
	 * @returns {function}
	 */
	errotTextHandler: function(target, attr) {
		return this.handler(function(apiValidationErrors) {
			target.set(attr, apiValidationErrors.get('errorMessages.firstObject'));
			return Ember.RSVP.reject();
		});
	},
});
